import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Flex } from 'antd';
import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme/colors';

const MeetingTitle = styled.span`
  overflow: hidden;
  color: var(--Cool-Gray-700, #0a1e2c);
  text-overflow: ellipsis;
  font-family: 'Cera Pro Medium';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface Props {
  id: string;
  title: string;
  participantsType?: string;
}

export const MeetingTitleLink: React.FC<Props> = ({ id, title, participantsType }) => {
  const handleClick = (ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    window.open(`/recap/${id}`, '_blank');
  };
  const badgeContent =
    participantsType === 'external'
      ? 'External Meeting'
      : participantsType === 'internal'
      ? 'Internal Meeting'
      : null;
  return (
    <Flex gap={4} align="center">
      <FontAwesomeIcon size="xs" icon={faExternalLink} />
      <MeetingTitle onClick={handleClick}>{title}</MeetingTitle>
      {badgeContent && (
        <Badge
          style={{
            backgroundColor: colors.gray[200],
            color: colors.gray[600],
            borderRadius: '5px',
          }}
          count={badgeContent}
        />
      )}
    </Flex>
  );
};
