import { create } from 'zustand';
import { Account, Deal } from './types';
import { produce } from 'immer';
import { AccountsContact } from '../../../API/types';

interface AccountOverviewState {
  selectedAccount: Account;
  participantsType: string;
  isFetching: boolean;
  dateRange: [Date, Date] | [null, null];
  dateOption: string;
  myMeetingsOnly: boolean;
  primaryDealChanged: (deal: Deal) => void;
  setMyMeetingsOnly: (accounts: boolean) => void;
  setSelectedAccount: (account: Account) => void;
  setDateRange: (dateRange: [Date, Date] | [null, null]) => void;
  setDateOption: (dateOption: string) => void;
  setParticipantsType: (participantsType: string) => void;
  fetchStatusChange: (isFetching: boolean) => void;
  favouriteStatusChanged: (isFavourite: boolean) => void;
  stakeholderDataUpdated: (contact: AccountsContact) => void;
  summarizationRequested: () => void;
}

export type AccountOverviewStore = ReturnType<typeof createAccountOverviewStore>;

export const createAccountOverviewStore = () => {
  return create<AccountOverviewState>(set => ({
    selectedAccount: {
      mappedProperties: {
        lifecycleStage: false,
        contractRenewalDate: false,
        contractValue: false,
        industry: false,
        priority: false,
        role: false,
      },
    } as Account,
    summarizationRequested: () => {
      set(partial =>
        produce(partial, draft => {
          if (draft.selectedAccount.userTimeline) {
            draft.selectedAccount.userTimeline = {
              ...draft.selectedAccount.userTimeline,
              isGenerating: true,
            };
          } else {
            draft.selectedAccount.userTimeline = {
              isGenerating: true,
              lastGeneratedAt: null,
              timeline: [],
              narrative: null,
            };
          }
        }),
      );
    },
    primaryDealChanged: deal => {
      set(partial =>
        produce(partial, draft => {
          draft.selectedAccount.primaryDeal = deal;
        }),
      );
    },
    favouriteStatusChanged: isFavourite => {
      set(partial =>
        produce(partial, draft => {
          draft.selectedAccount.isFavourited = isFavourite;
        }),
      );
    },
    stakeholderDataUpdated: (contact: AccountsContact) => {
      set(partial =>
        produce(partial, draft => {
          draft.selectedAccount.contacts = draft.selectedAccount.contacts.map(c => {
            if (c.id === contact.id) return contact;
            return c;
          });
        }),
      );
    },
    participantsType: 'all',
    dateRange: [null, null],
    dateOption: 'all',
    isFetching: false,
    myMeetingsOnly: false,
    setMyMeetingsOnly: (myMeetingsOnly: boolean) => set({ myMeetingsOnly }),
    setSelectedAccount: (account: Account) => set({ selectedAccount: account }),
    setDateRange: (dateRange: [Date, Date] | [null, null]) => set({ dateRange }),
    setDateOption: (dateOption: string) => set({ dateOption }),
    setParticipantsType: (participantsType: string) => set({ participantsType }),
    fetchStatusChange: isFetching => set({ isFetching }),
  }));
};
