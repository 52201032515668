import React, { useState } from 'react';
import { useToast } from '../../../../../../hooks/useToast';
import { coreAPI } from '../../../../../../API/core';
import { extractError } from '../../../../../../utils/api';
import SeatManagementModal from '../SeatManagementModal';

type Props = {
  open: boolean;
  workspaceId: string;
  usedPaidSeats: number;
  paidSeats: number;
  plan: string;
  onClose: () => unknown;
};

const api = new coreAPI();

const SubscribeModal: React.FC<Props> = ({
  open,
  workspaceId,
  usedPaidSeats,
  paidSeats,
  plan,
  onClose,
}) => {
  const [isWorking, setIsWorking] = useState(false);
  const { error } = useToast();

  const handleConfirm = async (plan: string, currentSeats: number) => {
    setIsWorking(true);
    const url: string = await api
      .subscribe(workspaceId, currentSeats, plan)
      .then(response => {
        return response;
      })
      .catch(errorMessage => {
        error(extractError(errorMessage));
      })
      .finally(() => {
        setIsWorking(false);
      });
    if (url) window.location.replace(url);
  };

  return (
    <SeatManagementModal
      onConfirm={handleConfirm}
      isWorking={isWorking}
      open={open}
      onClose={onClose}
      paidSeats={paidSeats}
      usedPaidSeats={usedPaidSeats}
      plan={plan}
    />
  );
};

export default SubscribeModal;
