import React, { useEffect } from 'react';
import { Container, ToggleCardContainer } from './style';
import Section from '../_Misc/Section';
import ToggleCard from './ToggleCard';
import { useUserSettings } from '../../../../features/user/hooks/useUserSettings';
import { CheatSheet } from './CheatSheet';
import { Button, Flex, Input } from 'antd';
import { useToast } from '../../../../hooks/useToast';

interface Props {
  slackIntegrationEnabled?: boolean;
  setSelectedTab: (e: string) => void;
}

const Notifications: React.FC<Props> = ({ slackIntegrationEnabled, setSelectedTab }) => {
  const { settings, updateSettings } = useUserSettings();

  const { success } = useToast();

  const [notifyPendingInvitesAfterDays, setNotifyPendingInvitesAfterDays] = React.useState(
    settings.notifyPendingInvitesAfterDays ?? 3,
  );

  useEffect(() => {
    if (settings.notifyPendingInvitesAfterDays !== notifyPendingInvitesAfterDays) {
      setNotifyPendingInvitesAfterDays(settings.notifyPendingInvitesAfterDays);
    }
  }, [settings.notifyPendingInvitesAfterDays]);

  const goToIntegrations = () => {
    setSelectedTab('integrations');
  };

  return (
    <Container>
      <Section
        title="Notifications config"
        description="Time to play DJ with your notification settings – mix 'em up!"
      />

      <Section title="By Email">
        <ToggleCardContainer>
          <ToggleCard
            description="Send me email notifications after each recapped meeting."
            checked={settings.receiveRecapsOnEmail}
            onChange={e => updateSettings({ receiveRecapsOnEmail: !!e })}
            recommendedSetting={'ON'}
          />

          <ToggleCard
            description="Send recap to meeting invitees who are part of my workspace"
            extraDescription="For the meetings that you are the owner of, UpdateAI will send a public view of the recap to all Workspace members that were invited to the meeting. This will be regardless of whether or not they attended the meeting."
            checked={settings.sendRecapUpdateUsers}
            onChange={e => updateSettings({ sendRecapUpdateUsers: !!e })}
            recommendedSetting={'ON'}
          />

          <ToggleCard
            description="Send recap to all meeting invitees (including external)"
            extraDescription="For the meetings that you are the owner of, UpdateAI will send a public view of the recap to all meeting invitees. This will be regardless of whether or not they attended the meeting."
            checked={settings.sendRecapToInvitees}
            onChange={e => updateSettings({ sendRecapToInvitees: !!e })}
            recommendedSetting={'OFF'}
          />

          <ToggleCard
            description='Receive Zoom’s "cloud recording now available" email notification'
            extraDescription="We recommend disabling this setting so that your meeting recap emails come from UpdateAI instead of Zoom."
            checked={settings.cloudRecordingEmail}
            onChange={e => updateSettings({ cloudRecordingEmail: !!e })}
            recommendedSetting={'OFF'}
            learnMore="https://help.update.ai/en/articles/6747031-how-do-i-turn-off-zoom-s-cloud-recording-now-available-email"
          />

          <ToggleCard
            description='Receive an alert every time I have a meeting with “limited dialogue"'
            extraDescription="If there was insufficient dialogue to generate insights, we will send you an alert."
            checked={settings.sendLimitedDialogueEmail}
            onChange={e => updateSettings({ sendLimitedDialogueEmail: !!e })}
            recommendedSetting={'OFF'}
            learnMore="https://help.update.ai/en/articles/7033173-why-wasn-t-updateai-able-to-generate-any-insights-of-my-meeting"
          />

          <CheatSheet />

          <ToggleCard
            description="Receive a weekly digest for topics I'm following"
            extraDescription="Every Friday at 7:00AM (your local time), we'll send you an email with all the new detections within the VoC topics you're following."
            checked={settings.sendWeeklyFollowedTopicReport}
            onChange={e => updateSettings({ sendWeeklyFollowedTopicReport: !!e })}
            recommendedSetting={'ON'}
          />

          <Flex vertical>
            <ToggleCard
              description="Receive a notification when workspace invitations are still pending"
              extraDescription="We'll inform you when members you've invited to your workspace have not yet accepted their invitations."
              checked={settings.notifyPendingInvites}
              onChange={e => updateSettings({ notifyPendingInvites: !!e })}
              recommendedSetting={'ON'}
            />

            {settings.notifyPendingInvites && (
              <Flex vertical gap={4} style={{ padding: '0 16px' }}>
                <span style={{ fontSize: '12px', color: '#6B6B6B', maxWidth: '500px' }}>
                  Select the number of days to wait before you're notified:
                </span>
                <Flex gap={4} style={{ maxWidth: '300px' }}>
                  <Input
                    type="number"
                    value={notifyPendingInvitesAfterDays}
                    onChange={e => setNotifyPendingInvitesAfterDays(parseInt(e.target.value))}
                  />
                  <Button
                    type="primary"
                    disabled={
                      notifyPendingInvitesAfterDays === settings.notifyPendingInvitesAfterDays ||
                      notifyPendingInvitesAfterDays < 1 ||
                      !notifyPendingInvitesAfterDays
                    }
                    onClick={async () => {
                      await updateSettings({
                        notifyPendingInvitesAfterDays,
                      });

                      success('Number of days updated.');
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            )}
          </Flex>
        </ToggleCardContainer>
      </Section>

      <Section title="By Slack">
        <ToggleCardContainer>
          <ToggleCard
            description="Receive meeting summaries and action items in Slack."
            checked={settings.receiveRecapsOnSlack}
            onChange={e => updateSettings({ receiveRecapsOnSlack: !!e })}
            recommendedSetting={'ON (REQUIRES SLACK INTEGRATION)'}
            onClickRecommendedSetting={goToIntegrations}
            disabled={!slackIntegrationEnabled}
            tooltipTitle="Requires Slack integration"
          />
        </ToggleCardContainer>
      </Section>
    </Container>
  );
};

export default Notifications;
