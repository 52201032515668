import styled from 'styled-components';

const Info = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const Number = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  color: #282828;
  gap: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
`;

const Expiration = styled.div`
  font-style: normal;
  color: #6a6a6a;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
`;

export default { Info, Number, Expiration };
