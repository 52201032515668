import React, { useEffect, useState } from 'react';
import { ButtonAction, IntegrationCard } from '../Components/IntegrationCard';
import { useToast } from '../../../../../hooks/useToast';
import { IntegrationModal } from '../Components/IntegrationModal';
import { SuccessConnectionDataLabel } from '../Components/SuccessConnectionDataLabel';
import { SubscribeToTeamsPlanLink } from '../../../../../componentsV2/UpgradeToPro';
import { coreService } from '../../../../../services/core/core-service';
import { AddCredentials } from './AddCredentials';
import { Calls } from './Calls';
import { OnlyOneConnected } from '../Components/OnlyOneConnected';
import Styles from './styles';
import DeleteModal from '../../Organizations/components/DeleteModal';
// import useFeatures from '../../../../../hooks/useFeatures';

const { Content } = Styles;

export const ClariIntegrationCard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddCredentialsModal, setShowAddCredentialsModal] = useState(false);
  const [result, setResult] = useState<{
    id: string;
    canDelete: boolean;
    secretKey: string;
  }>({ id: '', canDelete: false, secretKey: '***' });

  // const features = useFeatures();
  const availableFeature = true;

  const { success, error } = useToast();

  const logo = 'https://media.trustradius.com/vendor-logos/rD/wX/GW6ETVO15911.PNG';
  const hasIntegration = result.id !== '';

  const revokeKey = async () => {
    setIsLoading(true);
    coreService
      .removeGongCredentials()
      .then(() => {
        success('Gong key revoked successfully');
        setResult({ id: '', canDelete: false, secretKey: '***' });
      })
      .catch(() => {
        error('Failed to revoke Gong key');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setIsLoading(true);
    coreService
      .getMyWorkspace()
      .then(res => {
        if (res.workspace.integrations.clari.credentials) {
          setResult({
            id: res.workspace.integrations.clari.credentials.apiKeyId,
            canDelete: res.canDelete,
            secretKey: result.secretKey,
          });
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  const tooltipMessage = !availableFeature ? (
    <SubscribeToTeamsPlanLink title="Upgrade to a Business plan or higher to integrate with Gong." />
  ) : (
    'Integration can only be removed by the user who added it.'
  );
  const actions: ButtonAction[] = hasIntegration
    ? [
        {
          type: 'button',
          label: 'Revoke Key',
          tooltip: tooltipMessage,
          buttonProps: {
            type: 'default',
            loading: isLoading,
            disabled: true,
          },
          onClick: () => {
            if (availableFeature) setShowDeleteModal(true);
          },
        },
      ]
    : [
        {
          type: 'button',
          label: 'Add Key',
          tooltip: tooltipMessage,
          buttonProps: {
            type: 'primary',
            loading: isLoading,
            disabled: true,
          },
          onClick: () => {
            setShowAddCredentialsModal(true);
          },
        },
      ];

  if (isLoading || !hasIntegration) {
    return null;
  }

  return (
    <IntegrationCard
      title="Clari"
      description="Import calls from Clari, including all calls from the last 6 months."
      actions={actions}
      logoSrc={logo}
    >
      <Content>
        {!hasIntegration && (
          <OnlyOneConnected
            elem={
              <a href="https://api-doc.copilot.clari.com/#section/Authentication" target="_blank">
                How to get my Clari credentials?
              </a>
            }
          />
        )}
        {hasIntegration && (
          <>
            <SuccessConnectionDataLabel label="Id: " value={result.id} />
            <a style={{ alignSelf: 'end', color: '#FF6C54' }} onClick={() => setShowModal(true)}>
              {'Import >'}
            </a>
          </>
        )}
      </Content>

      <IntegrationModal
        show={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        icon={logo}
        title="Clari Calls"
        showButton={false}
        width={750}
      >
        <Calls showModal={() => setShowModal(true)} hideModal={() => setShowModal(false)} />
      </IntegrationModal>

      <IntegrationModal
        show={showAddCredentialsModal}
        onCancel={() => {
          setShowAddCredentialsModal(false);
        }}
        icon={logo}
        title="Clari Credentials"
        buttonTitle="Close"
        showButton={false}
      >
        <AddCredentials
          onSuccess={r => {
            setResult({ ...r, canDelete: true });
            setShowAddCredentialsModal(false);
          }}
        />
      </IntegrationModal>

      <DeleteModal
        shouldShow={showDeleteModal}
        loading={isLoading}
        closeModal={() => setShowDeleteModal(false)}
        heading="Revoke your Clari credentials"
        detail={`Ensure all your processing is done before revoking your credentials otherwise will fail. \n\nYour imported data will not be deleted.`}
        confirmButtonText="Yes, I'm sure."
        onConfirm={() => {
          revokeKey();
          setShowDeleteModal(false);
        }}
      />
    </IntegrationCard>
  );
};
