export type StripeSubscription = {
  mechanism: 'stripe';
  plan: string;
  paymentMethod: {
    card: {
      lastFourDigits: string;
      expiresAt: string;
      provider: string;
    };
  };
};

export type Subscription = {
  workspaceId: string;
  usedPaidSeats: number;
  canManageSubscription: boolean;
  subscription: {
    paidSeats: number;
    planId: string;
    isActive: boolean;
    isCanceled: boolean;
    billing: StripeSubscription | { mechanism: 'other' };
  };
};

export const Plans = {
  annual: {
    id: 'UPDATE_ANNUAL',
    pricePerMonth: 39,
    label: 'Annual',
  },
  monthly: {
    id: 'UPDATE_MONTHLY',
    pricePerMonth: 49,
    label: 'Monthly',
  },
  default: {
    pricePerMonth: 0,
    label: 'Monthly',
  },
};
