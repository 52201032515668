import {
  customerIntelligenceFiltersStore,
  reducers,
} from '../stores/customer-intelligence-filters';

const {
  accountsSelected,
  categoryChanged,
  contractValuesChanged,
  contractRenewalDateBeforeChanged,
  dateTypeOptionChanged,
  dateRangeChanged,
  groupSelected,
  memberSelectionToggled,
  allMembersToggled,
  meetingsImInvitedOnlyToggled,
  filtersReset,
  setCrmMap,
} = reducers;

export const useCustomerIntelligenceFilters = () => {
  const {
    accounts,
    category,
    contractRenewalDateBefore,
    contractValueMax,
    contractValueMin,
    dateTypeOption,
    dateRange,
    group,
    industries,
    lifecycleStages,
    meetingsImInvitedOnly,
    members,
    priorities,
    subGroup,
    crmFilters,
  } = customerIntelligenceFiltersStore();

  return {
    accounts,
    category,
    contractRenewalDateBefore,
    contractValueMax,
    contractValueMin,
    dateTypeOption,
    dateRange,
    group,
    industries,
    lifecycleStages,
    meetingsImInvitedOnly,
    members,
    priorities,
    subGroup,
    crmFilters,
    setCrmMap,
    changeDateTypeOption: dateTypeOptionChanged,
    toggleAllMembers: allMembersToggled,
    selectAccounts: accountsSelected,
    changeCategory: categoryChanged,
    changeContractValues: contractValuesChanged,
    changeContractRenewalDate: contractRenewalDateBeforeChanged,
    changeDateRange: dateRangeChanged,
    selectGroup: groupSelected,
    toggleMember: memberSelectionToggled,
    toggleMeetingsImInvitedOnly: meetingsImInvitedOnlyToggled,
    resetFilters: filtersReset,
  };
};
