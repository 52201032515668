import React, { useState } from 'react';
import { useToast } from '../../../../../../hooks/useToast';
import { coreAPI } from '../../../../../../API/core';
import { extractError } from '../../../../../../utils/api';
import SeatManagementModal from '../SeatManagementModal';
import { useQuery } from '../../../../../../hooks/useQuery';

type Props = {
  open: boolean;
  workspaceId: string;
  usedPaidSeats: number;
  paidSeats: number;
  plan: string;
  onClose: () => unknown;
};

const api = new coreAPI();

const UpgradeModal: React.FC<Props> = ({
  open,
  workspaceId,
  usedPaidSeats,
  paidSeats,
  plan,
  onClose,
}) => {
  const [isWorking, setIsWorking] = useState(false);
  const { error, success } = useToast();

  const handleConfirm = async (plan: string, currentSeats: number) => {
    setIsWorking(true);
    await api
      .updateSubscription(workspaceId, currentSeats, plan)
      .then(() => {
        success('Susbscription updated successfully');
        onClose();
      })
      .catch(errorMessage => {
        error(extractError(errorMessage));
      })
      .finally(() => {
        setIsWorking(false);
      });
  };

  return (
    <SeatManagementModal
      onConfirm={handleConfirm}
      isWorking={isWorking}
      open={open}
      onClose={onClose}
      paidSeats={paidSeats}
      usedPaidSeats={usedPaidSeats}
      plan={plan}
    />
  );
};

export default UpgradeModal;
