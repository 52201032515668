import { FC, useEffect, useState } from 'react';
import { Modal } from '../../../../../../../../componentsV2/Modal';
import Styled from '../../style';
import { Col, ColorPicker, ColorPickerProps, Divider, Flex, Input, Row, Tag, theme } from 'antd';

import { generate, green, presetPalettes, red, blue } from '@ant-design/colors';
import { coreService } from '../../../../../../../../services/core/core-service';
import { Alert } from '../../../../../../../../components/alerts/Alert';
import { DatabaseOutlined } from '@ant-design/icons';

const { ModalContainer, Label, FormItem } = Styled;

type CreateProductLineModalProps = {
  open: boolean;
  onClose: VoidFunction;
  productLine?: ProductLine;
  onSuccess: VoidFunction;
};

type Presets = Required<ColorPickerProps>['presets'][number];

function genPresets(presets = presetPalettes) {
  return Object.entries(presets).map<Presets>(([label, colors]) => ({ label, colors, key: label }));
}

export const CreateProductLineModal: FC<CreateProductLineModalProps> = ({
  onClose,
  onSuccess,
  open,
  productLine,
}) => {
  const { token } = theme.useToken();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState(`#${Math.floor(Math.random() * 16777215).toString(16)}`);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (productLine) {
      setName(productLine.name);
      setDescription(productLine.description);
      setColor(productLine.color);
    }
  }, [productLine]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      if (productLine) {
        await coreService.updateWorkspaceProductLine(productLine.id, {
          name,
          description,
          color,
        });
      } else {
        await coreService.createWorkspaceProductLine({
          name,
          description,
          color,
        });
      }

      onSuccess();
      setName('');
      setDescription('');
      setColor(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const isFormInvalid = !name || !description || !color;

  const presets = genPresets({ primary: generate(token.colorPrimary), red, green, blue });

  const customPanelRender: ColorPickerProps['panelRender'] = (
    _,
    { components: { Picker, Presets } },
  ) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider type="vertical" style={{ height: 'auto' }} />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );

  return (
    <Modal
      width={500}
      title={productLine ? `Update ${productLine.name}` : 'Add Product Line'}
      open={open}
      onClose={onClose}
      primaryAction={{
        label: productLine ? 'Update' : 'Add',
        onClick: handleSubmit,
        disabled: isFormInvalid,
        loading: isLoading,
      }}
      secondaryAction={{ label: 'Cancel', onClick: onClose }}
    >
      <ModalContainer>
        <Alert
          customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
          message="The Name and Description fields determine how this Product Line will be automatically mapped to insights and topics by our system. Once created, this Product Line will only be mapped automatically to future meeting data."
          variant="info"
          title=""
        />

        <FormItem>
          <Label>Name</Label>
          <Input
            value={name}
            onChange={ev => setName(ev.target.value)}
            placeholder="e.g. Cybersecurity Protection System"
          />
        </FormItem>

        <FormItem>
          <Label>Description</Label>
          <Input.TextArea
            value={description}
            onChange={ev => setDescription(ev.target.value)}
            rows={6}
            placeholder="e.g. A cloud-based data protection and encryption platform for Healthcare, Finance, and Defense companies. Ensures sensitive information is secure both at rest and in transit."
          />
        </FormItem>

        <FormItem>
          <Label>Color</Label>
          <ColorPicker
            showText
            style={{ justifyContent: 'flex-start' }}
            presets={presets}
            onChange={(color, hex) => setColor(hex)}
            value={color}
            panelRender={customPanelRender}
          />
        </FormItem>
      </ModalContainer>
    </Modal>
  );
};
