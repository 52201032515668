import styled from 'styled-components';

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto auto 24px auto;
`;

const FiltersGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div<{ $visible: boolean }>`
  flex-direction: column;
  gap: 8px;
  display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
  border-radius: var(--6px, 6px);
  border: 1px solid var(--Background-backgroundStroke, #d2d2d2);
  padding: 10px;
`;

const MeetingsCount = styled.p`
  color: var(--Cool-Gray-400, #566976);

  /* Text Styles/Heading 6 */
  font-family: Cera Pro;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

export default {
  Contents,
  FiltersGroup,
  Header,
  MeetingsCount,
  HeaderRow,
};
