import { organizationInvitesStore } from '../../../stores/organization-invites';
import { useOrganizationDetails } from './useOrganizationDetails';
import { revokeInvitation, inviteUsers, resendInvitation } from '../organizations.service';

export const useOrganizationInvites = () => {
  const { isFetching } = useOrganizationDetails();
  const { invitations } = organizationInvitesStore();

  return {
    isFetching,
    invitations,
    revokeInvitation,
    inviteUsers,
    resendInvitation,
  };
};
