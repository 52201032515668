import styled from 'styled-components';
import { colors } from '../../../../../../theme/colors';

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  width: 100%;
  height: 64px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background-color: white;
  outline: ${({ selected }) =>
    selected ? `1px solid ${colors.primary[200]}` : `1px solid ${colors.gray[100]}`};
  cursor: pointer;

  :hover {
    outline: 1px solid ${colors.coolGray[200]};
  }

  transition: outline 0.15s ease-in-out;
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: flex-start;
`;

const NameAndTitleFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 4px;
`;

const Subtitle = styled.span`
  color: var(--Cool-Gray-400, #566976);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
`;

const Title = styled.span`
  color: var(--Cool-Gray-500, #0d283b);
  font-family: 'Cera Pro Medium';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
`;

const TalkRatioSection = styled.div`
  display: flex;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

const TalkRatioFrame = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
`;

const TalkRatioLabel = styled.span`
  color: #9ea9b1;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const Percentage = styled.span`
  color: #566976;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const MeetingsInfoSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;
`;

const MeetingInfo = styled.div`
  display: flex;
  padding-right: 4px;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.span`
  color: #566976;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const LabelValue = styled.span`
  color: #0d283b;

  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70px;
`;

export default {
  Container,
  HeaderSection,
  NameAndTitleFrame,
  Subtitle,
  Title,
  TalkRatioSection,
  TalkRatioFrame,
  TalkRatioLabel,
  Percentage,
  MeetingsInfoSection,
  MeetingInfo,
  Label,
  LabelValue,
};
