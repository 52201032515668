import { v4 } from 'uuid';
import { PaginationMetadata, UpcomingEvent } from '../../../API/types';
import { create } from 'zustand';
import { setAccumulatedItems } from '../utils';
import { ProviderIntegration } from '../../../redux/typings/store';

export const UPCOMING_MEETINGS_PAGE_KEY = 'upcoming-meetings';

const defaultDateRange: [null, null] = [null, null];
const defaultMetadata = {
  page: 1,
  itemsPerPage: 20,
  totalItems: 0,
  totalPages: 0,
};

export type PastMeetingStore = ReturnType<typeof createUpcomingMeetingsStore>;

interface UpcomingMeetingsState {
  events: UpcomingEvent[];
  isFetching: boolean;
  updateAccounts: ProviderIntegration[];
  paginationMetadata: PaginationMetadata;
  page: number;
  pageSize: number;
  searchTerm: string;
  meetingType: string;
  mType: string | null;
  canLoadData: boolean;
  eventsFetched: (events: UpcomingEvent[]) => unknown;
  fetchStatusChanged: (isFetching: boolean) => unknown;
  updateAccountsFiltered: (accounts: ProviderIntegration[]) => unknown;
  pageChanged: (value: number) => unknown;
  paginationMetadataSet: (metadata: PaginationMetadata) => unknown;
  searchTermChanged: (value: string) => unknown;
  meetingTypeChanged: (value: string) => unknown;
  resetFilters: () => unknown;
  keepFetching: () => unknown;
  refetch: () => unknown;
  setPurposeStatement: (id: string, statement: string) => unknown;
  updateMeetingAccount: (
    id: string,
    account: { id: string; name: string; provider: string; mappingId: string } | null,
  ) => unknown;
  updateMType: (mType: string | null) => unknown;
  updateFiltersState: (filters: Record<string, any>) => void;

  //Internal
  fetchId: string;
}

const persistFilters = (newFilters: Record<string, any>) => {
  const currentFiltersObject = JSON.parse(localStorage.getItem('pageFilters') || '{}');
  const currentPageFilters = currentFiltersObject[UPCOMING_MEETINGS_PAGE_KEY] || {};
  const newFiltersObject = {
    ...currentPageFilters,
    ...newFilters,
  };
  currentFiltersObject[UPCOMING_MEETINGS_PAGE_KEY] = newFiltersObject;
  localStorage.setItem('pageFilters', JSON.stringify(currentFiltersObject));
}

export const createUpcomingMeetingsStore = () => {
  return create<UpcomingMeetingsState>(set => {
    return {
      events: [],
      isFetching: false,
      updateAccounts: [],
      contacts: [],
      dateRange: defaultDateRange,
      paginationMetadata: defaultMetadata,
      page: 1,
      pageSize: 20,
      searchTerm: '',
      meetingType: 'all',
      mType: null,
      canLoadData: false,

      // Filters
      updateFiltersState: filters => set(filters),
      updateAccountsFiltered: accounts =>
        set(store => {
          if (store.updateAccounts.length !== accounts.length) {
            persistFilters({ updateAccounts: accounts });
            return { updateAccounts: accounts, page: 1, events: [] };
          } else {
            const set = new Set(accounts.map(account => account.id));
            for (const acc of store.updateAccounts) {
              if (set.has(acc.id)) {
                set.delete(acc.id);
              } else {
                set.add(acc.id);
              }
            }
            if (set.size !== 0) {
              persistFilters({ updateAccounts: accounts });
              return { updateAccounts: accounts, page: 1, events: [] }
            };
            return store;
          }
        }),
      searchTermChanged: (value: string) => {
        set({ searchTerm: value, page: 1, events: [] })
        persistFilters({ searchTerm: value });
      },
      meetingTypeChanged: (value: string) => {
        set({ meetingType: value, page: 1, events: [] })
        persistFilters({ meetingType: value });
      },
      resetFilters: () => {
        set({
          events: [],
          updateAccounts: [],
          meetingType: 'all',
          mType: null,
          searchTerm: '',
          paginationMetadata: defaultMetadata,
          page: 1,
        })
        const currentFiltersObject = JSON.parse(localStorage.getItem('pageFilters') || '{}');
        delete currentFiltersObject[UPCOMING_MEETINGS_PAGE_KEY];
        localStorage.setItem('pageFilters', JSON.stringify(currentFiltersObject));
      },

      pageChanged: (value: number) => set({ page: value }),
      paginationMetadataSet: metadata => set({ paginationMetadata: metadata }),

      keepFetching: () => {
        set(state => {
          if (state.paginationMetadata.page < state.paginationMetadata.totalPages) {
            return { page: state.page + 1 };
          }
          return state;
        });
      },
      eventsFetched: events => {
        set(state => {
          return { events: setAccumulatedItems(state.events, events, true) };
        });
      },
      refetch: () => {
        set({
          paginationMetadata: defaultMetadata,
          page: 1,
          fetchId: v4(),
          events: [],
        });
      },
      fetchStatusChanged: isFetching => set({ isFetching }),
      setPurposeStatement: (id, statement) =>
        set(state => {
          const event = state.events.find(event => event.id === id);
          if (event) event.purposeStatement = statement;
          return { events: state.events };
        }),
      updateMeetingAccount: (id, account) =>
        set(state => {
          const event = state.events.find(event => event.id === id);
          if (event) event.account = account;
          return { events: state.events };
        }),
      updateMType: mType => {
        set({
          mType,
          page: 1,
          events: [],
        })
        persistFilters({ mType });
      },

      // Internal
      fetchId: v4(),
    };
  });
};
