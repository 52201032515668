import React, { useEffect, useState } from 'react';
import { Content, FilterSpacer, Shadow } from './style';
import { Button, Input, Space } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { DateSelector } from '../../../shared/components/DateSelector';
import { PastMeetingsFilter } from '../PastMeetingsFilter';
import { usePastMeetings } from '../../../meetings/past-meetings/past-meetings.hook';
import { useDebouncedCallback } from 'use-debounce';
import { PAST_MEETINGS_PAGE_KEY } from '../../../meetings/past-meetings/past-meetings.store';

const { Search } = Input;

const PastMeetingsFilterBar: React.FC = () => {
  const {
    searchTerm,
    isFetching,
    dateRange,
    dateRangeType,
    reset,
    changeSearchTerm,
    changeDateRange,
  } = usePastMeetings();
  const [inputSearchTerm, setInputSearchTerm] = useState('');
  const [dateOption, setDateOption] = useState<string>(dateRangeType);

  useEffect(() => {
    if (dateRangeType !== dateOption) {
      setDateOption(dateRangeType);
    }
  }, [dateRangeType]);

  const searchDebounce = useDebouncedCallback(value => {
    changeSearchTerm(value);
  }, 400);

  useEffect(() => {
    setInputSearchTerm(searchTerm);
  }, [searchTerm]);

  const updateFilters = ({ key, value }: { key: string; value: string }) => {
    const currentFilters = JSON.parse(localStorage.getItem('pageFilters') || '{}');
    const currentPageFilters = currentFilters[PAST_MEETINGS_PAGE_KEY] || {};
    localStorage.setItem(
      'pageFilters',
      JSON.stringify({
        ...currentFilters,
        [PAST_MEETINGS_PAGE_KEY]: { ...currentPageFilters, [key]: value },
      }),
    );
  };

  useEffect(() => {
    if (dateRangeType !== dateOption) {
      updateFilters({ key: 'dateRangeType', value: dateOption });
    }
  }, [dateOption]);

  const handleChangeInputText = (text: string) => {
    setInputSearchTerm(text);
    searchDebounce(text);

    updateFilters({ key: 'searchTerm', value: text });
  };

  return (
    <Content>
      <FilterSpacer>
        <Shadow>
          <Search
            placeholder="Search by title, account, or participants"
            onChange={e => handleChangeInputText(e.target.value)}
            style={{ width: 350 }}
            value={inputSearchTerm}
          />

          <DateSelector
            direction={'past'}
            value={dateOption}
            onDateOptionChanged={setDateOption}
            onDateRangeChanged={changeDateRange}
            predefinedRanges={{
              '3m': false,
              '6m': false,
              '12m': false,
            }}
            defaultValues={dateRange}
          />

          <PastMeetingsFilter />
        </Shadow>

        <Space size={'large'}>
          <Button disabled={isFetching} onClick={reset} icon={<ReloadOutlined />}>
            Reset Filters
          </Button>
        </Space>
      </FilterSpacer>
    </Content>
  );
};

export default PastMeetingsFilterBar;
