import React, { useState } from 'react';
import Styles from './styles';
import JoinScreen from '../JoinScreen/JoinScreen';
import { useToast } from '../../../../../hooks/useToast';
import { coreAPI } from '../../../../../API/core';
import { Spin, Tooltip } from 'antd';
import { Progress } from '../../../Progress';
import { ONBOARDING_TOTAL_STEPS_AMOUNT } from '../../../../../types/commonTypes';
import { Status } from '../../style';
import { Invitation as InvitationType } from '../../../../../redux/typings/organizations';
import { Invitation } from './Invitation/Invitation';
import { useEmail } from '../../../../../features/email/useEmail';
import { colors } from '../../../../../theme/colors';
import { useOnboardingAnalytics } from '../../../useOnboardingAnalytics';
import { useStore } from 'zustand';
import { onboardingStore } from '../../../stores/onboarding.store';

const { InvitationsList, CreateOrganizationLink } = Styles;

const api = new coreAPI();

interface Props {
  loading?: boolean;
  invitations: InvitationType[];
  onJoined: () => unknown;
  onCreateOrganization: () => unknown;
}

const JoinOrganization: React.FC<Props> = ({
  invitations,
  loading,
  onJoined,
  // onCreateOrganization,
}) => {
  const { totalSteps } = useStore(onboardingStore);

  const [isJoining, setIsJoining] = useState(false);
  const [currentJoin, setCurrentJoin] = useState('');

  const { getEmailLink } = useEmail();
  const { error } = useToast();
  const { organization } = useOnboardingAnalytics();

  const joinOrganization = async (organization: InvitationType) => {
    if (organization.type === 'invitation') {
      return api.acceptInvitation({ id: organization.id });
    } else if (organization.type === 'domain') {
      return api.joinOrganization(organization.organization.id);
    } else {
      throw new Error('Unsupported workspace type');
    }
  };

  const onJoinOrganization = (invitation: InvitationType) => {
    const { id, name } = invitation.organization;

    setIsJoining(true);
    setCurrentJoin(id);

    joinOrganization(invitation)
      .then(() => {
        organization.invitation.accepted(name);
        onJoined();
      })
      .catch(() => {
        error('Error joining workspace');
        setCurrentJoin('');
      })
      .finally(() => setIsJoining(false));
  };

  const cards = invitations.map((i, idx) => {
    const { organization, type, invitedBy } = i;

    const isInvitation = type === 'invitation';
    const label = isInvitation ? 'Accept Invitation' : 'Join';

    const isJoiningInvitation = i.id === currentJoin;

    return (
      <Invitation
        key={'invitation-card-' + idx}
        name={organization.name}
        owner={organization.owner}
        label={label}
        invited={isInvitation}
        invitedBy={invitedBy?.firstName + ' ' + invitedBy?.lastName}
        members={organization.members}
        disabled={isJoining}
        loading={isJoiningInvitation}
        selected={isJoiningInvitation}
        onJoin={() => onJoinOrganization(i)}
      />
    );
  });

  const tooltip = (
    <div>
      To switch workspaces, complete onboarding and go to your account settings. When you leave your
      current workspace you'll be prompted to create a new one.
      <br></br>
      <br></br>
      For extra help, email{' '}
      <a
        href={getEmailLink({
          subject: 'Switch my Workspace',
          to: ['support@update.ai'],
          body: '',
        })}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: colors.primary[500] }}
      >
        support@update.ai
      </a>
    </div>
  );

  return (
    <JoinScreen title="Your peers are already using UpdateAI!" subtitle="">
      <>
        {loading ? (
          <Spin
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          />
        ) : (
          <InvitationsList>{cards}</InvitationsList>
        )}

        <Status>
          <Tooltip title={tooltip} overlayInnerStyle={{ width: '400px' }}>
            <CreateOrganizationLink
              href="https://help.update.ai/en/articles/8096616-understanding-organizations-in-updateai-collaboration-privacy-and-workspace-functionality"
              target="_blank"
            >
              How do I create a new workspace?
            </CreateOrganizationLink>
          </Tooltip>

          <Progress total={totalSteps} current={2} />
        </Status>
      </>
    </JoinScreen>
  );
};

export default JoinOrganization;
