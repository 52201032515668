import React, { useContext, useMemo, useState } from 'react';
import { DateSelector } from '../../../../features/shared/components/DateSelector';
import { Cascader, Tag, Tooltip } from 'antd';
import { StoredFilterType } from '../StoredFilters/types';
import { useCustomerIntelligenceFilters } from '../../../../features/insights/hooks/useCustomerIntelligenceFilters';
import { useInsightsAnalytics } from '../../../../features/insights/hooks/useInsightsAnalytics';
import { AccountFilter } from '../AccountFilter';
import { CRMFilters } from './CRMFilters';
import Styles from '../../styles';
import { MultipleAccountsSelector } from '../../../accounts2/components/MultipleAccountsSelector';
import { useAccountSearch } from '../../../../features/account/account-search/account-search.hook';
import { EditCrmFilters } from '../../../portfolio-insights/components/EditCrmFilters';
import { useCrmFilterValues } from '../../../../features/crm-integration/hooks/use-crm-filter-values';
import useFeatures from '../../../../hooks/useFeatures';
const { FiltersGroup, HeaderRow } = Styles;

const CATEGORIES = [
  {
    label: 'Risks',
    value: 'Risks',
  },
  {
    label: 'Product Feedback',
    value: 'Product Feedback',
  },
  { label: 'Growth Opportunities', value: 'Growth Opportunities' },
  {
    label: 'Advocacy',
    value: 'Advocacy',
  },
];

export const Filters: React.FC = () => {
  const { accounts: availableAccounts } = useAccountSearch();
  const {
    category,
    dateRange,
    accounts,
    dateTypeOption,
    changeCategory,
    selectAccounts,
    resetFilters,
    changeDateRange,
    changeDateTypeOption,
  } = useCustomerIntelligenceFilters();
  const { refetch: refetchCrmFilters } = useCrmFilterValues();
  const [selectedFilter, setSelectedFilter] = useState<StoredFilterType | undefined>(undefined);

  const { portfolio } = useInsightsAnalytics();
  const features = useFeatures();
  const availableFeature = features.portfolioInsights;

  const SelectedFitlerTag = useMemo(() => {
    return selectedFilter ? (
      <Tag
        style={{
          height: 'auto',
          fontSize: '13px',
          paddingLeft: '10px',
          paddingRight: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '8px',
        }}
        closeIcon
        onClose={() => {
          resetFilters();
          setSelectedFilter(undefined);
          portfolio.filtered('reset filters');
        }}
      >
        <Tooltip title={selectedFilter.name}>
          <div
            style={{
              width: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {selectedFilter.name}
          </div>
        </Tooltip>
      </Tag>
    ) : (
      <></>
    );
  }, [selectedFilter?.name]);

  return (
    <header>
      <HeaderRow>
        <FiltersGroup>
          <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <label
              style={{
                position: 'absolute',
                top: '-20px',
                background: '#F0F0F0',
                padding: '0 4px',
              }}
            >
              Select Tracker Type
            </label>

            <Cascader
              options={CATEGORIES}
              changeOnSelect
              defaultValue={[category || 'Risk']}
              value={[category || 'Risk']}
              multiple={false}
              allowClear={false}
              disabled={!availableFeature}
              dropdownMenuColumnStyle={{
                fontFamily: 'Cera Pro',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
              }}
              onChange={c => {
                changeCategory(CATEGORIES.filter(cat => cat.value === c[0])[0].value);
                portfolio.filtered('category');
              }}
            />
          </div>

          <DateSelector
            defaultValues={dateRange}
            value={dateTypeOption}
            direction="past"
            predefinedRanges={{
              today: false,
              '1d': false,
              '7d': true,
              '30d': true,
              '3m': false,
              '6m': true,
              '12m': false,
            }}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onDateOptionChanged={option => changeDateTypeOption(option)}
            onDateRangeChanged={e => {
              changeDateRange(e);
            }}
          />

          <MultipleAccountsSelector
            value={accounts.map(a => a.id)}
            searchType="local"
            onSelect={selected => {
              selectAccounts(availableAccounts.filter(a => selected.includes(a.id)));
            }}
          />

          <CRMFilters />
          <EditCrmFilters onFinishEditing={refetchCrmFilters} />
        </FiltersGroup>
      </HeaderRow>
      <HeaderRow>
        <FiltersGroup>{SelectedFitlerTag}</FiltersGroup>
      </HeaderRow>
    </header>
  );
};
