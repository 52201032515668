import styled from 'styled-components';
import { colors } from '../../theme/colors';

const CellLabel = styled.span<{ width?: string }>`
  overflow: hidden;
  display: inline-block;
  max-width: ${props => props.width || 'auto'};
  text-overflow: ellipsis;
  color: ${colors.gray['700']};
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
`;

const Table = styled.table`
  table-layout: auto !important;

  width: 100%;
  border: 1px solid #d9d9d9;
  border-spacing: 0px;
  border-radius: 8px;
  border-collapse: separate;

  & > thead > tr > th::before {
    height: 0px !important;
  }

  & > tbody > tr:not(:last-of-type) > td {
    border-bottom-color: #d9d9d9 !important;
    border-bottom-width: 1px !important;
  }

  & > tbody > tr:last-of-type > td {
    border-bottom-width: 0px !important;
  }
`;

const TableHead = styled.thead`
  border-bottom: 1px solid #d9d9d9;
  background: #fafafa;
  bordertoprightradius: 8px;
  bordertopleftradius: 8px;
`;

const TableHeader = styled.th`
  padding: 12px 8px 12px 12px;
  text-align: left;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #d9d9d9;
`;

const ColumnName = styled.span`
  color: ${colors.gray['700']};
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.7px;
  text-transform: uppercase;
`;

export default {
  Table,
  TableHead,
  TableHeader,
  ColumnName,
  CellLabel,
};
