import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { ReactComponent as Achievement } from './assets/achievement.svg';
import { ReactComponent as ProgressSVG } from './assets/progress.svg';
import { CardHeader } from '../../CardHeader';
import { Container, NavigationButtons, Status } from '../../style';
import { selectOnboardingError, selectOnboardingLoading } from '../../../../../redux/selectors';
import { ONBOARDING_TOTAL_STEPS_AMOUNT } from '../../../../../types/commonTypes';
import { Progress } from '../../../Progress';
import { Card } from '../Card';

import * as Style from '../style';
import { FramedLayout } from '../../../../../components/layout/FramedLayout/FramedLayout';
import { useStore } from 'zustand';
import { onboardingStore } from '../../../stores/onboarding.store';
const { CardFrame, Cards, Header } = Style;

const roles = [
  {
    id: 1,
    title: 'I am a Team Leader',
    key: 'Leader',
    src: <Achievement />,
  },
  {
    id: 2,
    title: 'I am a Team Contributor',
    key: 'Contributor',
    src: <ProgressSVG />,
  },
];

interface Props {
  currentInternalStep: number;
  onNext: () => void;
  setRole: (s: string) => void;
}

export const KnowYourself: React.FC<Props> = ({ currentInternalStep, onNext, setRole }) => {
  const { totalSteps } = useStore(onboardingStore);
  const [selected, setSelected] = useState(1);
  const error = useSelector(selectOnboardingError);
  const loading = useSelector(selectOnboardingLoading);

  useEffect(() => {
    setRole(roles.find(each => each.id === selected)!.key);
  }, [selected]);

  return (
    <FramedLayout error={error} blockLayout={loading} logOut>
      <Container>
        <Header>
          <CardHeader title="Tell us about yourself" />
        </Header>

        <CardFrame>
          <Cards>
            {roles.map(each => {
              return (
                <Card
                  title={each.title}
                  image={each.src}
                  key={'card-roles' + each.id}
                  selected={selected === each.id}
                  onClick={() => setSelected(each.id)}
                />
              );
            })}
          </Cards>
        </CardFrame>

        <Status>
          <NavigationButtons>
            <div />

            <Button type="primary" loading={loading} onClick={() => onNext()}>
              Next
            </Button>
          </NavigationButtons>

          <Progress total={totalSteps} current={currentInternalStep} />
        </Status>
      </Container>
    </FramedLayout>
  );
};
