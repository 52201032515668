import { ReactElement, useCallback, useContext, useMemo } from 'react';
import { DashboardFilterContext } from './dashboard-filters.context';
import { SelectAll } from '../../../pages/insights/components/SelectAll';
import { InputNumber, Select } from 'antd';
import { DateSelector } from '../components/DateSelector';
// import { AccountFilterRender } from '../../../pages/insights/components/AccountFilter/AccountFilterRender';
// import { ProviderIntegration } from '../../../redux/typings/store';
import { DateRangeIntervals } from './types';
import { InputNumberRange } from '../../../pages/insights/components/InputNumberRange';
import { MeetingTypeFilter } from '../../home/components/MeetingTypeFilter';
import { OptionalTooltipWrapper } from '../../../componentsV2/OptionalTooltipWrapper';
import { MultipleAccountsSelector } from '../../../pages/accounts2/components/MultipleAccountsSelector';
import { ProductLinesSelector } from '../../insights/portoflio-insights/ProductLinesSelector';
import { CurrencyFilter } from '../../../pages/insights/components/CurrencyFilter';
import { OwnersSelector } from '../../insights/portfolio-insights/components/OwnersSelector';

export const useDashboardFilters = () => {
  const { store } = useContext(DashboardFilterContext);
  const storeData = store();

  const renderedFilters = useMemo(() => {
    const renders: { [k: string]: ReactElement } = {};

    Object.keys(storeData.filters).forEach(storeKey => {
      const filter = storeData.filters[storeKey];
      if (filter) {
        if (filter.type === 'list') {
          if (filter.componentProps?.type === 'currency') {
            const options = filter.value.options
              .map(option => Number(option.id))
              .filter(option => typeof option === 'number' && !isNaN(option));

            if (options.length > 0) {
              const min = Math.min(...options);
              const max = Math.max(...options);
              renders[storeKey] = (
                <CurrencyFilter
                  min={min}
                  max={max}
                  label={filter.componentProps?.label || ''}
                  onSelectionChanged={(min: number, max: number) => {
                    storeData.currencyFilterSelectionChanged(storeKey, min, max);
                  }}
                />
              );
            }
          } else {
            renders[storeKey] = (
              <SelectAll
                showAll
                style={filter.componentProps?.style}
                label={filter.componentProps?.label || ''}
                value={filter.value.options.filter(l => l.selected).map(l => l.id)}
                options={[...filter.value.options]}
                onSelectionChanged={(values: string[]) => {
                  storeData.listFilterSelectionChanged(storeKey, values);
                }}
              />
            );
          }
        }

        if (filter.type === 'select') {
          renders[storeKey] = (
            <Select
              placeholder={filter.componentProps?.label}
              style={filter.componentProps?.style}
              value={filter.value.selected}
              defaultValue={filter.value.defaultValue}
              onSelect={(values: number | string) => {
                storeData.selectFilterSelectionChanged(storeKey, values);
              }}
            >
              {filter.value.options.map(({ value, label, tooltip }, key) => (
                <Select.Option value={value} key={key}>
                  <OptionalTooltipWrapper display={!!tooltip} value={tooltip}>
                    {label}
                  </OptionalTooltipWrapper>
                </Select.Option>
              ))}
            </Select>
            // options={[...filter.value.options]}
          );
        }

        if (filter.type === 'number') {
          renders[storeKey] = <InputNumber />;
        }

        if (filter.type === 'multipleAccounts') {
          const key = storeData.filters[storeKey];
          const accountIds = key.type === 'multipleAccounts' ? key.value.selected || [] : [];
          renders[storeKey] = (
            <MultipleAccountsSelector
              searchType="local"
              onSelectionClear={() =>
                storeData.multipleAccountsFilterSelectionChanged(storeKey, [])
              }
              onSelect={accountIds =>
                storeData.multipleAccountsFilterSelectionChanged(storeKey, accountIds)
              }
              value={accountIds}
            />
          );
        }

        if (filter.type === 'productLines') {
          const key = storeData.filters[storeKey];
          const productLineIds = key.type === 'productLines' ? key.value.selected || [] : [];
          renders[storeKey] = (
            <ProductLinesSelector
              value={productLineIds}
              onChange={productLineIds => {
                console.log(storeKey, productLineIds);
                storeData.productLinesFilterSelectionChanged(storeKey, productLineIds);
              }}
            />
          );
        }

        if (filter.type === 'owners') {
          renders[storeKey] = (
            <OwnersSelector
              domain="update.ai"
              value={filter.value.selected}
              onChange={(value: string[]) =>
                storeData.ownersFilterSelectionChanged(storeKey, value)
              }
            />
          );
        }

        if (filter.type === 'numberRange') {
          renders[storeKey] = (
            <InputNumberRange
              style={filter.componentProps?.style}
              range={filter.value.interval}
              label={filter.componentProps?.label || ''}
              onChange={range => storeData.rangeNumberFilterValueChanged(storeKey, range)}
            />
          );
        }

        if (filter.type === 'dateRange') {
          renders[storeKey] = (
            <DateSelector
              key={filter.value.value}
              defaultValues={filter.value.interval}
              value={filter.value.value === 'custom' ? 'custom' : filter.value.dateOptionValue}
              direction="past"
              predefinedRanges={{
                today: false,
                '1d': false,
                '7d': false,
                '12m': false,
                '30d': true,
                '60d': true,
                '90d': true,
                '120d': true,
                '3m': false,
                '6m': false,
              }}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onDateOptionChanged={option => {
                storeData.dateOptionSelectionChanged(storeKey, option);
              }}
              onDateRangeChanged={e => {
                storeData.dateFilterSelectionChanged(storeKey, [
                  new Date(e[0] || ''),
                  new Date(e[1] || ''),
                ]);
              }}
            />
          );
        }

        if (filter.type === 'meetingType') {
          renders[storeKey] = (
            <MeetingTypeFilter
              style={filter.componentProps?.style}
              showTitle={false}
              flex={false}
              initialType={null}
              showAll
              size="middle"
              onChange={t => storeData.meetingTypeChanged(storeKey, t)}
            />
          );
        }
      }
    });

    return renders;
  }, [storeData.filters]);

  const render = (key: string) => {
    return renderedFilters[key];
  };

  const getListFilterOptions = useCallback(
    (k: string) => {
      const data = storeData.filters[k];
      if (data && data.type === 'list' && data.componentProps?.type === 'string') {
        const options = data.value.options.filter(s => s.selected).map(s => s.id);
        return { isValid: options.length > 0, filter: options };
      }

      if (data && data.type === 'list' && data.componentProps?.type === 'currency') {
        const { from, to } = data.value;

        if (!from || !to) {
          return { isValid: false, filter: {} };
        }

        const numberOptions = data.value.options
          .filter(option => !isNaN(Number(option.id)))
          .map(option => Number(option.id));
        const isAllRangeSelected =
          Math.min(...numberOptions) === from && Math.max(...numberOptions) === to;

        if (isAllRangeSelected) {
          return { isValid: false, filter: {} };
        }

        return {
          isValid: true,
          filter: {
            type: 'currency',
            filter: {
              from,
              to,
            },
          },
        };
      }

      return { isValid: false, filter: [] };
    },
    [storeData.filters],
  );

  const getDateFilterOptions = useCallback(
    (k: string): DateRangeIntervals => {
      const data = storeData.filters[k];
      if (data && data.type === 'dateRange') {
        return data.value.interval;
      }
      return [null, null];
    },
    [storeData.filters],
  );

  const getSelectFilterOptions = useCallback(
    (k: string): number | string => {
      const data = storeData.filters[k];
      if (data && data.type === 'select') {
        return data.value.selected || -1;
      }
      return -1;
    },
    [storeData.filters],
  );

  const getNumberRangeFilterOptions = useCallback(
    (k: string): [number, number] => {
      const data = storeData.filters[k];
      if (data && data.type === 'numberRange') {
        return data.value.interval;
      }
      return [0, 0];
    },
    [storeData.filters],
  );

  const getAccountFilterOptions = useCallback(
    (k: string): string | string[] | undefined => {
      const data = storeData.filters[k];
      if (data && data.type === 'account') {
        return data.value?.selected;
      }
      if (data && data.type === 'multipleAccounts') {
        return data.value?.selected;
      }
      return undefined;
    },
    [storeData.filters],
  );

  const getAccountsFilterOptions = useCallback(
    (k: string): string[] => {
      const data = storeData.filters[k];
      if (data && data.type === 'multipleAccounts') {
        return data.value?.selected;
      }
      return [];
    },
    [storeData.filters],
  );

  const getProductLinesFilterOptions = useCallback(
    (k: string): string[] => {
      const data = storeData.filters[k];
      if (data && data.type === 'productLines') {
        return data.value?.selected;
      }
      return [];
    },
    [storeData.filters],
  );

  const getOwnersFilterOptions = useCallback((): string[] => {
    const data = storeData.filters['owners'];
    if (data && data.type === 'owners') {
      return data.value?.selected;
    }
    return [];
  }, [storeData.filters]);

  return {
    render,
    changeAccountValue: storeData.accountFilterSelectionChanged,
    getListFilterOptions,
    getDateFilterOptions,
    getAccountFilterOptions,
    getAccountsFilterOptions,
    getProductLinesFilterOptions,
    getOwnersFilterOptions,
    getSelectFilterOptions,
    getNumberRangeFilterOptions,
    filters: storeData.filters,
    triggerDataLoad: storeData.triggerDataLoad,
    clearFilters: storeData.clearFilters,
  };
};
