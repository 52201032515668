import { Modal } from '../../../../componentsV2/Modal';
import {
  TopAccountRecord,
  TopAccountsCondensedTable,
} from './components/TopAccountsCondensedTable';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import Card from '../../../../componentsV2/Card';

import Styles from './styles';
import {
  Badge,
  Button,
  Collapse,
  Divider,
  Dropdown,
  Flex,
  Input,
  InputRef,
  Pagination,
  Select,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { DashboardFilterProvider } from '../../../shared/dashboard-filters/dashboard-filters.context';
import { useDashboardFilters } from '../../../shared/dashboard-filters/dashboard-filters.hooks';
import { addDays } from 'date-fns';
import { useToast } from '../../../../hooks/useToast';
import {
  CaretRightOutlined,
  CopyOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExperimentOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  PushpinOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Alert } from '../../../../components/alerts/Alert';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux/typings/store';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { useEventStore } from './stores/events';
import { TopicType } from '../../../../services/types';

const { Container, CardContents, SummaryContainer } = Styles;

type OnCloseArgs = {
  reload?: boolean;
};

interface Props {
  open: boolean;
  topicId: string;
  filters: {
    from: string;
    to: string;
    accountId?: string;
  };
  onClose: (args?: OnCloseArgs) => void;
  onEditTopicClicked?: (args: { id: string; name: string; description: string }) => void;
  onFollowTopicClicked?: (id: string) => void;
  onUnfollowTopicClicked?: (id: string) => void;
}

export const Wrapper: React.FC<PropsWithChildren<Omit<Props, 'filters'>>> = ({
  topicId,
  open,
  onClose,
  onEditTopicClicked,
  onFollowTopicClicked,
  onUnfollowTopicClicked,
  children,
}) => {
  const { filters, getAccountFilterOptions, getDateFilterOptions, render, changeAccountValue } =
    useDashboardFilters();

  const [topic, setTopic] = useState<TopicType | null>(null);

  const user = useSelector((store: Store) => store.session.user);
  const isOwner = user?.membership.role === 'OWNER' || user?.membership.role === 'ADMIN';

  const topAccountsFilters = useMemo(() => {
    const dateValues = getDateFilterOptions('dateRange');
    const accountValue = getAccountFilterOptions('account');

    return {
      from: dateValues[0] ? dateValues[0].toISOString() : addDays(new Date(), -30).toISOString(),
      to: dateValues[1] ? dateValues[1].toISOString() : new Date().toISOString(),
      accountId: accountValue || '',
    };
  }, [filters]);

  const [sortBy, setSortBy] = useState('mentions');
  const [page, setPage] = useState(1);
  const [summarizing, setIsSummarizing] = useState(false);
  const [summary, setSummary] = useState('');
  const [summarized, setSummarized] = useState(false);
  const { success, error } = useToast();

  const [isAddingProductLines, setIsAddingProductLines] = useState(false);
  const [selectedProductLines, setSelectedProductLines] = useState<
    { label: string; value: string }[]
  >([]);
  const [productLinesColors, setProductLinesColors] = useState<Record<string, string>>({});
  const [isSavingProductLines, setIsSavingProductLines] = useState<boolean>(false);

  const [newProductLineName, setNewProductLineName] = useState('');
  const inputRef = useRef<InputRef>(null);

  const onNewProductLineNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProductLineName(event.target.value);
  };

  const { isLoading: isLoadingTopic } = useQuery({
    queryKey: ['topic-details', topicId],
    queryFn: async () => {
      if (!topicId) return null;

      const topic = await coreService.getTopicById(topicId);
      return topic;
    },
    onSuccess: data => {
      setTopic(data);
    },
  });

  const handleClose = () => {
    changeAccountValue('account', '');
    onClose();
    setIsSummarizing(false);
    setSummary('');
    setSummarized(false);
    setIsAddingProductLines(false);
  };

  const { data: topAccounts, isFetching: topAccountsFetching } = useQuery({
    queryKey: [
      'topic-modal-accounts',
      topAccountsFilters.from,
      topAccountsFilters.to,
      topAccountsFilters.accountId,
      page,
      topicId,
      sortBy,
      open,
    ],
    queryFn: async () => {
      if (!open) {
        return null;
      }

      if (!topicId) return null;

      const options: any = [];
      if (topAccountsFilters.accountId) {
        options['accountIds'] = [topAccountsFilters.accountId];
      }

      const topAcc = await coreService.getTopAccountsByTopicId(
        topicId,
        topAccountsFilters.from,
        topAccountsFilters.to,
        page,
        10,
        sortBy,
        options,
      );
      return topAcc;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const {
    data: topicProductLines,
    isFetching: isFetchingProductLines,
    refetch: refetchTopicProductLines,
  } = useQuery({
    queryKey: ['topic-product-lines', topicId],
    queryFn: async () => {
      if (!open) {
        return null;
      }

      if (!topicId) return null;

      const topicP = await coreService.getTopicProductLines(topicId);
      return topicP;
    },
    onSuccess: data => {
      setSelectedProductLines(
        data?.map(productLine => ({ label: productLine.name, value: productLine.id })) ?? [],
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const {
    data: workspaceProductLines,
    isFetching: isFetchingWorkspaceProductLines,
    refetch: refetchWorkspaceProductLines,
  } = useQuery({
    queryKey: ['workspace-product-lines'],
    queryFn: async () => {
      if (!open) {
        return null;
      }

      if (!topicId) return null;

      const productLinesResponse = await coreService.getWorkspaceProductLines();
      return productLinesResponse;
    },
    onSuccess: data => {
      setProductLinesColors(
        data?.reduce((acc: Record<string, string>, productLine) => {
          acc[productLine.id] = productLine.color;
          return acc;
        }, {}) ?? {},
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    const unsubscribe = useEventStore
      .getState()
      .subscribe('insight-product-lines-updated', refetchTopicProductLines);

    return () => {
      unsubscribe();
    };
  }, []);

  const tableData: TopAccountRecord[] = useMemo(() => {
    if (!topAccounts) return [];

    return topAccounts.accounts.map(acc => {
      return {
        account: {
          id: acc.id,
          name: acc.name || '',
          provider: acc.provider || '',
        },
        mentions: acc.mentions || 0,
        meetings: acc.meetings || 0,
        arr: acc.arr || 0,
        renewalDate: acc.renewalDate ? new Date(acc.renewalDate) : new Date(),
      };
    });
  }, [topAccounts]);

  const accountFilter = useMemo(() => {
    return render('account');
  }, [topAccountsFilters]);

  const summarizeTopics = async () => {
    setIsSummarizing(true);
    setSummarized(false);
    coreService
      .summarizeTopics(topicId, topAccountsFilters.from, topAccountsFilters.to)
      .then(summarization => {
        setSummary(summarization);
      })
      .finally(() => {
        setSummarized(true);
        setIsSummarizing(false);
      });
  };

  const handleChangeSort = (sortBy: string) => {
    setSortBy(sortBy);
    setPage(1);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(summary);
    success('Summary copied to clipboard');
  };

  const handleAddProductLines = () => {
    setIsAddingProductLines(true);
  };

  const handleSaveProductLines = async () => {
    try {
      setIsSavingProductLines(true);
      const productLineIds = selectedProductLines.map(productLine => productLine.value);
      await coreService.handleTopicProductLines(topicId, productLineIds);
      await refetchTopicProductLines();
      setIsAddingProductLines(false);
      success('Product lines saved successfully.');
    } catch (err) {
      error('There was an error saving topic product lines.');
    } finally {
      setIsSavingProductLines(false);
    }
  };

  type TagRender = {
    color: string;
    id: string;
    name: string;
    onRemove: (id: string) => void;
  };

  const onSaveNewProductLine = async () => {
    setIsSavingProductLines(true);
    await coreService.createWorkspaceProductLine({
      name: newProductLineName,
      description: '',
      color: 'green',
    });

    const newProductLine = await refetchWorkspaceProductLines().then(response =>
      response.data?.find(productLine => productLine.name === newProductLineName),
    );

    if (newProductLine) {
      await coreService.handleTopicProductLines(topicId, [
        ...topicProductLines?.map(productLine => productLine.id)!,
        newProductLine.id,
      ]);

      await refetchTopicProductLines();
    }

    setNewProductLineName('');
    setIsSavingProductLines(false);
  };

  const handlePinTopic = async () => {
    const { pinned } = await coreService.pinTopic(topicId);
    setTopic(
      prev =>
        ({
          ...prev,
          pinned,
        } as TopicType),
    );

    success(
      pinned
        ? 'Topic pinned. This topic will remain visible even when other topics are automatically recalculated.'
        : 'Topic unpinned successfully. Unpinned topics may be recalculated automatically.',
    );
  };

  const handleDisableTopic = async () => {
    const { disabled } = await coreService.disableTopic(topicId);
    setTopic(
      prev =>
        ({
          ...prev,
          disabled,
        } as TopicType),
    );

    success(
      disabled
        ? 'Topic successfully disabled. Insights will no longer be added.'
        : 'Topic successfully enabled. Insights will be added.',
    );
  };

  const handleRemoveTopic = async () => {
    try {
      await coreService.removeTopic(topicId);
      success('Topic removed successfully');
      onClose({ reload: true });
    } catch (err) {
      error('There was an error removing the topic.');
    }
  };

  const handleArchiveTopic = async () => {
    try {
      await coreService.archiveTopic(topicId);
      success(
        'Topic archived successfully. Insights will no longer be associated with this topic.',
      );
      onClose({ reload: true });
    } catch (err) {
      error('There was an error archiving the topic.');
    }
  };

  const getRemoveActionTooltip = (record: TopicType) => {
    if (!record.custom) {
      return `Only custom topics can be ${
        (topAccounts?.accounts?.length ?? 0) > 0 ? 'archived' : 'removed'
      }`;
    }

    if ((topAccounts?.accounts?.length ?? 0) === 0) {
      return 'Removing a custom topic will delete it permanently.';
    }

    if ((topAccounts?.accounts?.length ?? 0) > 0) {
      return `Archiving a custom topic will make it hidden from the dashboard and no it will no longer be receiving new insights.`;
    }

    return '';
  };

  if (isLoadingTopic || !topic) {
    return (
      <Modal
        hideFooter
        width={1296}
        open={open}
        title={'Loading topic...'}
        onClose={handleClose}
        zIndex={999}
      >
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      hideFooter
      width={1296}
      open={open}
      title={
        <Flex gap={8} align="center">
          {topic.pinned && (
            <Tooltip title="This topic is pinned.">
              <PushpinOutlined style={{ width: '16px' }} />
            </Tooltip>
          )}

          {topic.disabled && (
            <Tooltip title="This topic is disabled.">
              <StopOutlined style={{ width: '16px' }} />
            </Tooltip>
          )}

          {topic.custom && (
            <Tooltip title="This is a custom topic.">
              <ExperimentOutlined style={{ width: '16px' }} />
            </Tooltip>
          )}

          <span>{topic.name}</span>
        </Flex>
      }
      onClose={handleClose}
      zIndex={999}
      headerExtraContent={
        <Flex gap={8} align="center">
          <Tooltip title={!isOwner ? 'Only owners or admins can edit topics.' : null}>
            <Button
              disabled={!isOwner}
              onClick={() => {
                if (onEditTopicClicked) {
                  onEditTopicClicked({
                    id: topic.id,
                    name: topic.name,
                    description: topic.description,
                  });
                }
              }}
            >
              <Space>
                <EditOutlined />
                Edit
              </Space>
            </Button>
          </Tooltip>

          <Tooltip
            title={
              !isOwner
                ? 'Only owners or admins can pin topics.'
                : topic.custom
                ? 'Custom made topics cannot be pinned. They will not be recalculated.'
                : null
            }
          >
            <Button disabled={!isOwner || topic.custom} onClick={handlePinTopic}>
              <Space>
                <PushpinOutlined />
                {topic.custom ? 'Pin' : topic.pinned ? 'Unpin' : 'Pin'}
              </Space>
            </Button>
          </Tooltip>

          <Tooltip
            title={
              !isOwner
                ? 'Only owners or admins can disable topics.'
                : topic.pinned
                ? 'Cannot disable a pinned topic.'
                : null
            }
          >
            <Button disabled={!isOwner || topic.pinned} onClick={handleDisableTopic}>
              <Space>
                {topic.disabled ? <CaretRightOutlined /> : <StopOutlined />}
                {topic.disabled ? 'Enable' : 'Disable'}
              </Space>
            </Button>
          </Tooltip>

          <Button
            onClick={() => {
              if (topic.following && onUnfollowTopicClicked) {
                onUnfollowTopicClicked(topic.id);
              }

              if (!topic.following && onFollowTopicClicked) {
                onFollowTopicClicked(topic.id);
              }
            }}
          >
            <Space>
              {topic.following ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              {topic.following ? 'Unfollow' : 'Follow'}
            </Space>
          </Button>

          <Tooltip title={getRemoveActionTooltip(topic)}>
            <Button
              disabled={!topic.custom}
              danger
              onClick={() => {
                if ((topAccounts?.accounts?.length ?? 0) > 0) {
                  handleArchiveTopic();
                  return;
                }

                handleRemoveTopic();
              }}
            >
              <Space>
                {(topAccounts?.accounts?.length ?? 0) > 0 ? <InboxOutlined /> : <DeleteOutlined />}
                {(topAccounts?.accounts?.length ?? 0) > 0 ? 'Archive' : 'Remove'}
              </Space>
            </Button>
          </Tooltip>
        </Flex>
      }
    >
      <Container>
        {children}
        <Flex vertical gap={8}>
          {topic.pinned && isOwner && (
            <Alert
              customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
              message={`When a topic is pinned, it will not be removed when new topics are added. ${
                isOwner ? 'You' : 'Only owners or admins'
              } can unpin it anytime.`}
              variant="info"
              title=""
            />
          )}

          {topic.disabled && isOwner && (
            <Alert
              customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
              message={`When a topic is disabled, it will no longer receive new insights. ${
                isOwner ? 'You' : 'Only owners or admins'
              } can re-enable it anytime.`}
              variant="warning"
              title=""
            />
          )}
        </Flex>
        <Flex gap={'8px'} justify={'space-between'}>
          <Flex gap={'8px'}>
            <Select
              value={sortBy}
              onChange={value => handleChangeSort(value)}
              options={[
                { value: 'mentions', label: '# of Detections' },
                { value: 'meetings', label: '# of Meetings' },
              ]}
            />
            {render('dateRange')}
            {accountFilter}
          </Flex>

          <Alert
            customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
            message={
              !topAccounts?.eventsCount
                ? 'Loading information.'
                : `Insights come from ${topAccounts?.eventsCount} meeting recaps based on your applied filters and meeting access.`
            }
            variant="info"
            title=""
          />
        </Flex>
        <SummaryContainer>
          {summarized && (
            <Flex vertical gap={'8px'}>
              {' '}
              <Flex gap={'8px'}>
                <Button type="default" onClick={summarizeTopics}>
                  Summarize this topic
                </Button>
                <Button icon={<CopyOutlined />} onClick={handleCopy} />
              </Flex>
              {summary}
            </Flex>
          )}

          {!summarized && !summarizing && (
            <Button type="primary" onClick={summarizeTopics}>
              Summarize this topic
            </Button>
          )}

          {summarizing && (
            <Spin
              size="small"
              tip="Generating your summary. Closing the modal at any time will reset the summary."
              style={{
                display: 'inline-flex',
                minWidth: '600px',
                height: '100%',
                position: 'relative',
                padding: '20px',
              }}
            >
              <div />
            </Spin>
          )}
        </SummaryContainer>

        <Collapse
          defaultActiveKey={['2']}
          items={[
            {
              key: '1',
              label: 'Description',
              children: (
                <Typography.Text>
                  {topic.description ?? 'Description not currently available.'}
                </Typography.Text>
              ),
            },
            {
              key: '2',
              label: (
                <Flex gap={8}>
                  <div>Product Lines</div>
                  <Tag color="volcano">{topicProductLines ? topicProductLines.length : 0}</Tag>
                </Flex>
              ),

              children: (
                <Flex>
                  {!isAddingProductLines && (
                    <>
                      {isFetchingProductLines && <Spin size="small" />}
                      {topicProductLines &&
                        topicProductLines.map(productLine => (
                          <Tooltip title={productLine.description}>
                            <Tag style={{ cursor: 'default' }} color={productLine.color}>
                              {productLine.name}
                            </Tag>
                          </Tooltip>
                        ))}

                      {!isFetchingProductLines && (
                        <Tag
                          color="default"
                          onClick={handleAddProductLines}
                          style={{ cursor: 'pointer' }}
                        >
                          {topicProductLines && topicProductLines.length > 0 ? 'Change' : 'Add'}{' '}
                          Product Lines
                        </Tag>
                      )}
                    </>
                  )}

                  {isAddingProductLines && (
                    <Flex gap={8}>
                      <Select
                        style={{ minWidth: 200 }}
                        placeholder="Select product lines"
                        mode="multiple"
                        labelInValue
                        dropdownRender={menu => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                              <Flex vertical gap={8}>
                                <Input
                                  placeholder="Product Line name"
                                  ref={inputRef}
                                  value={newProductLineName}
                                  onChange={onNewProductLineNameChange}
                                  onKeyDown={e => e.stopPropagation()}
                                  disabled={isSavingProductLines || !isOwner}
                                />
                                <Button
                                  type="primary"
                                  icon={<PlusOutlined />}
                                  onClick={onSaveNewProductLine}
                                  loading={isSavingProductLines}
                                  disabled={!newProductLineName}
                                >
                                  <OptionalTooltipWrapper
                                    value="Only owners or admins can edit topics."
                                    display={!isOwner}
                                  >
                                    Add Product Line
                                  </OptionalTooltipWrapper>
                                </Button>
                              </Flex>
                            </Space>
                          </>
                        )}
                        tagRender={({ label, value, onClose }) => (
                          <Tag
                            color={productLinesColors[value]}
                            closable={true}
                            onClose={onClose}
                            style={{ marginInlineEnd: 4 }}
                          >
                            {label}
                          </Tag>
                        )}
                        value={selectedProductLines}
                        onChange={setSelectedProductLines}
                        disabled={isSavingProductLines}
                        options={
                          workspaceProductLines?.map(productLine => ({
                            label: productLine.name,
                            value: productLine.id,
                            title: productLine.color,
                          })) ?? []
                        }
                      />

                      <Button
                        onClick={handleSaveProductLines}
                        type="primary"
                        loading={isSavingProductLines}
                      >
                        Save
                      </Button>
                    </Flex>
                  )}
                </Flex>
              ),
            },
          ]}
        />

        <Card title="Accounts">
          <CardContents>
            <TopAccountsCondensedTable
              elems={tableData}
              loading={topAccountsFetching}
              filters={topAccountsFilters}
              sortBy={sortBy}
              topic={topic}
            />
            <Pagination
              style={{
                alignSelf: 'flex-end',
              }}
              onChange={value => setPage(value)}
              hideOnSinglePage
              showSizeChanger={false}
              total={topAccounts?.pagination?.items?.total || 1}
              pageSize={topAccounts?.pagination?.pages?.pageSize || 10}
              current={topAccounts?.pagination?.pages?.currentPage || 1}
            />
          </CardContents>
        </Card>
      </Container>
    </Modal>
  );
};

export const TopicDetailModal: React.FC<PropsWithChildren<Props>> = ({
  topicId,
  open,
  onClose,
  onEditTopicClicked,
  onFollowTopicClicked,
  onUnfollowTopicClicked,
  filters,
  children,
}) => {
  return (
    <DashboardFilterProvider
      filters={{
        dateRange: {
          type: 'dateRange',
          config: {
            interval: [new Date(Date.parse(filters.from)), new Date(Date.parse(filters.to))],
            value: 'custom',
          },
        },
        account: {
          type: 'account',
          config: {
            selected: filters.accountId || '',
          },
        },
      }}
    >
      <Wrapper
        open={open}
        topicId={topicId}
        onClose={onClose}
        onEditTopicClicked={onEditTopicClicked}
        onFollowTopicClicked={onFollowTopicClicked}
        onUnfollowTopicClicked={onUnfollowTopicClicked}
      >
        {children}
      </Wrapper>
    </DashboardFilterProvider>
  );
};
