import { FC } from 'react';
import { SelectAll } from '../../../../pages/insights/components/SelectAll';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
};

export const ProductLinesSelector: FC<Props> = ({ onChange, value }) => {
  const { isFetching, data } = useQuery({
    queryKey: ['workspace-product-lines'],
    queryFn: () => coreService.getWorkspaceProductLines(),
  });

  return (
    <SelectAll
      showAll
      style={{ maxWidth: '240px' }}
      label={'Product Line'}
      value={value}
      disabled={isFetching}
      options={
        data?.map(productLine => ({
          label: productLine.name,
          id: productLine.id,
        })) ?? []
      }
      onSelectionChanged={onChange}
    />
  );
};
