import styled from "styled-components";

const TopicLabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    .edit-button {
        display: none;
    }

    &:hover .edit-button {
        display: block;
    }
`

export default {
    TopicLabelContainer
}