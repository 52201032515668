import { useQuery } from '@tanstack/react-query';
import { createApiClient } from '../../../API/core';
import { Subscription } from '../types';

const apiClient = createApiClient();

export const useSubscription = () => {
  const { data, isFetching } = useQuery({
    queryKey: ['subscription'],
    queryFn: async () => {
      const { data } = await apiClient.get<Subscription>('/api/subscription');
      return data;
    },
    refetchOnWindowFocus: false,
  });

  return {
    subscription: data,
    isFetching,
  };
};
