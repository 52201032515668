import React, { useEffect, useState } from 'react';
import Styles from './styles';
import { Check } from '../../../../../../shared/components/Icons/Check';

const { Container, Header, OptionContent, OptionContainer, CheckContainer } = Styles;

const speeds = [0.5, 0.75, 1, 1.25, 1.5, 2, 2.5];

interface Props {
  onSpeedChange: (speed: number) => unknown;
  speed?: number;
}

export const SpeedList: React.FC<Props> = ({ onSpeedChange, speed = 1 }) => {
  const [selectedSpeed, setSelectedSpeed] = useState(speed);

  useEffect(() => {
    setSelectedSpeed(speed);
  }, [speed]);

  const changeSpeed = (value: number) => {
    setSelectedSpeed(value);
    onSpeedChange(value);
  };

  return (
    <Container>
      <Header>Playback speed</Header>
      {speeds.map(speed => {
        const selected = selectedSpeed === speed;
        const speedLabel = speed === 1 ? '1x - Normal' : `${speed}x`;
        return (
          <OptionContainer onClick={() => changeSpeed(speed)}>
            <OptionContent selected={selected}>
              <CheckContainer>{selected && <Check />}</CheckContainer>
              <span>{speedLabel}</span>
            </OptionContent>
          </OptionContainer>
        );
      })}
    </Container>
  );
};
