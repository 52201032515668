import { useProfile } from '../../features/profile/profile.hook';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { useState } from 'react';
import { ProfileAvatar } from '../../componentsV2/ProfileAvatar';
import { Button, Flex } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Bio } from './Tabs/Bio';
import { AccountIcon } from '../../features/shared/components/AccountIcon';
import { useEmail } from '../../features/email/useEmail';
import { History } from './Tabs/History';
import TabV2 from '../../features/shared/components/TabV2';
import { OptionalTooltipWrapper } from '../../componentsV2/OptionalTooltipWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import DecisionMakerTag from '../../components/contacts/DecisionMakerTag';
import AdvocacyClassification from '../../components/contacts/AdvocacyClassification';

export const ProfilePage: React.FC = () => {
  const { TabItem, Tab } = TabV2;
  const { profile, isFetching } = useProfile();
  const navigate = useNavigate();
  const email = useEmail();

  const [selectedTab, setSelectedTab] = useState('bio');

  const tabItems = [
    {
      key: 'bio',
      label: <TabItem label="Stakeholder Bio" isActive={selectedTab === 'bio'} />,
      children: <Bio />,
    },
    {
      key: 'history',
      label: <TabItem label="Past Meetings" isActive={selectedTab === 'history'} />,
      children: <History />,
    },
  ];

  const handleAccountClicked = () => {
    navigate(`/accounts/${profile.account.id}`);
  };

  return (
    <TitledVerticalFlexLayout
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'baseline',
            width: '100%',
          }}
        >
          <ProfileAvatar
            image={<>{(profile.name || profile.email).slice(0, 1).toUpperCase()}</>}
            title={profile.name || profile.email}
            subtitle={
              <Flex gap={'8px'}>
                <div
                  style={{ display: 'flex', gap: '10px', alignItems: 'center', cursor: 'pointer' }}
                  onClick={handleAccountClicked}
                >
                  <AccountIcon provider={profile.account.provider} styles={{ width: '17px' }} />{' '}
                  {profile.account.name}
                </div>
                <Flex gap={'2px'}>
                  {profile.decisionMakingStatus?.isDecisionMaker && <DecisionMakerTag />}
                  {profile.advocacyClassification &&
                    profile.advocacyClassification?.classification !== 'None' && (
                      <AdvocacyClassification
                        classification={profile.advocacyClassification?.classification}
                      />
                    )}
                </Flex>
              </Flex>
            }
            loading={isFetching}
            titlewidth={500}
            width={72}
            height={72}
            fontSize={32}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <OptionalTooltipWrapper
              display={!!!profile.url}
              value="No url provided for this profile"
            >
              <Button
                disabled={!!!profile.url}
                onClick={() => window.open(profile.url || '')}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                View in CRM
                <FontAwesomeIcon size="sm" icon={faExternalLink} style={{ marginLeft: '10px' }} />
              </Button>
            </OptionalTooltipWrapper>

            <Button
              type="primary"
              onClick={() => window.open(email.getEmailLink({ to: [profile.email] }))}
            >
              <MailOutlined />
              Contact this Person
            </Button>
          </div>
        </div>
      }
    >
      <Tab
        items={tabItems}
        activeKey={selectedTab}
        onChange={(tab: string) => setSelectedTab(tab)}
      />
    </TitledVerticalFlexLayout>
  );
};
