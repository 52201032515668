import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.span`
  color: var(--N-900, #0d283b);
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const DoubleColumnContainer = styled.div`
  display: flex;
  gap: 8px;

  & > div {
    flex: 50%;
  }
`;

export default {
    Container,
    FormItem,
    Label,
    DoubleColumnContainer,
};