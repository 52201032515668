import { ReactNode } from 'react';
import { grayDark } from '../../assets/colors';
import styled from 'styled-components';

const LabelContainer = styled.div`
  color: ${grayDark};
  text-align: center;
`;

interface Props {
  align?: Align;
  children?: ReactNode;
  className?: string;
  clickable?: boolean;
  color?: string;
  customStyle?: {};
  marginBottom?: string;
  onClick?: () => void;
  size: Size;
  spacing?: string;
  uppercase?: boolean;
}

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type Align = undefined | 'left' | 'right' | 'center';

const fontSizes = {
  xs: '.68rem',
  sm: '.8rem',
  md: '.9rem',
  lg: '1.063rem',
  xl: '1.2rem',
};

const Label: React.FC<Props> = ({
  align = 'left',
  children,
  className,
  clickable,
  color,
  customStyle = {},
  marginBottom,
  onClick = () => null,
  size = 'sm',
  spacing,
  uppercase = true,
}) => (
  <LabelContainer
    className={`${className}`}
    style={{
      fontSize: fontSizes[size],
      textTransform: uppercase ? 'uppercase' : 'none',
      textAlign: align,
      letterSpacing: spacing ?? '0',
      marginBottom: marginBottom ?? '0',
      color: color ?? 'inherit',
      cursor: clickable ? 'pointer' : 'default',
      ...customStyle,
    }}
    onClick={onClick}
  >
    {children}
  </LabelContainer>
);

export default Label;
