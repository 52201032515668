import React, { useEffect, useState } from 'react';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { Button, Flex, Space, Typography } from 'antd';
import { CustomerIntelligenceProvider } from '../../features/insights/context/CustomerIntelligenceProvider';
import { useCustomerIntelligenceFilters } from '../../features/insights/hooks/useCustomerIntelligenceFilters';
import { Alert } from '../../components/alerts/Alert';
import { CategoriesCard } from './components/CategoriesCard';
import { SubCategoriesCard } from './components/SubCategoriesCard';
import { SmartClipsDrawer } from './components/SmartClipsDrawer';
import { useCustomerIntelligenceData } from '../../features/insights/hooks/useCustomerIntelligenceData';
import { ManageInsightsFolderModal } from '../../features/libraries/components/ManageInsightFoldersModal';
import { ApiOutlined, DatabaseOutlined } from '@ant-design/icons';
import { ManageCategoriesModal } from './components/ManageCategoriesModal';
import { CategoryManagementProvider } from '../../features/insights/context/CategoryManagementProvider';
import { InsightCategoriesProvider } from '../../features/insights/context/InsightCategoriesProvider';
import { useInsightsAnalytics } from '../../features/insights/hooks/useInsightsAnalytics';
import { useLocation } from 'react-router-dom';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import { Filters } from './components/Filters';
import useFeatures from '../../hooks/useFeatures';

import Styles from './styles';
import { useCrmIntegration } from '../../features/crm-integration/hooks/use-crm-integration';
import { AccountSearchProvider } from '../../features/account/account-search/account-search.context';
import { InsightsAskMeAnything } from './components/InsightsAskMeAnything';
const { Contents, Header } = Styles;

export const Insights: React.FC = () => {
  useLocationChange();

  const [showManageCategoriesModal, setShowManageCategoriesModal] = useState(false);

  const [oldDR, setOldDR] = React.useState<(null | Date)[]>([null, null]);

  const { category, dateRange, selectAccounts, changeCategory, resetFilters } =
    useCustomerIntelligenceFilters();
  const { workspaceCrmIntegration } = useCrmIntegration();
  const isCRMConnected = workspaceCrmIntegration?.workspace.integration !== null;

  const { meetingsCount, refetchData } = useCustomerIntelligenceData();
  const { portfolio } = useInsightsAnalytics();

  const { search } = useLocation();

  const features = useFeatures();
  const availableFeature = features.portfolioInsights;

  const handleConnectCrm = () => {
    window.open('/settings?tab=integrations', '_blank');
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const type = params.get('type');
    const accountId = params.get('accountId');

    if (type) {
      changeCategory(type);
    }

    if (accountId) {
      const [id, name, provider] = accountId.split(',');
      selectAccounts([{ id, name, provider }]);
    }
  }, [search]);

  useEffect(() => {
    if (!oldDR[0] && !oldDR[1]) {
      setOldDR(dateRange);
    } else {
      portfolio.filtered('date range');
    }
  }, [dateRange]);

  return (
    <CustomerIntelligenceProvider>
      <AccountSearchProvider searchId="insights">
        <InsightCategoriesProvider insightType={category}>
          <CategoryManagementProvider>
            <ManageCategoriesModal
              open={showManageCategoriesModal}
              onClose={() => setShowManageCategoriesModal(false)}
              onDiscard={() => setShowManageCategoriesModal(false)}
              onChangesSaved={() => null}
            />
            <TitledVerticalFlexLayout
              sider={true}
              title="VoC Topic Tracking"
              titleDescription={
                <Flex align="center" justify="space-between">
                  <div style={{ maxWidth: '1000px' }}>
                    This tool lets you analyze data exclusively from your external meetings,
                    providing deeper insights into client interactions.
                  </div>
                  <div>
                    <InsightsAskMeAnything category={category} />
                  </div>
                </Flex>
              }
            >
              <UpgradeToPro plan="business" display={!availableFeature}>
                <Contents>
                  <ManageInsightsFolderModal />
                  <Header $visible={true}>
                    <Flex justify="space-between" align="center">
                      <Filters />

                      <Flex gap="8px">
                        <Button type="default" onClick={resetFilters}>
                          Reset Filters
                        </Button>
                      </Flex>
                    </Flex>
                  </Header>
                  <SmartClipsDrawer />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '50px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {!isCRMConnected && (
                        <Button type="link" onClick={handleConnectCrm}>
                          <Space>
                            Connect your CRM <ApiOutlined />
                          </Space>
                        </Button>
                      )}
                    </div>

                    <Alert
                      customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
                      message={`Insights come from ${meetingsCount} meeting recaps based on your applied filters and meeting access.`}
                      variant="info"
                      title=""
                    />
                  </div>
                  <CategoriesCard onManageCategories={() => setShowManageCategoriesModal(true)} />
                  <SubCategoriesCard />
                </Contents>
              </UpgradeToPro>
            </TitledVerticalFlexLayout>
          </CategoryManagementProvider>
        </InsightCategoriesProvider>
      </AccountSearchProvider>
    </CustomerIntelligenceProvider>
  );
};
