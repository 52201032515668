import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TopAccountRecord, TopAccountsTable } from './components/TopAccountsTable';
import { AccountTopicClickHandler, SelectedTopic } from './types';
import Card from '../../../../componentsV2/Card';
import { ViewAllAccountsModal } from './components/ViewAllAccountsModal';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { Button, Flex, Select } from 'antd';
import { TopicDetailModal } from '../TopicDetailModal';
import { DashboardFilterContext } from '../../../shared/dashboard-filters/dashboard-filters.context';
import { TopAccountsType } from '../../../../services/types';

interface TopAccountsSectionProps {
  onTopicSelected: AccountTopicClickHandler;
  onAccountClicked: (accountId: string) => unknown;
  filters: {
    from: string;
    to: string;
    accountId?: string;
    crmFilters?: Record<string, string[]>;
  };
}

const TopAccountsSection: React.FC<TopAccountsSectionProps> = ({
  onTopicSelected,
  filters,
  onAccountClicked,
}) => {
  const { store } = useContext(DashboardFilterContext);

  const [response, setResponse] = useState<TopAccountsType>();

  const [sortBy, setSortBy] = useState<string>('mentions');
  const [showAllAccounts, setShowAllAccounts] = useState(false);
  const [showTopics, setShowTopics] = useState<SelectedTopic | null>(null);

  const { isFetching, refetch } = useQuery({
    queryKey: [
      'view-all-accounts',
      filters.from,
      filters.to,
      filters.accountId,
      sortBy,
      filters.crmFilters,
    ],
    queryFn: async () => {
      const options: any = {};
      if (filters.accountId) {
        options['accountIds'] = [filters.accountId];
      }

      if (filters.crmFilters) {
        options['crmFilters'] = filters.crmFilters;
      }

      return coreService.getTopAccounts(filters.from, filters.to, 1, 10, sortBy, options);
    },
    onSuccess: data => {
      setResponse(data);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const state = store.getState();
    if (!state.hasRefetchMethod('top-accounts')) {
      state.setRefetchMethod('top-accounts', refetch);
    }
  }, [refetch]);

  const totalAccounts = response?.pagination.items.total || 0;

  const records: TopAccountRecord[] = useMemo(() => {
    if (!response) {
      return [];
    }

    return response.accounts.map(account => {
      return {
        account: {
          id: account.id,
          name: account.name || '',
          provider: account.provider || '',
        },
        mentions: account.mentions,
        meetings: account.meetings,
        topRisk: account.topTopics.find(tt => tt.type === 'Risks'),
        topProductFeedback: account.topTopics.find(tt => tt.type === 'Product Feedback'),
        topAdvocacy: account.topTopics.find(tt => tt.type === 'Advocacy'),
        topGrowthOpportunities: account.topTopics.find(tt => tt.type === 'Growth Opportunities'),
      };
    });
  }, [response]);

  return (
    <Card
      id="top-accounts"
      title="Top Accounts"
      subtitle="This tool lets you analyze data exclusively from your external meetings, providing deeper insights into client interactions."
    >
      <ViewAllAccountsModal
        onAccountClicked={onAccountClicked}
        open={showAllAccounts}
        onClose={() => setShowAllAccounts(false)}
        onTopicClicked={onTopicSelected}
        sortBy={sortBy}
        filters={filters}
      />
      <TopicDetailModal
        open={!!showTopics}
        onClose={() => setShowTopics(null)}
        filters={filters}
        topicId={showTopics?.topicId ?? ''}
      />
      <Flex vertical gap={'16px'}>
        <Flex gap={'16px'}>
          <Button type="primary" onClick={() => setShowAllAccounts(true)}>
            View All ({totalAccounts})
          </Button>
          <Select
            value={sortBy}
            options={[
              { label: '# of Detections', value: 'mentions' },
              { label: '# of Meetings', value: 'meetings' },
            ]}
            onChange={value => setSortBy(value)}
          />
        </Flex>
        <TopAccountsTable
          onAccountClicked={onAccountClicked}
          data={records}
          loading={isFetching}
          onTopicClicked={onTopicSelected}
        />
      </Flex>
    </Card>
  );
};

export default TopAccountsSection;
