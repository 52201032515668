import { DislikeFilled, TrophyFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import React, { ReactElement } from 'react';

const classificationToComponents: Record<string, { color: string; icon: ReactElement }> = {
  Champion: {
    color: 'green',
    icon: <TrophyFilled />,
  },
  Detractor: {
    color: 'red',
    icon: <DislikeFilled />,
  },
};

type Props = {
  classification: string;
};

const AdvocacyClassification: React.FC<Props> = ({ classification }) => {
  const classifData = classificationToComponents[classification];

  const icon = classifData?.icon;
  const color = classifData?.color;

  return (
    <Tag icon={icon} color={color}>
      {classification}
    </Tag>
  );
};

export default AdvocacyClassification;
