import React, { useState } from 'react';
import { Button, Dropdown, Flex, Space, Tooltip, Modal, Tag, Popover } from 'antd';
import { format } from 'date-fns';
import { AccordionCard } from '../../../AccordionCard';
import { colors } from '../../../../../../../../theme/colors';

import Styles from './styles';
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  MoreOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import { Magic } from '../../../../../../../shared/components/Icons/Magic';
import { Link } from 'react-router-dom';
import { InsightProductLinesContainer } from './components/InsightProductLinesContainer';
import { useEventStore } from '../../../../stores/events';
const { MeetingDate, MeetingTitle, ItemWrapper } = Styles;

const { confirm } = Modal;
type ProductLine = {
  id: string;
  name: string;
  color: string;
};

interface Props {
  id: string;
  account: {
    id: string;
    name: string;
    provider: string;
  };
  title: string;
  scheduledStartDate: Date;
  insights: {
    id: string;
    type: string;
    text: string;
    hidden: boolean;
    favorite: boolean;
    productLines: ProductLine[];
  }[];
  onRemove: (id: string) => void;
  onFavorite: (id: string) => void;
  onUnfavorite: (id: string) => void;
  onExclude: (id: string) => void;
}

export const Meeting: React.FC<Props> = ({
  id,
  title,
  scheduledStartDate,
  insights,
  onRemove,
  onFavorite,
  onUnfavorite,
  onExclude,
}) => {
  const [selectedInsightId, setSelectedInsightId] = useState<string | null>(null);
  const [insightsState, setInsights] = useState(insights);

  const handleProductLinesChange = (insightId: string, productLines: ProductLine[]) => {
    useEventStore.getState().emit('insight-product-lines-change', { insightId, productLines });
    setInsights(prevInsights =>
      prevInsights.map(insight =>
        insight.id === insightId ? { ...insight, productLines } : insight,
      ),
    );
  };

  return (
    <AccordionCard
      style={{ width: 'calc(100% - 24px)' }}
      open={true}
      header={
        <Space>
          <MeetingTitle>{title}</MeetingTitle>
          <MeetingDate>{format(scheduledStartDate, 'MMM dd @ hh aa')}</MeetingDate>
        </Space>
      }
    >
      <Flex vertical gap="15px">
        {insightsState.map(insight => (
          <Flex justify="space-between" align="start" key={insight.id}>
            <ItemWrapper>
              <div
                style={{
                  backgroundColor: colors.gray[200],
                  padding: '2px 5px',
                  borderRadius: '10px',
                  color: colors.gray[700],
                  textDecoration: 'none',
                  height: '22px',
                  width: '150px',
                  minWidth: '150px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {insight.type}
              </div>
              <div
                onClick={() => {
                  window.open(`/recap/${id}?insightId=${insight.id}`, '_blank');
                }}
                style={{ maxWidth: '75%' }}
              >
                {insight.text}
              </div>
            </ItemWrapper>
            <Flex gap="8px">
              <Flex>
                {insight.productLines.slice(0, 1).map(productLine => (
                  <Tag key={productLine.id} color={productLine.color} style={{ cursor: 'default' }}>
                    {productLine.name}
                  </Tag>
                ))}

                <Popover
                  key={insight.id}
                  content={
                    <InsightProductLinesContainer
                      productLines={insight.productLines}
                      insightId={insight.id}
                      onProductLinesChange={handleProductLinesChange}
                    />
                  }
                  title="Insight Product Lines"
                  trigger="click"
                  open={selectedInsightId === insight.id}
                  onOpenChange={open => setSelectedInsightId(open ? insight.id : null)}
                >
                  <Tooltip
                    title={insight.productLines.length > 1 ? 'View All' : 'Add Product Line'}
                  >
                    <Tag style={{ cursor: 'pointer' }}>
                      {insight.productLines.length > 1 ? <ZoomInOutlined /> : <PlusOutlined />}
                    </Tag>
                  </Tooltip>
                </Popover>
              </Flex>
              {insight.favorite && (
                <Tooltip
                  title={
                    <span>
                      This insight has been added to your{' '}
                      <Link
                        to="/libraries?tab=my-libraries"
                        style={{ color: 'white', textDecoration: 'underline' }}
                      >
                        favorites library
                      </Link>
                      .
                    </span>
                  }
                >
                  <StarFilled style={{ color: 'gold' }} />
                </Tooltip>
              )}
              <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    {
                      label: (
                        <Tooltip title="Excluding this insight will remove it from this topic, but it will still be available in its original meeting recap.">
                          <span>Exclude from Topics</span>
                        </Tooltip>
                      ),
                      icon: <Magic style={{ fill: 'orange', width: 16, marginLeft: '-3px' }} />,
                      key: 'exclude-insight',
                      onClick: () => {
                        onExclude(insight.id);
                      },
                    },
                    {
                      label: insight.favorite ? 'Remove from Favorites' : 'Favorite',
                      icon: insight.favorite ? <StarFilled /> : <StarOutlined />,
                      key: 'favorite-insight',
                      onClick: () => {
                        if (insight.favorite) {
                          onUnfavorite(insight.id);
                        } else {
                          onFavorite(insight.id);
                        }
                      },
                    },
                    {
                      label: 'Delete from Workspace',
                      icon: <DeleteOutlined />,
                      key: 'delete-insight',
                      danger: true,
                      onClick: () => {
                        confirm({
                          title: 'Are you sure you want to delete this insight?',
                          icon: <ExclamationCircleFilled />,
                          content:
                            "Deleting an insight is an irreversible action, this can't be undone",
                          okText: 'Yes, Delete',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk: () => {
                            onRemove(insight.id);
                          },
                        });
                      },
                    },
                  ],
                }}
              >
                <Button type="default" size="small" icon={<MoreOutlined />} />
              </Dropdown>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </AccordionCard>
  );
};
