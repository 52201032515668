import React from 'react';
import Styles from './style';
import { ContactCardContent } from './ContactCardContent';

const { Container } = Styles;

interface Props {
  name: string;
  percentage: number;
  meetings: number;
  attendees: number;
  email: string;
  lastMeeting: string;
  noShows: number;
  role?: string;
  selected?: boolean;
  isDecisionMaker: boolean;
  advocacyClassification: string;
  onClick: () => void;
}

export const ContactCard: React.FC<Props> = ({ onClick, ...props }) => {
  return (
    <Container onClick={onClick} selected={props.selected || false}>
      <ContactCardContent {...props} />
    </Container>
  );
};
