import React from 'react';
import { ButtonAction, IntegrationCard } from '../Components/IntegrationCard';
import { useIntegrationRequest } from '../../../../../features/integrations/hooks/useIntegrationRequest';
import { Store } from '../../../../../redux/typings/store';
import { useSelector } from 'react-redux';
import { selectCalendarIntegration } from '../../../../../redux/selectors';
import { SuccessConnectionDataLabel } from '../Components/SuccessConnectionDataLabel';
import plug from '../../../../../assets/svg/actions/plug.svg';
import unplug from '../../../../../assets/svg/actions/unplug.svg';
import { OnlyOneConnected } from '../Components/OnlyOneConnected';
import useFeatures from '../../../../../hooks/useFeatures';

const selectGoogleIntegrationId = (store: Store) =>
  store.session.user?.configuration.integration.google.id;

export const GoogleIntegrationCard: React.FC = () => {
  const { isIntegrating, requestIntegration, hasIntegration, removeIntegration, isRemoving } =
    useIntegrationRequest({ source: 'NYLAS', id: selectGoogleIntegrationId });

  const integrationData = useSelector(selectCalendarIntegration);
  const otherCalendarConnected = Boolean(
    integrationData && integrationData.id && integrationData.provider !== 'GOOGLE',
  );

  const { integrateCalendar } = useFeatures();
  const tooltip = otherCalendarConnected
    ? 'You can only have one calendar connected at a time.'
    : !integrateCalendar
    ? 'A paid account is required to connect your calendar. Contact a Workspace admin or contact us at support@update.ai.'
    : '';

  const actions: ButtonAction[] = hasIntegration
    ? [
        {
          type: 'button',
          label: 'Disconnect',
          icon: <img src={plug} style={{ opacity: `${otherCalendarConnected ? 0.5 : 1}` }} />,
          onClick: () => removeIntegration(),
          tooltip: !integrateCalendar
            ? `Calendar will be disconnected automatically since your trial expired.`
            : undefined,
          buttonProps: {
            loading: isRemoving,
            disabled: otherCalendarConnected || !integrateCalendar,
          },
        },
      ]
    : [
        {
          type: 'button',
          label: 'Connect',
          icon: <img src={unplug} style={{ opacity: `${otherCalendarConnected ? 0.5 : 1}` }} />,
          tooltip,
          onClick: () => requestIntegration({ provider: 'google' }),
          buttonProps: {
            loading: isIntegrating,
            disabled: otherCalendarConnected || !integrateCalendar,
            type: 'primary',
          },
        },
      ];

  return (
    <IntegrationCard
      title="Google"
      description="Keep up to date and synchronize your meeting calendar within UpdateAI."
      logoSrc="https://assets.update.ai/google_calendar_logo.png"
      actions={actions}
      // learnMore="https://help.update.ai/en/articles/6293196-connecting-your-google-calendar"
    >
      {hasIntegration && (
        <SuccessConnectionDataLabel label="Connected: " value={integrationData?.email || ''} />
      )}

      {otherCalendarConnected && (
        <OnlyOneConnected
          elem={<>You can only connect one calendar at a time. Disconnect one.</>}
        />
      )}
    </IntegrationCard>
  );
};
