import { AccountsContact } from '../../../../../../API/types';
import { Button, Divider, Dropdown, MenuProps } from 'antd';
import { CardContent } from '../../../../../../componentsV2/Card/styled';
import { useAccountOverview } from '../../../../../../features/account/account-overview/account-overview.hook';
import { coreService } from '../../../../../../services/core/core-service';
import { useToast } from '../../../../../../hooks/useToast';
import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { AccountIcon } from '../../../../../../features/shared/components/AccountIcon';
import { format, formatDistance } from 'date-fns';
import { MoreOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useEmail } from '../../../../../../features/email/useEmail';

import Styles from './style';
import { OptionalTooltipWrapper } from '../../../../../../componentsV2/OptionalTooltipWrapper';
import { useQuery } from '@tanstack/react-query';
const { Container, Header, Title, OneLiner, LastGeneratedLabel, Stats, StatsTitle, StatsContent } =
  Styles;

interface Props {
  stakeholder: AccountsContact | null;
  loading: boolean;
  setLoading: (id: string) => void;
}

export const ProfileDrawer: React.FC<Props> = ({ stakeholder, loading, setLoading }) => {
  const [lding, setLding] = useState(false);

  const { selectedAccount, stakeholderDataUpdated } = useAccountOverview();
  const { success } = useToast();
  const navigate = useNavigate();
  const { getEmailLink } = useEmail();

  const handleGenerate = async () => {
    if (stakeholder) {
      coreService.generateStakeholderProfile(stakeholder.id).then(() => {
        success(`Stakeholder profile is being generated`);
        setLoading(stakeholder.id);
      });
    }
  };

  useEffect(() => {
    setLding(loading);
  }, [stakeholder?.id, loading]);

  const { data } = useQuery({
    enabled: lding,
    queryKey: [`account-stakeholder-${stakeholder?.id}`],
    refetchInterval: 10000,
    queryFn: async () => {
      if (stakeholder) {
        const contactData = await coreService.getContact(stakeholder.id);
        return contactData;
      }
      return null;
    },
  });

  useEffect(() => {
    if (data) {
      setLding(data.isGeneratingProfile);
      stakeholderDataUpdated({
        advocacyClassification: data.advocacyClassification,
        decisionMakingStatus: data.decisionMakingStatus,
        email: data.email,
        id: data.id,
        isGeneratingProfile: data.isGeneratingProfile,
        lastMeetingDate: data.overview.lastMeetingDate,
        name: data.name,
        noShows: data.overview.noShows,
        profile: data.profile,
        profileLastGeneratedAt: data.profileLastGeneratedAt,
        talkRatio: data.overview.talkRatio,
        totalMeetings: data.overview.totalMeetings,
      });
    }
  }, [data]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      disabled: !!!stakeholder?.profile,
      label: <a onClick={() => navigate(`/profile/${stakeholder?.id}`)}>Go to Full Bio</a>,
    },
    {
      key: '2',
      label: <a onClick={handleGenerate}>Regenerate Bio</a>,
      disabled: loading,
    },
    {
      key: '3',
      label: (
        <a
          href={getEmailLink({
            to: [stakeholder?.email || ''],
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          Send Email
        </a>
      ),
      disabled: !!!stakeholder?.email,
    },
  ];

  if (!stakeholder) return <></>;

  return (
    <Container>
      <Header>
        <div style={{ display: 'flex', gap: '3px', flexDirection: 'column' }}>
          <Title>{capitalize(stakeholder.name || stakeholder.email)}</Title>
          <OneLiner>
            <AccountIcon provider={selectedAccount.provider} styles={{ width: '17px' }} />{' '}
            {selectedAccount.name}
          </OneLiner>
        </div>

        <OptionalTooltipWrapper
          display={loading}
          value={
            'We’re generating a stakeholder profile for this person. Please check back shortly.'
          }
        >
          <div>
            <Dropdown menu={{ items }} placement="bottomRight" disabled={loading}>
              <Button icon={<MoreOutlined />} loading={loading} />
            </Dropdown>
          </div>
        </OptionalTooltipWrapper>
      </Header>

      {!stakeholder.profile && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            gap: '5px',
            color: '#8b8e92',
          }}
        >
          {lding ? (
            <p style={{ textAlign: 'center' }}>
              We’re generating a stakeholder profile for this person. Please check back shortly.
            </p>
          ) : (
            <p>A profile has not yet been generated for this stakeholder.</p>
          )}

          <Button disabled={lding} onClick={handleGenerate}>
            Generate Bio
          </Button>
        </div>
      )}

      {stakeholder.profile && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

          <CardContent>
            <LastGeneratedLabel>
              Last generated at{' '}
              {stakeholder.profileLastGeneratedAt
                ? format(new Date(stakeholder.profileLastGeneratedAt), 'MM/dd/yyy hh:mm aa')
                : 'Not generated yet'}
            </LastGeneratedLabel>

            <Stats>
              <StatsTitle># Meetings:</StatsTitle>
              <StatsTitle># Not Attended:</StatsTitle>
              <StatsTitle>Last Meeting:</StatsTitle>

              <StatsContent>{stakeholder.totalMeetings}</StatsContent>

              <StatsContent>
                {stakeholder.noShows} (
                {Math.round(100 * (stakeholder.noShows / stakeholder.totalMeetings))}%)
              </StatsContent>

              <StatsContent>
                {formatDistance(new Date(stakeholder.lastMeetingDate), new Date())} ago
              </StatsContent>
            </Stats>
          </CardContent>

          <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

          <CardContent>
            <h1>About Stakeholder</h1>

            <h2>Role</h2>
            <p>{stakeholder.profile?.role.value || 'No data'}</p>

            <h2>Personal</h2>
            <p>{stakeholder.profile?.personal.value || 'No data'}</p>
          </CardContent>

          <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

          <CardContent>
            <h1>VIEWS</h1>

            <h2>Positive Feedback</h2>
            <p>{stakeholder.profile?.positiveFeedback?.value || 'No data'}</p>

            <h2>Negative Feedback</h2>
            <p>{stakeholder.profile?.negativeFeedback?.value || 'No data'}</p>

            <h2>Product or Service Request</h2>
            <p>{stakeholder.profile?.requests?.value || 'No data'}</p>
          </CardContent>
        </div>
      )}
    </Container>
  );
};
