import { PropsWithChildren, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { coreAPI } from '../../../API/core';
import { FetchUpcomingEventsArgs } from '../../../API/types';
import { createUpcomingMeetingsStore, UPCOMING_MEETINGS_PAGE_KEY } from './upcoming-meetings.store';
import { upcomingMeetingsContext } from './upcoming-meetings.context';

export const UpcomingMeetingsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const store = useRef(createUpcomingMeetingsStore()).current;
  const {
    searchTerm,
    page,
    meetingType,
    updateAccounts,
    fetchId,
    pageSize,
    mType,
    canLoadData,
    fetchStatusChanged,
    eventsFetched,
    paginationMetadataSet,
    updateFiltersState,
  } = store();

  useEffect(() => {
    const filters = JSON.parse(localStorage.getItem('pageFilters') || '{}');
    const pageFilters = filters[UPCOMING_MEETINGS_PAGE_KEY] || {};
    updateFiltersState({
      searchTerm: pageFilters.searchTerm || '',
      meetingType: pageFilters.meetingType || 'all',
      mType: pageFilters.mType || null,
      updateAccounts: pageFilters.updateAccounts || [],
      canLoadData: true,
    });
  }, []);

  const { data, isFetching } = useQuery({
    queryKey: [searchTerm, meetingType, updateAccounts, page, fetchId, mType],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    networkMode: 'always',
    enabled: canLoadData,
    queryFn: () => {
      const searchArgs: FetchUpcomingEventsArgs = {
        page,
        limit: pageSize,
      };

      if (searchTerm) {
        searchArgs['search'] = searchTerm;
      }

      if (meetingType !== 'all') {
        searchArgs['participantsType'] = meetingType;
      }

      if (updateAccounts.length) {
        searchArgs['accounts'] = updateAccounts;
      }

      if (mType) {
        searchArgs['meetingType'] = mType;
      }

      const api = new coreAPI();
      return api.fetchUpcomingEvents(searchArgs);
    },
  });

  useEffect(() => {
    fetchStatusChanged(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      eventsFetched(data.items);
      paginationMetadataSet(data.metadata);
    }
  }, [data]);

  return (
    <upcomingMeetingsContext.Provider value={{ store }}>
      {children}
    </upcomingMeetingsContext.Provider>
  );
};
