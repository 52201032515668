import { useState } from 'react';
import { UpcomingEvent } from '../../../../../../../../API/types';
import { MeetingTypeSelector } from '../../../../../../../../features/home/tabs/UpcomingsTab/components/MeetingTypeSelector';
import { PruposeStatementModal } from '../../../../../../../../features/meeting-prep/components/PurposeStatementModal';
import { useUpcomingMeetings } from '../../../../../../../../features/meetings/upcoming-meetings/upcoming-meetings.hook';
import { Button } from 'antd';
import { format } from 'date-fns';
import { RecordMeetingToggle } from '../../../../../../../../componentsV2/RecordMeetingToggle';

import Styles from './style';
const { Container, Actions, Header, Title, DateLabel } = Styles;

interface Props {
  event: UpcomingEvent;
}

export const UpcomingCard: React.FC<Props> = ({ event }) => {
  const { setPurposeStatement } = useUpcomingMeetings();
  const [openPurposeMeeting, setOpenPurposeMeeting] = useState<{
    id: string;
    title: string;
    scheduledStartDate: Date;
    purposeStatement: string;
  } | null>(null);

  return (
    <Container key={event.id + '-upcoming-event'}>
      <Header>
        <Title>{event.title}</Title>
        <DateLabel>
          {format(new Date(event.scheduledStartDate), 'eeee, MMMM dd, yyyy ')}
          {format(new Date(event.scheduledStartDate), 'HH:mm aaa').toUpperCase()}
          {' - '}
          {format(new Date(event.scheduledEndDate), 'HH:mm aaa').toUpperCase()}
        </DateLabel>
      </Header>

      <Actions>
        <RecordMeetingToggle
          id={event.id}
          checked={event.recording?.value || false}
          disabled={!event.canToggleCreateRecording}
          title={event.recording?.reason || ''}
        />
        <Button
          size="small"
          onClick={() => {
            setOpenPurposeMeeting({
              id: event.id,
              title: event.title,
              purposeStatement: event.purposeStatement || '',
              scheduledStartDate: new Date(event.scheduledStartDate),
            });
          }}
        >
          Add Purpose Statement
        </Button>
        <MeetingTypeSelector eventId={event.id} meetingType={event.type} size="small" />
      </Actions>

      <PruposeStatementModal
        open={!!openPurposeMeeting}
        event={openPurposeMeeting}
        onClose={() => setOpenPurposeMeeting(null)}
        onPurposeChanged={setPurposeStatement}
      />
    </Container>
  );
};
