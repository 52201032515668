import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { NoMeetingMessage } from './components/NoMeetingMessage';
import { DateLabel } from '../components/DateLabel';
import { isSameDay, startOfYesterday } from 'date-fns';
import { MeetingCard } from '../components/MeetingCard';
import { useUpcomingMeetings } from '../../../meetings/upcoming-meetings/upcoming-meetings.hook';
import { RecordMeetingToggle } from '../../../../componentsV2/RecordMeetingToggle';
import { PruposeStatementModal } from '../../../meeting-prep/components/PurposeStatementModal';
import { AccountModal } from '../../../meetings/account-selection/components/AccountModal';
import { JoinMeetingAction } from './components/JoinMeetingAction';
import { DropdownVisibility } from '../../../../componentsV2/DropdownVisibility';
import { MeetingTypeSelector } from './components/MeetingTypeSelector';
import UpcomingMeetingsFilterBar from '../../components/UpcomingMeetingsFilterBar';
import InfiniteScroll from 'react-infinite-scroll-component';

import Styles from './styles';
const { UpcomingList, CenteredContent } = Styles;

export const UpcomingsTab: React.FC = () => {
  const {
    isFetching,
    events,
    updateAccounts,
    meetingType,
    searchTerm,
    mType,
    hasNextPage,
    canLoadData,
    keepFetching,
    setPurposeStatement,
    updateMeetingAccount,
    updateAccountsFiltered,
  } = useUpcomingMeetings();
  const hasNoFiltersApplied =
    updateAccounts.length === 0 && meetingType === 'all' && searchTerm === '' && !mType;

  const [height, setHeight] = useState(800);
  const [openPurposeModal, setOpenPurposeModal] = useState('');
  const [openPurposeMeeting, setOpenPurposeMeeting] = useState<{
    id: string;
    title: string;
    scheduledStartDate: Date;
    purposeStatement: string;
  } | null>(null);

  // Not using setCurrentDate to avoid unnecesary re-renders. It's only to calculate dates.
  let [currentDate] = useState(startOfYesterday());

  useEffect(() => {
    setHeight(window.outerHeight - 210);
  }, [window.screen.height, window.screen.width]);

  useEffect(() => {
    if (openPurposeModal !== '') {
      const event = events.find(event => event.id === openPurposeModal);

      if (event)
        setOpenPurposeMeeting({
          id: event.id,
          title: event.title,
          scheduledStartDate: new Date(event.scheduledStartDate),
          purposeStatement: event.purposeStatement || '',
        });
    } else {
      setOpenPurposeMeeting(null);
    }
  }, [openPurposeModal]);

  return (
    <UpcomingList id="timeframelist">
      {canLoadData && <UpcomingMeetingsFilterBar />}

      {isFetching && events.length === 0 ? (
        <CenteredContent>
          <Spin />
        </CenteredContent>
      ) : (
        <InfiniteScroll
          dataLength={events.length}
          next={() => keepFetching()}
          height={height}
          hasMore={hasNextPage}
          loader={
            <div
              style={{ display: 'flex', width: '100%', justifyContent: 'center', height: '40px' }}
            >
              <Spin />
            </div>
          }
          endMessage={
            events.length === 0 ? (
              <CenteredContent>
                {hasNoFiltersApplied ? (
                  <NoMeetingMessage />
                ) : (
                  <p> No matches for current filter settings</p>
                )}
              </CenteredContent>
            ) : (
              <></>
            )
          }
          scrollThreshold={0.6}
        >
          {events.map(event => {
            const comps = [];
            const date = event.scheduledStartDate;
            if (!isSameDay(new Date(date), currentDate)) {
              currentDate = new Date(date);
              comps.push(<DateLabel date={date} key={'date-label' + currentDate.toString()} />);
            }

            comps.push(
              <MeetingCard
                event={event}
                secondaryActions={[
                  event.visibility ? (
                    <>
                      <DropdownVisibility
                        hasPermissions={event.permissions.canManageWorkspaceVisibility}
                        visibility={event.visibility}
                        id={event.id}
                      />
                      <MeetingTypeSelector
                        meetingType={event.type}
                        eventId={event.id}
                        hasPermissions={event.permissions.canChangeMeetingTypes}
                      />
                      <Button
                        size="small"
                        onClick={() => setOpenPurposeModal(event.id)}
                        disabled={!event.permissions.canManagePurposeStatement}
                      >
                        Add Purpose Statement
                      </Button>
                    </>
                  ) : (
                    <></>
                  ),
                ]}
                joinMeetingAction={<JoinMeetingAction url={event.conferencingUrl} />}
                tertiaryActions={[
                  <RecordMeetingToggle
                    id={event.id}
                    checked={event.recording?.value || false}
                    // disabled={!event.canToggleCreateRecording}
                    title={event.recording?.reason || ''}
                  />,
                ]}
                onAccountRemoved={id => updateMeetingAccount(id, null)}
                onAccountClicked={updateAccountsFiltered}
              />,
            );
            return <>{comps}</>;
          })}
        </InfiniteScroll>
      )}
      <PruposeStatementModal
        open={!!openPurposeMeeting}
        event={openPurposeMeeting}
        onClose={() => setOpenPurposeModal('')}
        onPurposeChanged={setPurposeStatement}
      />
      <AccountModal onAccountChanged={updateMeetingAccount} />
    </UpcomingList>
  );
};
