import { FetchRecapResponse } from '../../../services/types';
import {
  Category,
  Cropping,
  Insight,
  Playback,
  RecapAccount,
  RecapSpeakerAnalytics,
  Utterance,
  UtteranceSegment,
} from './types';

type Point = {
  position: number;
  chunkIds: string[];
  itemIds: string[];
};

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type MessageWithAllChunks = ArrayElement<FetchRecapResponse['messages']> & {
  chunks: { from: number; to: number; id: string; itemId: string }[];
};

const isChunkBetweenInterval = (position: number, chunk: { from: number; to: number }) => {
  return position >= chunk.from && position <= chunk.to;
};

export const removeBullet = (text: string) => {
  if (!text) {
    return text;
  }

  return text.replace(/•\s/g, '');
};

const createSegments = (msg: MessageWithAllChunks): UtteranceSegment[] => {
  const positionPoints: Point[] = [];
  const points = new Set<number>();

  /**
   * For each chunk, we add a point in the rect.
   */
  msg.chunks.forEach(ch => {
    points.add(ch.from);
    points.add(ch.to);
  });

  const pointsArray = Array.from(points.values());

  /**
   * For each point, we check which chunks are involved in it.
   * We store their ids and itemIds.
   */
  pointsArray.forEach(p => {
    const matchingChunks = msg.chunks.filter(chunk => isChunkBetweenInterval(p, chunk));
    positionPoints.push({
      position: p,
      chunkIds: matchingChunks.map(mc => mc.id),
      itemIds: matchingChunks.map(mc => mc.itemId),
    });
  });

  const sortedPoints = positionPoints.sort((a, b) => a.position - b.position);

  const generatedChunks: any[] = [];

  for (let i = 0; i < sortedPoints.length - 1; i++) {
    const curPoint = sortedPoints[i];
    const nextPoint = sortedPoints[i + 1];
    /**
     * If the current point and nextpoint share Ids, then those ids
     * are involved in that segment.
     */
    const matchingChunkIds = curPoint.chunkIds.filter(cc =>
      Boolean(nextPoint.chunkIds.find(p => p === cc)),
    );
    const matchingItemIds = curPoint.itemIds.filter(cc =>
      Boolean(nextPoint.itemIds.find(p => p === cc)),
    );

    generatedChunks.push({
      from: curPoint.position,
      to: nextPoint.position,
      itemIds: matchingItemIds,
      chunkIds: matchingChunkIds,
    });
  }

  return generatedChunks;
};

export const parseSpeakerAnalytics = (
  fetchRecapResponse: FetchRecapResponse,
): RecapSpeakerAnalytics => {
  const speakerAnalytics: RecapSpeakerAnalytics =
    fetchRecapResponse.speakerAnalytics &&
      Array.isArray(fetchRecapResponse.speakerAnalytics.speakers)
      ? fetchRecapResponse.speakerAnalytics
      : { speakers: [] };
  speakerAnalytics.speakers = speakerAnalytics.speakers.sort((a, b) => {
    return b.talkRatio - a.talkRatio;
  });
  return speakerAnalytics;
};

export const parseInsights = (fetchRecapResponse: FetchRecapResponse): Insight[] => {
  const insights: Insight[] = [];
  fetchRecapResponse.actionItems.forEach(insight => {
    insights.push({
      id: insight.id,
      text: removeBullet(insight.text),
      category: 'Action Items',
      hidden: insight.hidden,
      excludedFromInsightsEngine: insight.excludedFromInsights,
      chunks: insight.chunks,
      favourite: Boolean(insight.favorite),
    });
  });

  fetchRecapResponse.summaryItems.forEach(insight => {
    insights.push({
      id: insight.id,
      text: removeBullet(insight.text),
      category: 'TL;DR',
      hidden: insight.hidden,
      excludedFromInsightsEngine: insight.excludedFromInsights,
      chunks: insight.chunks,
      favourite: Boolean(insight.favorite),
    });
  });
  for (const category in fetchRecapResponse.categories) {
    fetchRecapResponse.categories[category].items.forEach(insight => {
      insights.push({
        id: insight.id,
        text: removeBullet(insight.text),
        category,
        hidden: insight.hidden,
        excludedFromInsightsEngine: insight.excludedFromInsights,
        chunks: insight.chunks,
        favourite: Boolean(insight.favorite),
      });
    });
  }
  return insights;
};

export const createUtterances = (fetchRecapResponse: FetchRecapResponse): Utterance[] => {
  const mapped = new Map<string, MessageWithAllChunks>();

  /**
   * We add the message to the map.
   * The first chunk is the whole text.
   */
  fetchRecapResponse.messages.forEach(message => {
    mapped.set(message.id, {
      ...message,
      chunks: [
        {
          from: 0,
          to: message.content.length,
          id: message.id,
          itemId: message.id,
        },
      ],
    });
  });

  fetchRecapResponse.actionItems.forEach(actionItem => {
    actionItem.chunks.forEach(chunk => {
      const message = mapped.get(chunk.messageId)!;
      if (message) {
        message.chunks.push({
          from: chunk.from,
          to: chunk.to,
          id: chunk.id,
          itemId: actionItem.id,
        });
        mapped.set(chunk.messageId, message);
      }
    });
  });

  fetchRecapResponse.summaryItems.forEach(actionItem => {
    actionItem.chunks.forEach(chunk => {
      const message = mapped.get(chunk.messageId)!;
      if (message) {
        message.chunks.push({
          from: chunk.from,
          to: chunk.to,
          id: chunk.id,
          itemId: actionItem.id,
        });
        mapped.set(chunk.messageId, message);
      }
    });
  });

  fetchRecapResponse.keyMoments.forEach(actionItem => {
    actionItem.chunks.forEach(chunk => {
      const message = mapped.get(chunk.messageId)!;
      if (message) {
        message.chunks.push({
          from: chunk.from,
          to: chunk.to,
          id: chunk.id,
          itemId: actionItem.id,
        });
        mapped.set(chunk.messageId, message);
      }
    });
  });

  const messagesMapped = Array.from(mapped.values());
  return messagesMapped.map(msg => {
    const segments = createSegments(msg);
    return {
      id: msg.id,
      speaker: msg.speaker,
      startTime: msg.startedTime,
      endTime: msg.endTime,
      duration: msg.duration,
      content: msg.content,
      segments,
    };
  });
};

export const parseAccount = (fetchRecapResponse: FetchRecapResponse): RecapAccount => {
  if (fetchRecapResponse.account && fetchRecapResponse.account.id !== 'unknown') {
    return {
      id: fetchRecapResponse.account.id,
      name: fetchRecapResponse.account.name,
      provider: fetchRecapResponse.account.provider,
    };
  }

  return null;
};

export const parsePlayback = (fetchRecapResponse: FetchRecapResponse): Playback | null => {
  if (fetchRecapResponse.externalResource) {
    return {
      type: fetchRecapResponse.externalResource.type,
      url: fetchRecapResponse.externalResource.url,
    };
  }

  return null;
};

const sortOrder = [
  'TL;DR',
  'Action Items',
  'Risks',
  'Growth Opportunities',
  'Product Feedback',
  'Key Questions',
  'Key Decisions',
  'Advocacy',
  'Important Numbers',
];

export const parseCategories = (
  fetchRecapResponse: FetchRecapResponse,
): { [k: string]: Category } => {
  const categories: { [k: string]: Category } = {};
  const sortedCategories: { [k: string]: Category } = {};

  categories['Action Items'] = { count: fetchRecapResponse.actionItemsCount };
  categories['TL;DR'] = { count: fetchRecapResponse.summaryItemsCount };

  for (const category in fetchRecapResponse.categories) {
    categories[category] = {
      count: fetchRecapResponse.categories[category].count,
    };
  }

  const sortedKeys = Object.keys(categories).sort(
    (catOne, catTwo) =>
      sortOrder.findIndex(val => val === catOne) - sortOrder.findIndex(val => val === catTwo),
  );

  for (const catName of sortedKeys) {
    sortedCategories[catName] = categories[catName];
  }

  return sortedCategories;
};

export const parseCropping = (fetchRecapResponse: FetchRecapResponse): Cropping => {
  if (fetchRecapResponse.externalResource) {
    return {
      start: fetchRecapResponse.externalResource.cropStart,
      end: fetchRecapResponse.externalResource.cropEnd,
    };
  }

  return {
    start: 0,
    end: 0,
  };
};

export const dateOrNull = (value: string | null): Date | null => {
  if (value !== null) {
    return new Date(value);
  }

  return null;
};
