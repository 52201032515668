import { coreService } from '../../services/core/core-service';
import { GetOrganizationInfoResult } from '../../services/types';
import * as organizationDetails from '../../stores/organization-details';
import * as organizationInvites from '../../stores/organization-invites';
import * as organizationMembers from '../../stores/organization-members';
import { extractError } from '../../utils/api';
import { MeetingsVisibility, Member } from './types';

const mapFetchResultToMembers = (result: GetOrganizationInfoResult): Member[] => {
  return result.members.map(member => {
    return {
      isSingleSubscribed: member.isSingleSubscribed,
      meetingsVisibility: {
        internalMeetingsVisibility: member.user.shareEventSettings.shareInternalMeetings,
        externalMeetingsVisibility: member.user.shareEventSettings.shareExternalMeetings,
      },
      meetingVisibilityLocked: member.meetingVisibilityLocked,
      permissions: member.permissions,
      role: member.role,
      type: member.type,
      user: {
        email: member.user.email,
        id: member.user.id,
        firstName: member.user.firstName,
        lastName: member.user.lastName,
        jobTitle: member.jobTitle,
      },
    };
  });
};

export const fetch = async () => {
  const state = organizationDetails.organizationDetailsStore.getState();
  if (state.isFetching) {
    return;
  }

  organizationDetails.fetchRequested();

  try {
    const organizationInfo = await coreService.fetchOrganization();
    const { id, name, allowJoinByDomain } = organizationInfo;

    organizationDetails.fetchSucceeded({
      id,
      name,
      allowJoinByDomain,
      globalMeetingsVisibility: organizationInfo.meetingsVisibility,
      manageMeetingsVisibilityGlobally: organizationInfo.manageMeetingsVisibilityGlobally,
      botTextAnnouncement: organizationInfo.botTextAnnouncement,
      botVoiceAnnouncement: organizationInfo.botVoiceAnnouncement,
      permissions: organizationInfo.permissions,
      doNotRecordList: organizationInfo.doNotRecordList,
      crmData: organizationInfo.crmData,
      slack: organizationInfo.slack,
    });
    organizationInvites.invitationsFetched(organizationInfo.invitations);
    organizationMembers.membersFetched(mapFetchResultToMembers(organizationInfo));
  } catch (error) {
    const errorMessage = extractError(error);
    organizationDetails.fetchFailed(errorMessage);
  }
};

export const fetchMembers = async () => {
  const state = organizationDetails.organizationDetailsStore.getState();
  if (state.isFetching) {
    return;
  }

  try {
    const organizationInfo = await coreService.fetchOrganization();
    organizationMembers.membersFetched(mapFetchResultToMembers(organizationInfo));
  } catch (error) {
    // const errorMessage = extractError(error);
  }
};

export const changeName = async (name: string) => {
  const previousState = organizationDetails.organizationDetailsStore.getState();
  const { name: previousName, id } = previousState;
  try {
    organizationDetails.nameChanged(name);
    await coreService.updateOrganizationDetails({ id, name });
  } catch (error) {
    organizationDetails.nameChanged(previousName);
    throw error;
  }
};

export const updateCrmSettings = async (args: { pushToCrmWithPrimaryProfile: boolean }) => {
  const { pushToCrmWithPrimaryProfile } = args;

  const previousState = organizationDetails.organizationDetailsStore.getState();
  const { crmData } = previousState;
  try {
    organizationDetails.crmDataChanged({ ...crmData, pushToCrmWithPrimaryProfile });
    await coreService.updateOrganizationCRMSettings(previousState.id, {
      pushToCrmWithPrimaryProfile,
    });
  } catch (error) {
    organizationDetails.crmDataChanged({ ...crmData });
    throw error;
  }
};

export const disconnectPrimaryProfile = async () => {
  const previousState = organizationDetails.organizationDetailsStore.getState();
  const { crmData } = previousState;
  try {
    await coreService.disconnectPrimaryProfile(previousState.id);
    organizationDetails.crmDataChanged({
      ...crmData,
      primaryProfile: null,
      pushToCrmWithPrimaryProfile: false,
    });
  } catch (error) {
    organizationDetails.crmDataChanged({ ...crmData });
    throw error;
  }
};

export const toggleAllowJoinByDomain = async (value: boolean) => {
  const previousState = organizationDetails.organizationDetailsStore.getState();
  const { allowJoinByDomain } = previousState;
  try {
    organizationDetails.allowJoinByDomainToggled(value);
    await coreService.toggleAllowJoinByDomain(previousState.id, value);
  } catch (error) {
    organizationDetails.allowJoinByDomainToggled(allowJoinByDomain);
    throw error;
  }
};

export const inviteUsers = async (users: { email: string; type: string; role: string }[]) => {
  const invitations = await coreService.inviteUsers(users);
  invitations.forEach(invitation => organizationInvites.emailInvited(invitation));
};

export const revokeInvitation = async (invitationId: string) => {
  await coreService.cancelInvitation(invitationId);
  organizationInvites.invitationRevoked(invitationId);
};

export const resendInvitation = async (invitationId: string) => {
  await coreService.resendInvitation(invitationId);
};

export const removeMember = async (userId: string) => {
  await coreService.removeMember(userId);
  organizationMembers.memberRemoved(userId);
};

export const changeMemberRole = async (userId: string, role: string) => {
  await coreService.changeMemberRole(userId, role);
  organizationMembers.memberRoleChanged(userId, role);
};

export const changeMemberType = async (userId: string, type: string) => {
  await coreService.changeMemberType(userId, type);
  organizationMembers.memberTypeChanged(userId, type);
};

export const disableManageMeetingsVisibilityGlobally = async () => {
  await coreService.disableGlobalMeetingVisibility();
  organizationDetails.manageGlobalMeetingsVisibilityDisabled();
};

export const enableManageMeetingsVisibilityGlobally = async () => {
  await coreService.enableGlobalMeetingVisibility();
  organizationDetails.manageGlobalMeetingsVisibilityEnabled();
};

export const changeGlobalMeetingsVisibility = async (args: MeetingsVisibility) => {
  await coreService.modifyGlobalMeetingVisibility(args);
  organizationDetails.globalMeetingsVisibilityModified(args);
};

export const changeBotAnnouncement = async (args: { text?: boolean; voice?: boolean }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updatedValues: any = {};

  if (args.text !== undefined) {
    updatedValues.botTextAnnouncement = args.text;
  }

  if (args.voice !== undefined) {
    updatedValues.botVoiceAnnouncement = args.voice;
  }

  organizationDetails.changeBotAnnouncement(updatedValues);
  await coreService.changeBotAnnouncement(args);
};
