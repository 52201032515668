import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Calendar } from './Steps/Calendar';
import { Conferencing } from './Steps/Conferencing/Conferencing';
import { Validation } from './Steps/Validation/Validation';
import { useNavigate } from 'react-router-dom';
import { Organization } from './Steps/Organization/Organization';
import { selectOnboardingStatus, selectUser } from '../../redux/selectors';
import { OnboardingStatus } from '../../types/commonTypes';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { RecordingSettings } from './Steps/RecordingSettings';
import { useOnboardingAnalytics } from './useOnboardingAnalytics';
import { ChromeExperience } from './Steps/ChromeExperience';

export const Onboarding: React.FC = () => {
  const onboardingStatus = useSelector(selectOnboardingStatus);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { knowYourselfView } = useOnboardingAnalytics();

  useLocationChange();

  useEffect(() => {
    if (onboardingStatus === OnboardingStatus.Completed) {
      if (user?.demoEventId) {
        navigate('/recap/demo');
      } else {
        navigate('/');
      }
    }
  }, [onboardingStatus]);

  switch (onboardingStatus) {
    case OnboardingStatus.Validation:
      knowYourselfView.viewed();
      return <Validation />;

    case OnboardingStatus.SelectOrganization:
      return <Organization />;

    case OnboardingStatus.CalendarSync:
      return <Calendar />;

    case OnboardingStatus.ConferencingSync: {
      return <Conferencing />;
    }

    case OnboardingStatus.RecordingSettings: {
      return <RecordingSettings />;
    }

    case OnboardingStatus.ChromeExperience: {
      return <ChromeExperience />;
    }

    default:
      return <></>;
  }
};
