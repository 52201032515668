import { useContext, useEffect } from 'react';
import { pastMeetingsContext } from './past-meetings.context';

export const usePastMeetings = () => {
  const { store } = useContext(pastMeetingsContext);
  const {
    updateAccounts,
    isFetching,
    contacts,
    events,
    dateRange,
    dateRangeType,
    searchTerm,
    showMineOnly,
    showReadyOnly,
    showOnlyUnmappedAccounts,
    meetingType,
    pageSize,
    page,
    mType,
    paginationMetadata,
    canLoadData,
    updateMType,
    resetFilters,
    updateAccountsFiltered,
    contactsFiltered,
    dateRangeChanged,
    searchTermChanged,
    showMineOnlyChanged,
    showReadyOnlyChanged,
    showOnlyUnmappedAccountsChanged,
    pageSizeChanged,
    pageChanged,
    meetingTypeChanged,
    keepFetching,
    refetch,
    deleteEvent,
    updateMeetingAccount,
    onInsightChanged,
    reset,
  } = store();

  return {
    isFetching,
    updateAccounts,
    contacts,
    events,
    pageSize,
    page,
    dateRange,
    dateRangeType,
    searchTerm,
    showMineOnly,
    showReadyOnly,
    showOnlyUnmappedAccounts,
    paginationMetadata,
    meetingType,
    mType,
    hasNextPage: paginationMetadata.page < paginationMetadata.totalPages,
    canLoadData,
    updateMType,
    changeShowMineOnly: showMineOnlyChanged,
    updateAccountsFiltered,
    filterContacts: contactsFiltered,
    changeDateRange: dateRangeChanged,
    changeSearchTerm: searchTermChanged,
    changeShowReadyOnly: showReadyOnlyChanged,
    changeShowOnlyUnmappedAccounts: showOnlyUnmappedAccountsChanged,
    changePageSize: pageSizeChanged,
    changePage: pageChanged,
    resetFilters,
    meetingTypeChanged,
    keepFetching,
    refetch,
    reset,
    deleteEvent,
    updateMeetingAccount,
    onInsightChanged,
  };
};
