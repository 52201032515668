import React, { useState } from 'react';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { SummarizeButton } from './components/SummarizeButton';
import { Search } from '@updateai/ui/dist/components/Search';
import { coreAPI } from '../../../../../API/core';
import { PastEvent } from '../../../../../API/types';
import { UpcomingCard } from './components/Meetings/UpcomingCard';
import { PastCard } from './components/Meetings/PastCard';
import { Spin, Tooltip } from 'antd';
import { useQuery } from '@tanstack/react-query';
import Styles from './style';
import { QuestionCircleOutlined } from '@ant-design/icons';
const { Container, OneLiner, Title, Meetings, Message } = Styles;

export const UpcomingAndPastMeetings: React.FC<{ onSummary: () => void }> = ({ onSummary }) => {
  const { selectedAccount } = useAccountOverview();

  const [meetingsSelected, setMeetingsSelected] = useState<any[]>([]);
  const [filteredPastMeetings, setFilteredPastMeetings] = useState<PastEvent[] | null>(null);

  const handleSelectedChange = (selected: boolean, eventId: string) => {
    let selections = [...meetingsSelected];
    if (selected) {
      selections.push(eventId);
    } else {
      selections = selections.filter(sel => sel !== eventId);
    }
    setMeetingsSelected(selections);
  };

  const { data: upcomingMeetings, isFetching: loadingUpcoming } = useQuery({
    queryKey: ['upcoming-meetings', selectedAccount.id],
    queryFn: async () => {
      if (!selectedAccount.mappingId) return [];
      const api = new coreAPI();
      const res = await api.fetchUpcomingEvents({
        accounts: [
          {
            id: selectedAccount.mappingId,
            provider: selectedAccount.provider,
            name: selectedAccount.name,
          },
        ],
        limit: 2,
        page: 1,
      });
      return res.items;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const { data: pastMeetings, isFetching: loadingPast } = useQuery({
    queryKey: ['past-meetings', selectedAccount.id],
    queryFn: async () => {
      if (!selectedAccount.mappingId) return [];
      const api = new coreAPI();
      const res = await api.fetchPastEvents({
        accountIds: selectedAccount.mappingId,
        limit: 1000,
        page: 1,
        readyOnly: 'true',
        hostOnly: 'false',
      });
      return res.items;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return (
    <Container>
      <Title>Next 2 meetings</Title>
      <Meetings>
        <Message>
          {upcomingMeetings &&
            upcomingMeetings.length === 0 &&
            !loadingUpcoming &&
            'No upcoming meetings'}
        </Message>

        {loadingUpcoming && <Spin size="small" style={{ width: '100%' }} />}
        {(upcomingMeetings || []).map(event => (
          <UpcomingCard event={event} />
        ))}
      </Meetings>

      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Title style={{ marginTop: '30px', marginRight: '10px' }}>Past meetings</Title>
        <Tooltip title="Only past meetings in the 'Ready' state that you are authorized to access will be displayed.">
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
      <OneLiner>
        <Search
          placeholder="Search Meeting Title"
          onChange={s => {
            if (s.target.value === '') {
              setFilteredPastMeetings(null);
            } else {
              setFilteredPastMeetings(
                (pastMeetings || []).filter(p =>
                  p.title.toUpperCase().includes(s.target.value.toUpperCase()),
                ),
              );
            }
          }}
          onSearch={s => s}
        />

        <SummarizeButton
          id={selectedAccount.id}
          meetings={meetingsSelected}
          summarizeDisabled={meetingsSelected.length === 0}
          onSuccess={() => {
            setMeetingsSelected([]);
            onSummary();
          }}
        />
      </OneLiner>

      <Message>
        {pastMeetings && pastMeetings.length === 0 && !loadingPast && 'No past meetings'}
      </Message>

      <Meetings>
        {loadingPast && <Spin size="small" style={{ width: '100%' }} />}
        {(filteredPastMeetings || pastMeetings || []).map(event => (
          <PastCard
            event={event}
            onSelctedChange={(e, i) => handleSelectedChange(e, i)}
            selected={meetingsSelected.includes(event.id)}
            participantsType={event.participantsType}
          />
        ))}
      </Meetings>
    </Container>
  );
};
