import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, InputNumber, InputNumberProps, Slider } from 'antd';
import { useMemo, useState } from 'react';
import Styles from './styles';
import Label from '../../../../componentsV2/Label';

const { CustomInput, OptionsContainer, Contents } = Styles;

interface Props {
  min: number;
  max: number;
  label: string;
  value?: string[];
  onSelectionChanged: (min: number, max: number) => void;
  style?: React.CSSProperties;
}

export const CurrencyFilter: React.FC<Props> = ({
  label,
  style = {},
  onSelectionChanged,
  min,
  max,
}) => {
  const formatter = new Intl.NumberFormat('en-US', { currency: 'USD' });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [range, setRange] = useState<[number, number]>([min, max]);

  const formatValue = (value: number) => formatter.format(value);
  const parseValue = (value?: string) => (value ? parseFloat(value.replace(/[$,]/g, '')) : 0);

  const handleChange = (index: number, newValue: number | null) => {
    if (newValue !== null) {
      setRange(prev => {
        const updatedRange = [...prev] as [number, number];
        updatedRange[index] = newValue;
        return updatedRange;
      });
    }
  };

  const inputPlaceholder = useMemo(() => {
    return range[0] === min && range[1] === max
      ? label
      : `$${formatValue(range[0])} - $${formatValue(range[1])}`;
  }, [label, range, min, max]);

  const handleReset = () => {
    setRange([min, max]);
    onSelectionChanged(min, max);
  };

  const handleApply = () => {
    onSelectionChanged(range[0], range[1]);
    setDropdownOpen(false);
  };

  return (
    <Dropdown
      dropdownRender={() => (
        <Contents>
          <OptionsContainer>
            <div>{label}</div>

            <Flex gap="16px" justify="space-between" align="center" style={{ marginTop: '8px' }}>
              {['Min', 'Max'].map((label, index) => (
                <div key={label} style={{ width: '100%' }}>
                  <Label size="xs">{label}</Label>
                  <InputNumber
                    style={{ width: '100%' }}
                    min={index === 0 ? min : range[0] + 1}
                    max={index === 0 ? range[1] - 1 : max}
                    value={range[index]}
                    onChange={value => handleChange(index, value as number)}
                    prefix="$"
                    step={10}
                    formatter={value => formatValue(value as number)}
                    parser={value => parseValue(value)}
                  />
                </div>
              ))}
            </Flex>

            <Slider
              range={{ draggableTrack: true }}
              step={1000}
              min={min}
              max={max}
              tooltip={{ formatter: value => `$${formatValue(value!)}` }}
              style={{ marginTop: '16px' }}
              onChange={(value: number[]) => setRange(value as [number, number])}
              value={range}
            />

            <Flex justify="end" gap="8px" style={{ marginTop: '8px' }}>
              <Button type="default" onClick={handleReset}>
                Reset
              </Button>
              <Button type="primary" onClick={handleApply}>
                Apply
              </Button>
            </Flex>
          </OptionsContainer>
        </Contents>
      )}
      trigger={['click']}
      onOpenChange={setDropdownOpen}
      open={dropdownOpen}
    >
      <CustomInput
        type="text"
        readOnly
        className={inputPlaceholder !== label ? 'has-value' : ''}
        placeholder={inputPlaceholder}
        style={style}
        suffix={
          <DownOutlined
            style={{
              transform: dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
              width: '12px',
              height: '12px',
              color: 'rgba(0, 0, 0, 0.25)',
            }}
          />
        }
      />
    </Dropdown>
  );
};
