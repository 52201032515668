import { Button, Spin } from 'antd';
import { useOrganizationDetails } from '../../../../../features/organizations/hooks/useOrganizationDetails';
import ToggleCard from '../ToggleCard';
import { StyledInput, BotNamingContainer, BotNameMessage } from './style';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUserSettings } from '../../../../../features/user/hooks/useUserSettings';
import { useToast } from '../../../../../hooks/useToast';
import { useAnalytics } from '../../../../../features/analytics/hooks/useAnalytics';

export const BotNaming: React.FC = () => {
  const org = useOrganizationDetails();
  const userSettings = useUserSettings();
  const { settings: analytics } = useAnalytics();
  const { success, error } = useToast();

  const botName = useMemo(
    () => userSettings.settings.botName || '',
    [userSettings.settings.botName],
  );

  const [newBotName, setNewBotName] = useState(botName);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!org.isFetching && !userSettings.isFetching) {
      setNewBotName(botName);
    }
  }, [org.isFetching, userSettings.isFetching, botName]);

  const formHasNotChanged = newBotName.trim() === botName;

  const handleSave = useCallback(async () => {
    try {
      setIsSaving(true);
      analytics.botRenamed();
      await userSettings.updateSettings({ botName: newBotName });
      success('Bot name updated successfully.');
    } catch (err) {
      console.error('Failed to update bot name:', err);
      error('There was an error when update bot name.');
    } finally {
      setIsSaving(false);
    }
  }, [newBotName]);

  return (
    <ToggleCard
      title="Name your bot"
      description="Chose a personal or company display name when your bot enters the meeting."
      hideToggle
    >
      {!org.isFetching ? (
        <>
          <BotNamingContainer>
            <StyledInput
              label=""
              inputValue={newBotName}
              onChange={event => {
                setNewBotName(event.target.value);
              }}
              id="botNameInput"
            />

            <Button
              type="primary"
              onClick={handleSave}
              disabled={formHasNotChanged}
              loading={isSaving}
            >
              {isSaving ? 'Saving' : 'Save'}
            </Button>
          </BotNamingContainer>

          <BotNameMessage>
            Your bot will be displayed as{' '}
            {newBotName === '' || newBotName === org.name
              ? `"${org.name} - Assistant by UpdateAI"`
              : `"${newBotName}'s Assistant by UpdateAI"`}
          </BotNameMessage>
        </>
      ) : (
        <Spin />
      )}
    </ToggleCard>
  );
};
