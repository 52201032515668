import styled from 'styled-components';
import LabeledInput from '../../_Misc/LabeledInput';

export const BotNamingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-end;

  p {
    margin-block-end: 0 !important;
  }
`;

export const StyledInput = styled(LabeledInput)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  width: 320px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  letter-spacing: 0.01rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const BotNameMessage = styled.p`
  margin-block-end: 0;
`;
