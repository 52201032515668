import React from 'react';
import Styles from '../style';
import { ProfileAvatar } from '../../../../../../../componentsV2/ProfileAvatar';
import { Flex, Tag } from 'antd';
import { CrownFilled, TrophyFilled } from '@ant-design/icons';
import AdvocacyClassification from '../../../../../../../components/contacts/AdvocacyClassification';
import DecisionMakerTag from '../../../../../../../components/contacts/DecisionMakerTag';

const {
  HeaderSection,
  // Label,
  // LabelValue,
  // MeetingInfo,
  // MeetingsInfoSection,
  Subtitle,
  NameAndTitleFrame,
  // Percentage,
  // TalkRatioFrame,
  // TalkRatioLabel,
  // TalkRatioSection,
  Title,
} = Styles;

interface Props {
  name: string;
  percentage: number;
  meetings: number;
  attendees: number;
  email: string;
  lastMeeting: string;
  noShows: number;
  role?: string;
  isDecisionMaker: boolean;
  advocacyClassification: string;
}

export const ContactCardContent: React.FC<Props> = ({
  name,
  email,
  isDecisionMaker,
  advocacyClassification,
  // percentage,
  // meetings,
  // lastMeeting,
  // noShows,
  role,
}) => {
  return (
    <>
      <HeaderSection>
        <ProfileAvatar image={<>{(name || email).slice(0, 1).toUpperCase()}</>} />

        <NameAndTitleFrame>
          <Title>{name || email}</Title>
          {role && <Subtitle>{role} pepepepe</Subtitle>}
          {(isDecisionMaker || advocacyClassification !== 'None') && (
            <Flex gap={2}>
              {isDecisionMaker && <DecisionMakerTag />}
              {advocacyClassification !== 'None' && (
                <AdvocacyClassification classification={advocacyClassification} />
              )}
            </Flex>
          )}
        </NameAndTitleFrame>
      </HeaderSection>

      {/* <TalkRatioSection>
        <Progress
          percent={percentage}
          showInfo={false}
          strokeColor={colors.primary[500]}
          style={{ margin: '0px', lineHeight: '4px' }}
        />

        <TalkRatioFrame>
          <TalkRatioLabel>Avg. talk ratio</TalkRatioLabel>
          <Percentage>{`${percentage?.toFixed(2) || 0}%`}</Percentage>
        </TalkRatioFrame>
      </TalkRatioSection>

      <MeetingsInfoSection>
        <MeetingInfo>
          <Label>Last meeting</Label>
          <LabelValue>
            {lastMeeting ? format(new Date(lastMeeting), ACCOUNT_DATE_FORMAT) : '-'}
          </LabelValue>
        </MeetingInfo>

        <MeetingInfo>
          <Label>Meetings</Label>
          <LabelValue>{meetings}</LabelValue>
        </MeetingInfo>

        <MeetingInfo>
          <Label>Did not attend</Label>
          <LabelValue>{noShows}</LabelValue>
        </MeetingInfo>
      </MeetingsInfoSection> */}
    </>
  );
};
