import { useEffect, useMemo, useState } from 'react';
import { coreService } from '../../services/core/core-service';
import { Alert } from '../../components/alerts/Alert';
import { useToast } from '../../hooks/useToast';
import * as EmailValidator from 'email-validator';

enum IAuthorizationMechanism {
  authorized_only = 'AUTHORIZED_ONLY',
  public = 'PUBLIC',
  requires_identification = 'REQUIRES_IDENTIFICATION',
}

interface IShareLink {
  id: string;
  isExpired: boolean;
  type: IAuthorizationMechanism;
  url: string;
  expirationDate: string;
  views: number;
  description?: string;
}

export const useShareLink = (id: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [link, setLink] = useState<IShareLink | null>(null);
  const [sent, setSent] = useState(false);

  const toast = useToast();

  useEffect(() => {
    coreService
      .getShareLink(id)
      .then(response => setLink(response))
      .catch(e => {
        setMessage(e.response.data.message);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleValidation = async (email: string) => {
    if (!link) return;
    if (!EmailValidator.validate(email))
      return toast.error('Invalid email address. Please try again.');

    try {
      setError(false);
      setIsValidating(true);
      await coreService.validateShareLink({ email: email, shareLinkId: link.id });
      setSent(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(true);
      setMessage(e.response.data.message);
      toast.error('An error occurred while sending the Access Link. Please try again.');
    } finally {
      setIsValidating(false);
    }
  };

  const alert = useMemo(() => {
    if (link?.isExpired) {
      setError(true);
      setMessage('The link your are trying to access is expired.');
    }

    if (error) return <Alert variant="error" title="" message={message} width="auto" />;

    if (sent) {
      return (
        <Alert
          title=""
          variant="success"
          message={
            <span>
              An access link has been sent to your email. Please check your inbox, and if you don’t
              see it, check your spam. Alternatively,{' '}
              <a href="/meetings/recaps" style={{ textDecoration: 'underline', color: 'green' }}>
                click here
              </a>{' '}
              to return to the home page and continue browsing.
            </span>
          }
          width="auto"
        />
      );
    }

    return (
      <Alert
        title=""
        variant="info"
        message="Please verify your identity to access this meeting recap. You'll receive an email with a special access link -- If it's not in your inbox, check your spam folder."
        width="auto"
      />
    );
  }, [link?.isExpired, message, error, sent]);

  const redirect = useMemo(() => {
    if (link?.type === IAuthorizationMechanism.public && !link?.isExpired) return link.url;
    return null;
  }, [link?.type]);

  return {
    isLoading,
    isValidating,
    alert,
    error,
    link,
    redirect,
    handleValidation,
    isExpired: link?.isExpired,
  };
};
