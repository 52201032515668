import { Button, Divider, Flex } from 'antd';
import React, { useMemo } from 'react';
import { Detail, Footer, LeadingContent, Title } from '../Account/style';
import Section from '../_Misc/Section';
import { CardHolder } from './CardHolder';
import { CurrentPlanCard } from './CurrentPlanCard';
import Style from './style';
import { useSubscription } from '../../../../features/subscription/hooks/useSubscription';
import { StripeSubscription, Subscription } from '../../../../features/subscription/types';
import { UpdateLoader } from '../../../../componentsV2/UpdateLoader';

function isStripeSubscription(billingMechanism: any): billingMechanism is StripeSubscription {
  return billingMechanism.mechanism === 'stripe';
}

function isSubscriptionLoaded(
  subscription: Subscription | undefined,
): subscription is Subscription {
  return typeof subscription !== 'undefined';
}

const Billing: React.FC = () => {
  const { Container } = Style;
  const { subscription, isFetching } = useSubscription();

  const billingInfo = useMemo(() => {
    const billing = subscription?.subscription.billing;
    if (subscription && isStripeSubscription(billing)) {
      const cardDetails = billing.paymentMethod.card;
      return (
        <>
          <Section title="Payment Method" description="Change how you paid your plan.">
            <LeadingContent>
              <Title>Card information</Title>
              <Detail>Edit your credit card on file</Detail>
            </LeadingContent>
            <Footer>
              <CardHolder
                provider={cardDetails.provider}
                last4={cardDetails.lastFourDigits}
                expiration={cardDetails.expiresAt}
                isCanceled={Boolean(subscription.subscription.isCanceled)}
              />
            </Footer>
          </Section>
          <Divider />
        </>
      );
    } else {
      return <></>;
    }
  }, [subscription]);

  if (isFetching) {
    return (
      <Flex style={{ height: '100%' }}>
        <UpdateLoader text="Loading plan and billing data" />
      </Flex>
    );
  }

  return (
    <Container>
      <Section
        title="Plans and Billing"
        description="Pick a plan that works best for you and your team."
      />
      <Divider />
      <Section
        title={
          <Flex align="baseline" gap={'4px'}>
            <span>Current Plan</span>
            <Button
              style={{ fontSize: '12px', margin: '0px' }}
              type="link"
              onClick={() => window.open('https://update.ai/pricing/')}
            >
              Explore all plans
            </Button>
          </Flex>
        }
        childrenBackground={false}
        childrenBorder={false}
      >
        <Flex gap={12} style={{ paddingLeft: '24px', paddingRight: '24px', width: '100%' }}>
          {isSubscriptionLoaded(subscription) && (
            <CurrentPlanCard
              subscription={subscription}
              canManageSubscription={Boolean(subscription?.canManageSubscription)}
              paidSeats={subscription?.subscription.paidSeats || 0}
              usedPaidSeats={subscription?.usedPaidSeats || 0}
            />
          )}
        </Flex>
      </Section>
      <Divider />

      {billingInfo}
    </Container>
  );
};

export default Billing;
