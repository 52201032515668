import {
  AccountFilterConfig,
  AccountsFilterStore,
  DateRangeFilterConfig,
  DateRangeFilterStore,
  KeyToFilterConfig,
  KeyToFilterStore,
  ListFilterConfig,
  ListFilterStore,
  MeetingTypeFilterConfig,
  MeetingTypeFilterStore,
  MultipleAccountsFilterConfig,
  MultipleAccountsFilterStore,
  NumberFilterConfig,
  NumberFilterStore,
  NumberRangeFilterConfig,
  NumberRangeFilterStore,
  ProductLinesFilterConfig,
  ProductLinesFilterStore,
  OwnersFilterConfig,
  OwnersFilterStore,
  SelectionFilterConfig,
  SelectionFilterStore,
} from './types';

export const listConfigToType = (typeConfig: ListFilterConfig): ListFilterStore => {
  const allowsNoValueSet = !!typeConfig.config.allowNoValueSet;

  let allOptions = typeConfig.config.options;

  if (allowsNoValueSet && !allOptions.find(option => option.id === '(No value set)')) {
    allOptions = [{ id: '(No value set)', label: '(No value set)', selected: true }, ...allOptions];
  }

  return {
    type: 'list',
    value: {
      options: allOptions,
    },
    componentProps: typeConfig.componentProps,
  };
};

export const dateRangeConfigToType = (typeConfig: DateRangeFilterConfig): DateRangeFilterStore => {
  return {
    type: 'dateRange',
    value: {
      dateOptionValue: '30d',
      value: typeConfig.config.value,
      interval: typeConfig.config.interval,
    },
    componentProps: typeConfig.componentProps,
  };
};

export const numberConfigToType = (typeConfig: NumberFilterConfig): NumberFilterStore => {
  return {
    type: 'number',
    value: {
      value: typeConfig.config.value,
    },
    componentProps: typeConfig.componentProps,
  };
};

export const selectConfigToType = (typeConfig: SelectionFilterConfig): SelectionFilterStore => {
  return {
    type: 'select',
    value: {
      defaultValue: typeConfig?.config?.defaultValue || '',
      options: typeConfig.config.options,
      selected: typeConfig.config.selected,
    },
    componentProps: typeConfig.componentProps,
  };
};

export const accountConfigToType = (typeConfig: AccountFilterConfig): AccountsFilterStore => {
  return {
    type: 'account',
    value: {
      selected: typeConfig.config.selected || '',
    },
    componentProps: typeConfig.componentProps,
  };
};

export const multipleAccountsConfigToType = (typeConfig: MultipleAccountsFilterConfig): MultipleAccountsFilterStore => {
  return {
    type: 'multipleAccounts',
    value: {
      selected: typeConfig.config.selected || [],
    },
    componentProps: typeConfig.componentProps,
  };
};

export const productLinesConfigToType = (typeConfig: ProductLinesFilterConfig): ProductLinesFilterStore => {
  return {
    type: 'productLines',
    value: {
      selected: typeConfig.config.selected || [],
    },
    componentProps: typeConfig.componentProps,
  };
};

export const ownersConfigToType = (typeConfig: OwnersFilterConfig): OwnersFilterStore => {
  return {
    type: 'owners',
    value: {
      selected: typeConfig.config.selected || [],
    },
    componentProps: typeConfig.componentProps,
  };
};

export const numberRangeConfigToType = (
  typeConfig: NumberRangeFilterConfig,
): NumberRangeFilterStore => {
  return {
    type: 'numberRange',
    value: {
      interval: typeConfig.config.interval || [0, 0],
    },
    componentProps: typeConfig.componentProps,
  };
};

export const meetingTypeConfigToType = (
  typeConfig: MeetingTypeFilterConfig,
): MeetingTypeFilterStore => {
  return {
    type: 'meetingType',
    value: {
      selected: typeConfig.config.selected || null,
    },
    componentProps: typeConfig.componentProps,
  };
};

export const filtersConfigToStore = (filtersArgs: KeyToFilterConfig) => {
  const filters: KeyToFilterStore = Object.keys(filtersArgs).reduce<KeyToFilterStore>(
    (previousValue, currentValue) => {
      const currentFilter = filtersArgs[currentValue];
      if (currentFilter) {
        switch (currentFilter.type) {
          case 'list':
            previousValue[currentValue] = listConfigToType(currentFilter);
            break;
          case 'dateRange':
            previousValue[currentValue] = dateRangeConfigToType(currentFilter);
            break;
          case 'number':
            previousValue[currentValue] = numberConfigToType(currentFilter);
            break;
          case 'select':
            previousValue[currentValue] = selectConfigToType(currentFilter);
            break;
          case 'account':
            previousValue[currentValue] = accountConfigToType(currentFilter);
            break;
          case 'multipleAccounts':
            previousValue[currentValue] = multipleAccountsConfigToType(currentFilter);
            break
          case 'productLines':
            previousValue[currentValue] = productLinesConfigToType(currentFilter);
            break
          case 'owners':
            previousValue[currentValue] = ownersConfigToType(currentFilter);
            break
          case 'numberRange':
            previousValue[currentValue] = numberRangeConfigToType(currentFilter);
            break;
          case 'meetingType':
            previousValue[currentValue] = meetingTypeConfigToType(currentFilter);
            break;
        }
      }
      return previousValue;
    },
    {},
  );
  return filters;
};
