import React, { useState, useEffect, ReactElement, useCallback } from 'react';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { coreService } from '../../../../../services/core/core-service';
import { Summarization } from '../../../../../services/types';
import { Button, Spin } from 'antd';
import { format } from 'date-fns'; //isSameDay, isSameMonth, isSameYear
import { MeetingTitleLink } from '../../../../../components/common/MeetingTitleLink';

import Styles from './style';
import { DeleteOutlined } from '@ant-design/icons';
import { useToast } from '../../../../../hooks/useToast';
const { Container, EmptyState, Text } = Styles;

type SummaryHistoryProps = {
  summarization: Summarization;
  isProcessing: boolean;
  processType: 'deleting' | 'summarizing';
  onDelete: (summaryHistoryItemId: string) => void;
};

const Item: React.FC<SummaryHistoryProps> = ({
  summarization,
  isProcessing,
  processType,
  onDelete,
}) => {
  if (summarization.narrative?.length === 0) return <></>;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
      {format(new Date(summarization.lastRequestedAt), 'eeee, MMMM dd, YYY - HH:MM ')}
      {format(new Date(summarization.lastRequestedAt), 'aaa').toUpperCase()}
      <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', gap: '5px 10px', flexWrap: 'wrap' }}>
          {summarization.events.map(e => (
            <MeetingTitleLink key={e.id} id={e.id} title={e.title} />
          ))}
        </div>
        <Button
          size="small"
          disabled={isProcessing}
          onClick={() => onDelete(summarization.id)}
          style={{ padding: '0px 6px' }}
        >
          <DeleteOutlined />
        </Button>
      </header>
      <Text>
        {isProcessing ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            AI summary is being {processType === 'deleting' ? 'deleted' : 'generated'}
            <Spin size="small" />
          </div>
        ) : (
          summarization.narrative
        )}
      </Text>
    </div>
  );
};

export const SummaryHistory: React.FC<{ active: boolean }> = ({ active }) => {
  const { selectedAccount } = useAccountOverview();
  const { error, success } = useToast();
  const queryClient = useQueryClient();

  const [summarization, setSummarization] = useState<Summarization[]>([]);
  const [summarizationItem, setSummarizationItem] = useState<Summarization>();

  const { data, isFetching, isRefetching } = useQuery({
    queryKey: ['user-event-summarization', selectedAccount.id, active],
    queryFn: () => {
      return coreService.getAccountEventSummarizations(selectedAccount.id);
    },
    refetchIntervalInBackground: true,
    refetchInterval: 15000,
  });

  const handleDelete = async (id: string) => {
    try {
      setSummarizationItem(summarization.find(item => item.id === id));
      await coreService.deleteAccountEventSummarization(selectedAccount.id, id);
      setSummarization(prev => prev.filter(item => item.id !== id));
      success('Item deleted successfully.');
      queryClient.refetchQueries({
        queryKey: ['user-event-summarization', selectedAccount.id, active],
      });
    } catch (err) {
      error('Something went wrong when deleting the Summary History item.');
    } finally {
      setSummarizationItem(undefined);
    }
  };

  const items: ReactElement[] = summarization.map(summarization => (
    <Item
      key={summarization.id}
      summarization={summarization}
      onDelete={handleDelete}
      isProcessing={summarization.isProcessing || summarizationItem?.id === summarization.id}
      processType={summarization.isProcessing ? 'summarizing' : 'deleting'}
    />
  ));

  useEffect(() => {
    const sortedData = data ? [...data?.summarizations] : [];
    sortedData.sort(
      (a, b) => new Date(b.lastRequestedAt).getTime() - new Date(a.lastRequestedAt).getTime(),
    );
    setSummarization(sortedData);
  }, [data]);

  useEffect(() => {
    return;
  }, []);

  return (
    <Container>
      {isFetching && !isRefetching && (
        <Spin style={{ width: '100%', display: 'flex', justifyContent: 'center' }} />
      )}
      {summarization.length === 0 && !isFetching ? (
        <EmptyState>No AI summarizations generated</EmptyState>
      ) : (
        items
      )}
    </Container>
  );
};
