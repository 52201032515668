import { PropsWithChildren, createContext, useEffect, useRef } from 'react';
import { DashboardFiltersStore, createStore } from './dashboard-filters.store';
import { KeyToFilterConfig } from './types';

interface ProviderProps {
  filters: KeyToFilterConfig;
}

interface DashboardFilterContext {
  store: DashboardFiltersStore;
}

export const DashboardFilterContext = createContext<DashboardFilterContext>({
  store: createStore({ filters: {} }),
});

export const DashboardFilterProvider: React.FC<PropsWithChildren<ProviderProps>> = ({
  filters,
  children,
}) => {
  const store = useRef(createStore({ filters })).current;

  useEffect(() => {
    store.getState().filtersChanged(filters);
  }, [filters]);

  return (
    <DashboardFilterContext.Provider value={{ store }}>{children}</DashboardFilterContext.Provider>
  );
};
