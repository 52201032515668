export const getUserBrowser = (): string => {
    const { userAgent } = navigator;

    if (/edg/i.test(userAgent)) {
        return "Microsoft Edge";
    } else if (/chrome|crios|crmo/i.test(userAgent)) {
        return "Google Chrome";
    } else if (/firefox|fxios/i.test(userAgent)) {
        return "Mozilla Firefox";
    } else if (/safari/i.test(userAgent) && !/chrome|crios|crmo/i.test(userAgent)) {
        return "Apple Safari";
    } else if (/opr|opera/i.test(userAgent)) {
        return "Opera";
    } else if (/msie|trident/i.test(userAgent)) {
        return "Internet Explorer";
    } else {
        return "Unknown browser";
    }
}