import React from 'react';
import { Mentions, TopMentionsType } from '../../../../../../services/types';
import { Meeting } from './components/Meeting';
import { Flex, Spin, Typography } from 'antd';

import Styles from './styles';
const { Container } = Styles;

interface Props {
  isParentLoading: boolean;
  mentionsData: TopMentionsType;
  onRemove: (id: string) => void;
  onFavorite: (id: string) => void;
  onUnfavorite: (id: string) => void;
  onExclude: (id: string) => void;
}

export const MentionsContainer: React.FC<Props> = ({
  mentionsData,
  isParentLoading,
  onRemove,
  onFavorite,
  onUnfavorite,
  onExclude,
}) => {
  return (
    <Container>
      {isParentLoading ? (
        <Spin />
      ) : (
        <>
          {mentionsData.mentions.length === 0 && (
            <Flex justify="center" align="center" style={{ height: '100%' }}>
              <Typography.Text>No detections found</Typography.Text>
            </Flex>
          )}
          {(mentionsData.mentions || []).map((mention: Mentions) => {
            return (
              <Meeting
                id={mention.id}
                insights={mention.mentions}
                account={mention.account}
                title={mention.title}
                scheduledStartDate={new Date(mention.scheduledStartDate)}
                onRemove={onRemove}
                onExclude={onExclude}
                onFavorite={onFavorite}
                onUnfavorite={onUnfavorite}
                key={mention.id}
              />
            );
          })}
        </>
      )}
    </Container>
  );
};
