import React, { useState } from 'react';
import Styled from './style';
import Section from '../../../_Misc/Section';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../../../services/core/core-service';
import { Table, TableColumnDefinition } from '../../../../../../componentsV2/Table';
import { Button, Dropdown, Flex, Tag, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateProductLineModal } from './components/CreateProductLineModal';
import { useToast } from '../../../../../../hooks/useToast';

const { Container, Title } = Styled;

export const TopicsSection: React.FC<{}> = () => {
  const { error, success } = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productLine, setProductLine] = useState<ProductLine | null>(null);

  const {
    data: productLines,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['productLines'],
    queryFn: () => {
      return coreService.getWorkspaceProductLines();
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleDelete = async (productLineId: string) => {
    try {
      setIsLoading(true);
      await coreService.removeWorkspaceProductLine(productLineId);
      success('Product line deleted successfully');
      refetch();
    } catch (err) {
      error('Failed to delete product line');
    } finally {
      setIsLoading(false);
    }
  };

  const columns: TableColumnDefinition<ProductLine>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
    {
      key: 'color',
      title: 'Color',
      dataIndex: 'color',
      render: color => <Tag color={color}>{color}</Tag>,
    },
    {
      key: 'actions',
      title: 'Actions',
      width: '80px',
      render: (value, record: ProductLine) => (
        <Dropdown
          trigger={['click']}
          menu={{
            items: [
              {
                label: 'Edit',
                icon: <EditOutlined />,
                key: 'edit-product-line',
                onClick: () => {
                  setProductLine(record);
                  setIsModalOpen(true);
                },
              },
              {
                label: 'Delete',
                icon: <DeleteOutlined />,
                key: 'delete-product-line',
                danger: true,
                onClick: () => {
                  handleDelete(record.id);
                },
              },
            ],
          }}
        >
          <Button type="default" icon={<MoreOutlined />} size="small" />
        </Dropdown>
      ),
    },
  ];

  const handleClose = () => {
    setIsModalOpen(false);

    if (productLine) {
      setProductLine(null);
    }
  };

  const handleOnSuccess = () => {
    refetch();
    setIsModalOpen(false);
    setProductLine(null);
  };

  return (
    <>
      <CreateProductLineModal
        open={isModalOpen}
        onClose={handleClose}
        productLine={productLine ?? undefined}
        onSuccess={handleOnSuccess}
      />

      <Section
        title="Topic Settings"
        description="Manage your topics' settings for the entire workspace."
      >
        <Container>
          <Flex align="center" justify="space-between">
            <Flex vertical gap={4}>
              <Title>Product Lines</Title>
              <Typography.Text>
                Add your company's product lines to enable UpdateAI to organize insights by product
                category.
              </Typography.Text>
            </Flex>
            <Button onClick={() => setIsModalOpen(true)}>
              <PlusOutlined /> Add New Product Line
            </Button>
          </Flex>
          <Table
            locale={{
              emptyText:
                isLoading || isFetching ? `Loading product lines...` : 'No product line found',
            }}
            pagination={{
              position: ['bottomRight'],
            }}
            loading={isLoading || isFetching}
            columns={columns}
            data={productLines ?? []}
          />
        </Container>
      </Section>
    </>
  );
};
