import { Button, Input, Spin } from 'antd';
import React, { PropsWithChildren, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MicrosoftButton } from '../../../../features/authentication/components/MicrosoftButton';
import { coreService } from '../../../../services/core/core-service';
import { useToast } from '../../../../hooks/useToast';
import AuthenticationForm from '../../../../features/authentication/components/AuthenticationForm';
import Label from '../../../../features/shared/components/Label';
import Styles from './styles';
import { isValidEmail } from '../../../../utils/email';
import { GoogleButton } from '../../../../components/layout/FramedLayout/Login/GoogleButton';

const {
  FormContainer,
  ActionsContainer,
  LoginLink,
  Line,
  DividerContainer,
  RegisterContainer,
  ErrorMessage,
  LoginOptions,
} = Styles;

interface Props {
  onStandardLogin: () => unknown;
}

const SignInWrapper: React.FC<PropsWithChildren<{ description: string }>> = ({
  children,
  description,
}) => {
  const [params] = useSearchParams();
  return (
    <AuthenticationForm title="Welcome back" description={description}>
      <FormContainer>
        {children}

        <RegisterContainer>
          <Label>Don't have an account?</Label>
          <LoginLink href="/signup">Register here</LoginLink>.
        </RegisterContainer>

        <ErrorMessage>{params.get('errorMessage') || ''}</ErrorMessage>
      </FormContainer>
    </AuthenticationForm>
  );
};

const SigninForm: React.FC<Props> = ({ onStandardLogin }) => {
  const [sso, setSso] = useState<Boolean | null>(null);
  const [email, setEmail] = useState('');
  const { error: errorToast } = useToast();

  const handleClick = async () => {
    const finalUrl = `${process.env.REACT_APP_API_CORE}/users/microsoft/authenticate`;
    window.location.replace(finalUrl);
  };

  const handleDomainCheck = async () => {
    if (!isValidEmail(email)) return;
    if (
      email.toLocaleLowerCase().includes('@disqo.com') ||
      email.toLocaleLowerCase().includes('@shiphero.com') ||
      (process.env.REACT_APP_PORTAL_ENV === 'staging' &&
        email.toLocaleLowerCase().includes('@update.ai'))
    ) {
      setSso(true);
      coreService
        .getSsoSigninUrl(email)
        .then(data => data.url)
        .then(data => {
          window.open(data, '_blank');
        })
        .catch(error => errorToast(`SSO is not enabled for your e-mail domain`))
        .finally(() => setSso(null));
    } else {
      setSso(false);
    }
  };

  if (sso === null) {
    return (
      <SignInWrapper description="Type your email to select your sign in preferred method.">
        <Input placeholder="Enter your email" onChange={e => setEmail(e.target.value)} />
        <Button onClick={handleDomainCheck} disabled={!isValidEmail(email)}>
          Sign In
        </Button>
      </SignInWrapper>
    );
  }

  return (
    <SignInWrapper description="Sign in with your preferred method">
      <ActionsContainer>
        <LoginOptions>
          {sso ? (
            <Spin />
          ) : (
            <>
              <GoogleButton />
              <div style={{ marginLeft: '25px' }}>
                <MicrosoftButton label="Sign in with Microsoft" handleClick={handleClick} />
              </div>
            </>
          )}
        </LoginOptions>

        <DividerContainer>
          <Line />
          <Label style={{ margin: '20px 8px' }}>OR</Label>
          <Line />
        </DividerContainer>
        <Button onClick={onStandardLogin} type="primary" disabled={sso === true}>
          Login with user and password
        </Button>
      </ActionsContainer>
    </SignInWrapper>
  );
};

export default SigninForm;
