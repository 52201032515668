import { useContext } from 'react';
import { accountOverviewContext } from './account-overview.context';
import { useStore } from 'zustand';

export const useAccountOverview = () => {
  const { store, setAccountId } = useContext(accountOverviewContext);
  const selectedAccount = useStore(store, store => store.selectedAccount);
  const selectedAccountFetching = useStore(store, store => store.isFetching);
  const dateRange = useStore(store, store => store.dateRange);
  const setDateRange = useStore(store, store => store.setDateRange);
  const myMeetingsOnly = useStore(store, store => store.myMeetingsOnly);
  const setMyMeetingsOnly = useStore(store, store => store.setMyMeetingsOnly);
  const stakeholderDataUpdated = useStore(store, store => store.stakeholderDataUpdated);
  const { primaryDealChanged, favouriteStatusChanged, summarizationRequested } = store();

  return {
    selectedAccountFetching,
    selectedAccount,
    dateRange,
    setDateRange,
    myMeetingsOnly,
    setMyMeetingsOnly,
    primaryDealChanged,
    setSelectedAccount: setAccountId,
    favouriteStatusChanged,
    summarizationRequested,
    stakeholderDataUpdated,
  };
};
