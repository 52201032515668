import { Space } from 'antd';
import { Table } from '../../../../../../componentsV2/Table';
import { AccountLabel } from '../../../../../../features/shared/components/AccountLabel';
import { AccountTopicClickHandler, SelectedTopic } from '../../types';
import { MentionCount } from '../../../MentionCount';
import { TopicLabel } from '../../../TopicLabel';

export type TopAccountsItemType = {
  id: string;
  name: string;
  mentions: number;
};

export interface TopAccountRecord {
  account: {
    id: string;
    name: string;
    provider: string;
  };
  mentions: number | null;
  meetings: number | null;
  topRisk?: TopAccountsItemType;
  topProductFeedback?: TopAccountsItemType;
  topAdvocacy?: TopAccountsItemType;
  topGrowth?: TopAccountsItemType;
}

export interface Props {
  data: TopAccountRecord[];
  loading?: boolean;
  onTopicClicked: AccountTopicClickHandler;
  onAccountClicked: (accountId: string) => unknown;
}

export const TopAccountsTable: React.FC<Props> = ({
  data,
  loading = false,
  onTopicClicked,
  onAccountClicked,
}) => {
  return (
    <Table
      locale={{
        emptyText: loading ? `Loading top accounts...` : 'No accounts found',
      }}
      pagination={false}
      loading={loading}
      columns={[
        {
          title: 'Account',
          key: 'account',
          sorter: (a, b) => a.account.name.localeCompare(b.account.name),
          render: value => {
            return (
              <AccountLabel
                account={value.account}
                onClick={() => onAccountClicked(value.account.id)}
              />
            );
          },
        },
        {
          title: 'Detections',
          key: 'mentions',
          dataIndex: 'mentions',
          sorter: (a, b) => (a.mentions || 0) - (b.mentions || 0),
        },
        {
          title: 'Meetings',
          key: 'meetings',
          dataIndex: 'meetings',
          sorter: (a, b) => (a.meetings || 0) - (b.meetings || 0),
        },
        {
          title: 'Top Risk',
          key: 'top-risk',
          dataIndex: 'topRisk',
          tooltip:
            'Evaluate potential risks that might impact customer satisfaction or threaten continuity and renewal, such as operational challenges or dips in user engagement.',
          sorter: (a, b) => (a.topRisk?.mentions || 0) - (b.topRisk?.mentions || 0),
          render: (value, record) => {
            if (!value) return `-`;

            return (
              <Space size={'small'}>
                <TopicLabel
                  name={`${value.name} (${value.mentions})`}
                  onClick={() =>
                    onTopicClicked(
                      {
                        id: value.id,
                        name: value.name,
                        description: value.description,
                      },
                      record.account.id,
                    )
                  }
                />
              </Space>
            );
          },
        },
        {
          title: 'Top Product Feedback',
          key: 'top-pf',
          dataIndex: 'topProductFeedback',
          tooltip:
            'Gather insights on how customers perceive the product, pinpointing areas needing improvement.',
          sorter: (a, b) =>
            (a.topProductFeedback?.mentions || 0) - (b.topProductFeedback?.mentions || 0),
          render: (value, record) => {
            if (!value) return `-`;

            return (
              <Space size={'small'}>
                <MentionCount count={value.mentions} />
                •
                <TopicLabel
                  name={value.name}
                  onClick={() =>
                    onTopicClicked(
                      {
                        id: value.id,
                        name: value.name,
                        description: value.description,
                      },
                      record.account.id,
                    )
                  }
                />
              </Space>
            );
          },
        },
        {
          title: 'Top Growth',
          key: 'top-growth',
          dataIndex: 'topGrowth',
          tooltip:
            'Identify potential areas for account expansion to enhance account value and explore new opportunities.',
          sorter: (a, b) => (a.topGrowth?.mentions || 0) - (b.topGrowth?.mentions || 0),
          render: (value, record) => {
            if (!value) return `-`;

            return (
              <Space size={'small'}>
                <MentionCount count={value.mentions} />
                •
                <TopicLabel
                  name={value.name}
                  onClick={() =>
                    onTopicClicked(
                      {
                        id: value.id,
                        name: value.name,
                        description: value.description,
                      },
                      record.account.id,
                    )
                  }
                />
              </Space>
            );
          },
        },
        {
          title: 'Top Advocacy',
          key: 'top-advocacy',
          dataIndex: 'topAdvocacy',
          tooltip:
            'Measure customer endorsement and support to understand their level of engagement and loyalty.',
          sorter: (a, b) => (a.topAdvocacy?.mentions || 0) - (b.topAdvocacy?.mentions || 0),
          render: (value, record) => {
            if (!value) return `-`;

            return (
              <Space size={'small'}>
                <MentionCount count={value.mentions} />
                •
                <TopicLabel
                  name={value.name}
                  onClick={() =>
                    onTopicClicked(
                      {
                        id: value.id,
                        name: value.name,
                        description: value.description,
                      },
                      record.account.id,
                    )
                  }
                />
              </Space>
            );
          },
        },
      ]}
      data={data}
    />
  );
};
