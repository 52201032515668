import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Detail,
  Message,
  NoDataContainer,
  NoDataLogo,
  Shade,
  Title,
} from '../InvitationsTable/style';
import { Container } from './style';
import { useToast } from '../../../../../../hooks/useToast';
import { HeaderCell, Cell, Header, Row } from '../../../../../../features/shared/components/Table';
import { selectUser } from '../../../../../../redux/selectors';
import { extractError } from '../../../../../../utils/api';
import { MeetingsVisibilityModal } from '../MeetingVisibilityModal';
import { RoleDropdown } from '../../../../../../features/organizations/components/RoleDropdown';
import { MeetingsVisibility } from './Components/MeetingsVisibility';
import { Member } from '../../../../../../features/organizations/types';
import { useOrganizationMembers } from '../../../../../../features/organizations/hooks/useOrganizationMembers';
import IconSvg from '../../../../../../components/common/IconSvg';
import NameCell from './Components/Name';
import UserTypeCell from './Components/UserType';
import JobTitle from './Components/JobTitle';
import { useOrganizationDetails } from '../../../../../../features/organizations/hooks/useOrganizationDetails';
import { fetchUser } from '../../../../../../redux/slices/sessionSlice';

interface Props {
  data: Member[];
  page: number;
  paginationSize: number;
}

const MembersTable: React.FC<Props> = ({ data, page, paginationSize }) => {
  const [userToModifyVisibility, setUserToModifyVisibility] = useState('');
  const { changeMemberType } = useOrganizationMembers();
  const { permissions } = useOrganizationDetails();
  const { success, error } = useToast();
  const loggedInUser = useSelector(selectUser);
  const dispatch = useDispatch();
  const disableUserInteractions = !permissions.manageOrganizationDetails;

  const handleSetUserType = async (userId: string, type: string) => {
    try {
      await changeMemberType(userId, type);
      if (userId === loggedInUser?.id) {
        dispatch(fetchUser());
      }
      success('User membership updated successfully');
    } catch (e) {
      const message = extractError(e);
      error(message);
    }
  };

  const RenderName = (row: Member) => {
    return (
      <NameCell
        firstName={row.user.firstName}
        lastName={row.user.lastName}
        email={row.user.email}
      />
    );
  };

  const RenderVisibility = (row: Member) => {
    return (
      <MeetingsVisibility
        canManageMeetingVisibility={row.permissions.manageMeetingsVisibility}
        meetingsVisibilityLocked={row.meetingVisibilityLocked}
        meetingsVisibility={{
          externalMeetingsVisibility: row.meetingsVisibility.externalMeetingsVisibility,
          internalMeetingsVisibility: row.meetingsVisibility.internalMeetingsVisibility,
        }}
        onChange={() => setUserToModifyVisibility(row.user.id)}
      />
    );
  };

  const RenderUserType = (row: Member) => {
    return (
      <UserTypeCell
        userType={row.type}
        onChange={type => {
          handleSetUserType(row.user.id, type);
        }}
        disableUserInteractions={loggedInUser?.membership.role === 'MEMBER'}
      />
    );
  };

  const RenderUserRole = (row: Member) => {
    const { user, role } = row;
    return (
      <RoleDropdown
        user={{ id: user.id, role, fullName: user.firstName, email: user.email }}
        isLoggedUser={user.id === loggedInUser?.id}
        disabled={disableUserInteractions || loggedInUser?.membership.role === 'MEMBER'}
        onOrganizationTransfered={() => window.location.reload()}
      />
    );
  };

  const columns: ColumnsType<Member> = [
    {
      title: <div style={{ paddingLeft: '24px' }}>Name</div>,
      key: 'name',
      render: RenderName,
      onCell: () => {
        return {
          style: {
            width: 'auto',
          },
        };
      },
    },
    {
      title: 'Role',
      render: RenderUserRole,
      key: 'role',
      width: '100px',
    },
    {
      title: 'User Type',
      key: 'type',
      render: RenderUserType,
      width: '100px',
    },
    {
      title: <div style={{ paddingLeft: '24px' }}>Meetings Visibility</div>,
      key: 'visibility',
      render: RenderVisibility,
      onHeaderCell: () => {
        return {
          style: {
            textAlign: 'center',
          },
        };
      },
      width: '200px',
    },
    {
      title: 'Job Title',
      key: 'type',
      render: ({ user }) => (
        <JobTitle
          userId={user.id}
          jobTitle={user.jobTitle}
          disableUserInteractions={disableUserInteractions}
        />
      ),
      width: '270px',
    },
  ];

  const components = {
    header: {
      row: Header,
      cell: HeaderCell,
    },
    body: {
      row: Row,
      cell: Cell,
    },
  };

  const tablePagination = {
    current: page,
    pageSize: paginationSize,
    position: [],
  };

  return (
    <Container>
      {userToModifyVisibility && (
        <MeetingsVisibilityModal
          open={true}
          onCancel={() => setUserToModifyVisibility('')}
          onDone={() => setUserToModifyVisibility('')}
          userId={userToModifyVisibility}
        />
      )}
      {data.length > 0 ? (
        <Table
          columns={columns}
          pagination={tablePagination}
          dataSource={data}
          components={components}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          rowKey={(e: any) => {
            return e.user.id;
          }}
        />
      ) : (
        <NoDataContainer>
          <NoDataLogo>
            <Shade>
              <IconSvg name="users" size="lg" color="transparent" />
            </Shade>
          </NoDataLogo>
          <Message>
            <Title>No data</Title>
            <Detail>It seems that there are no members here yet.</Detail>
          </Message>
        </NoDataContainer>
      )}
    </Container>
  );
};

export default MembersTable;
