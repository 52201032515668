import React, { useEffect, useMemo, useState } from 'react';
import { ContactCard } from './ContactCard';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { ProfileDrawer } from './ProfileDrawer';
import { AccountsContact } from '../../../../../API/types';

import Styles from './style';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
const { Container, ContactGroup, EmptyState } = Styles;

export const Stakeholders: React.FC = () => {
  const [loading, setLoading] = React.useState<string[]>([]);
  const [selectedStakeholderId, setSelectedStakeholderId] = useState('');

  const [searchTerm, setSearchTerm] = useState('');

  const { selectedAccount } = useAccountOverview();
  const sortedContacts = [...selectedAccount.contacts];
  sortedContacts.sort((a, b) => a.email.localeCompare(b.email));

  useEffect(() => {
    if (sortedContacts.length > 0) {
      setSelectedStakeholderId(sortedContacts[0].id);
    }
  }, [selectedAccount.id]);

  useEffect(() => {
    setLoading(
      (selectedAccount.contacts || [])
        .filter(contact => !!contact.isGeneratingProfile)
        .map(contact => contact.id),
    );
  }, [selectedAccount.contacts]);

  const selectedStakeHolder = useMemo(() => {
    return selectedAccount.contacts.find(c => c.id === selectedStakeholderId);
  }, [selectedStakeholderId, selectedAccount.contacts]);

  return (
    <>
      {sortedContacts.length === 0 && <EmptyState>No stakeholders found</EmptyState>}
      {sortedContacts.length > 0 && (
        <Container>
          <ContactGroup>
            <div>
              <Input
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                placeholder="Search Stakeholders"
                suffix={<SearchOutlined />}
              />
            </div>
            {sortedContacts
              .filter(
                contact =>
                  contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  contact.email?.toLowerCase().includes(searchTerm.toLowerCase()),
              )
              .map(contact => {
                const {
                  email,
                  id,
                  name,
                  noShows,
                  talkRatio,
                  totalMeetings,
                  lastMeetingDate,
                  decisionMakingStatus,
                  advocacyClassification,
                } = contact;
                const isDecisionMaker =
                  decisionMakingStatus && decisionMakingStatus.isDecisionMaker === true;
                const advocacyStatus = advocacyClassification
                  ? advocacyClassification.classification
                  : 'None';
                return (
                  <ContactCard
                    selected={selectedStakeholderId === contact.id}
                    onClick={() => {
                      setSelectedStakeholderId(contact.id);
                    }}
                    isDecisionMaker={isDecisionMaker}
                    advocacyClassification={advocacyStatus}
                    attendees={totalMeetings - noShows}
                    lastMeeting={lastMeetingDate}
                    noShows={noShows}
                    name={name}
                    meetings={totalMeetings}
                    percentage={talkRatio}
                    email={email}
                    key={id}
                  />
                );
              })}
          </ContactGroup>

          {selectedStakeHolder && (
            <ProfileDrawer
              stakeholder={selectedStakeHolder}
              setLoading={(id: string) => setLoading([...loading, id])}
              loading={loading.includes(selectedStakeholderId)}
            />
          )}
        </Container>
      )}
    </>
  );
};
