import { Badge, Button, Dropdown, Flex, Space, Spin, Tag, Tooltip, Typography } from 'antd';
import { Table, TableColumnDefinition } from '../../../../../../componentsV2/Table';
import { TopicLabel } from '../../../TopicLabel';
import { EllipsisWithTooltip } from '../../../../../shared/components/EllipsisWithTooltip';
import { useToast } from '../../../../../../hooks/useToast';
import {
  CaretRightOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExperimentFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PushpinOutlined,
  StopOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import { useMemo, useState } from 'react';
import { coreService } from '../../../../../../services/core/core-service';
import { TopAccountsType } from '../../../../../../services/types';
import Styles from './styles';
import { OptionalTooltipWrapper } from '../../../../../../componentsV2/OptionalTooltipWrapper';

const { TopicLabelContainer } = Styles;

export type TopAccountsItemType = {
  id: string;
  name: string;
  mentions: number;
};

export type TopTopicRecord = {
  id: string;
  name: string;
  description: string;
  totalRevenue: string | null;
  mentions: number | null;
  meetings: number | null;
  accounts: number | null;
  custom: boolean;
  following: boolean;
  pinned: boolean;
  disabled: boolean;
  type: string;
  productLines: {
    id: string;
    name: string;
    color: string;
  }[];
};

interface Props {
  data: TopTopicRecord[];
  loading?: boolean;
  showAccountColumn?: boolean;
  showTypeColumn?: boolean;
  showAccountsTooltip?: boolean;
  onTopicClicked: (args: {
    id: string;
    name: string;
    description: string;
    custom: boolean;
    following: boolean;
    type: string;
  }) => void;
  onEditTopicClicked?: (args: {
    id: string;
    name: string;
    description: string;
    custom: boolean;
    following: boolean;
    type: string;
  }) => void;
  filters?: {
    from: string;
    to: string;
    accountIds?: string[];
    crmFilters?: Record<string, string[]>;
  };
  onFollowTopicClicked: (id: string) => void;
  onUnfollowTopicClicked: (id: string) => void;
  onPinTopicClicked: (id: string) => void;
  onDisableTopicClicked: (id: string) => void;
  onArchiveTopicClicked: (id: string) => void;
  onRemoveTopicClicked: (id: string) => void;
  canEditTopic?: boolean;
}

export const TopTopicsTable: React.FC<Props> = ({
  data,
  loading = false,
  onTopicClicked,
  onEditTopicClicked,
  onFollowTopicClicked,
  onUnfollowTopicClicked,
  onPinTopicClicked,
  onDisableTopicClicked,
  onArchiveTopicClicked,
  onRemoveTopicClicked,
  showAccountColumn = true,
  showTypeColumn = false,
  canEditTopic = false,
  showAccountsTooltip = false,
  filters,
}) => {
  const { success } = useToast();

  function formatBigIntAsCurrency(value: string): string {
    if (!/^-?\d+$/.test(value)) {
      return 'Valor inválido';
    }

    try {
      const number = BigInt(value);

      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(number));
    } catch (e) {
      return 'Not currently available';
    }
  }

  const [accountsCache, setAccountsCache] = useState<Record<string, TopAccountsType>>({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (topicId: string, page = 1) => {
    try {
      setIsLoading(true);
      const res = await coreService.getTopAccountsByTopicId(
        topicId,
        filters!.from,
        filters!.to,
        page,
        10,
        'mentions',
      );

      setAccountsCache(prev => ({
        ...prev,
        [topicId]: {
          ...res,
          accounts: [
            ...(prev[topicId]?.accounts || []).filter(
              item => !res.accounts.some(account => account.id === item.id),
            ),
            ...res.accounts,
          ],
        },
      }));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenChange = async (isVisible: boolean, record: TopTopicRecord) => {
    if (!isVisible || accountsCache[record.id] !== undefined) {
      return;
    }

    await fetchData(record.id);
  };

  const handlePageChange = async (topicId: string) => {
    const { currentPage } = accountsCache[topicId]?.pagination.pages;
    await fetchData(topicId, currentPage + 1);
  };

  const sorterFunction =
    (key: keyof TopTopicRecord) =>
    (first: TopTopicRecord, second: TopTopicRecord): number => {
      if (first[key] === null || second[key] === null) {
        return 0;
      }
      return Number(first[key]) - Number(second[key]);
    };

  const getRemoveActionTooltip = (record: TopTopicRecord) => {
    if (!record.custom) {
      return `Only custom topics can be ${(record.mentions ?? 0) > 0 ? 'archived' : 'removed'}`;
    }

    if (record.mentions === 0) {
      return 'Removing a custom topic will delete it permanently.';
    }

    if (record.mentions && record.mentions > 0) {
      return `Archiving a custom topic will make it hidden from the dashboard and no it will no longer be receiving new insights.`;
    }

    return '';
  };

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text);
    success('Text copied to clipboard');
  };

  const itemActionsCounter = (item: TopTopicRecord) => {
    let counter = 0;

    counter += Number(item.pinned);
    counter += Number(item.disabled);
    counter += Number(item.custom);
    counter += Number(item.following);

    return counter;
  };

  const columns: TableColumnDefinition<TopTopicRecord>[] = [
    {
      key: 'topic',
      title: 'Topics',
      width: '250px',
      render: (value, record) => {
        const recordActionsCount = itemActionsCounter(record);
        return (
          <TopicLabelContainer style={{ gap: 16 }}>
            <Space>
              {recordActionsCount > 0 ? (
                <Tooltip
                  title={
                    <div>
                      {record.pinned && (
                        <Space>
                          <PushpinOutlined />
                          This topic is pinned.
                        </Space>
                      )}
                      {record.disabled && (
                        <Space>
                          <StopOutlined /> This topic is disabled.
                        </Space>
                      )}
                      {record.custom && (
                        <Space>
                          <ExperimentFilled /> This is a custom topic created manually.
                        </Space>
                      )}
                      {record.following && (
                        <Space>
                          <EyeOutlined /> You are following this topic.
                        </Space>
                      )}
                    </div>
                  }
                >
                  <Badge
                    count={recordActionsCount}
                    color="#ff6c54"
                    size="small"
                    style={{ padding: '0 8px', cursor: 'default' }}
                  />
                </Tooltip>
              ) : null}

              <TopicLabel
                name={`${value.name}`}
                onClick={() =>
                  onTopicClicked({
                    id: value.id,
                    name: value.name,
                    description: value.description,
                    custom: value.custom,
                    following: value.following,
                    type: value.type,
                  })
                }
              />
            </Space>
          </TopicLabelContainer>
        );
      },
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      textWrap: 'word-break',
      ellipsis: true,
      width: '600px',
      render: value => (
        <div
          style={{
            overflow: 'hidden',
          }}
        >
          {!value ? (
            <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
              Description not currently available.
            </Typography.Paragraph>
          ) : (
            <EllipsisWithTooltip
              text={value}
              multiline={true}
              customTooltipRender={text => (
                <Flex gap={'8px'} align="center">
                  <div>{text}</div>
                  <Tooltip title="Copy description" placement="top">
                    <Button onClick={() => handleCopyText(text)} size="small">
                      <CopyOutlined />
                    </Button>
                  </Tooltip>
                </Flex>
              )}
            />
          )}
        </div>
      ),
    },
    {
      key: 'product-lines',
      title: 'Product Line(s)',
      dataIndex: 'productLines',
      width: '250px',
      render: (value, record) => {
        return (
          <Flex>
            {record.productLines && record.productLines.length > 0 && (
              <>
                <Tag key={record.productLines[0].id} color={record.productLines[0].color}>
                  {record.productLines[0].name}
                </Tag>

                {record.productLines.length > 1 && (
                  <Tooltip title="See all product lines">
                    <Tag
                      style={{ cursor: 'pointer' }}
                      onClick={() => onTopicClicked(record)}
                      color="default"
                    >
                      <ZoomInOutlined />
                    </Tag>
                  </Tooltip>
                )}
              </>
            )}
          </Flex>
        );
      },
    },
    {
      key: 'revenue-value',
      title: 'Commercial Value',
      dataIndex: 'totalRevenue',
      width: '200px',
      sorter: sorterFunction('totalRevenue'),
      render: value => (
        <div
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography.Paragraph
            type={value === '0' ? 'secondary' : undefined}
            style={{ marginBottom: 0 }}
          >
            {value && value !== '0' ? formatBigIntAsCurrency(value) : 'Not currently available.'}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      key: 'mentions',
      title: 'Detections',
      dataIndex: 'mentions',
      width: '150px',
      sorter: sorterFunction('mentions'),
    },
    {
      key: 'meetings',
      title: 'Meetings',
      dataIndex: 'meetings',
      width: '150px',
      sorter: sorterFunction('meetings'),
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'actions',
      width: '50px',
      render(value, record) {
        return (
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  label: (
                    <OptionalTooltipWrapper
                      value={'Only owners or admins can edit topics.'}
                      display={!canEditTopic}
                    >
                      Edit
                    </OptionalTooltipWrapper>
                  ),
                  disabled: !canEditTopic,
                  icon: <EditOutlined />,
                  key: 'edit-topic',
                  onClick: () => {
                    if (onEditTopicClicked) {
                      onEditTopicClicked({
                        id: record.id,
                        name: record.name,
                        description: record.description,
                        custom: record.custom,
                        following: record.following,
                        type: record.type,
                      });
                    }
                  },
                },
                {
                  label: record.following ? 'Unfollow' : 'Follow',
                  icon: record.following ? <EyeInvisibleOutlined /> : <EyeOutlined />,
                  key: 'follow-topic',
                  onClick: () => {
                    if (record.following) {
                      onUnfollowTopicClicked(record.id);
                    } else {
                      onFollowTopicClicked(record.id);
                    }
                  },
                },
                {
                  label: (
                    <OptionalTooltipWrapper
                      value={
                        !canEditTopic
                          ? 'Only owners or admins can pin topics.'
                          : record.custom
                          ? 'Custom made topics cannot be pinned. They will not be recalculated.'
                          : null
                      }
                      display={!canEditTopic || record.custom}
                    >
                      {record.custom ? 'Pin' : record.pinned ? 'Unpin' : 'Pin'}
                    </OptionalTooltipWrapper>
                  ),
                  disabled: !canEditTopic || record.custom,
                  icon: <PushpinOutlined />,
                  key: 'pin-topic',
                  onClick: () => {
                    onPinTopicClicked(record.id);
                  },
                },
                {
                  label: (
                    <OptionalTooltipWrapper
                      value={
                        !canEditTopic
                          ? 'Only owners or admins can edit topics.'
                          : record.pinned
                          ? 'Cannot disable a pinned topic.'
                          : null
                      }
                      display={!canEditTopic || record.pinned}
                    >
                      {record.disabled ? 'Enable' : 'Disable'}
                    </OptionalTooltipWrapper>
                  ),
                  disabled: !canEditTopic,
                  icon: record.disabled ? <CaretRightOutlined /> : <StopOutlined />,
                  key: 'disable-topic',
                  onClick: () => {
                    onDisableTopicClicked(record.id);
                  },
                },
                {
                  label: (
                    <Tooltip title={getRemoveActionTooltip(record)}>
                      {(record.mentions ?? 0) > 0 ? 'Archive' : 'Remove'}
                    </Tooltip>
                  ),
                  icon: (record.mentions ?? 0) > 0 ? <InboxOutlined /> : <DeleteOutlined />,
                  disabled: !record.custom,
                  danger: true,
                  key: 'delete-archive-topic',
                  onClick: () => {
                    if (record.mentions && record.mentions > 0) {
                      onArchiveTopicClicked(record.id);
                    } else {
                      onRemoveTopicClicked(record.id);
                    }
                  },
                },
              ],
            }}
          >
            <Button type="default" icon={<MoreOutlined />} size="small" />
          </Dropdown>
        );
      },
    },
  ];

  if (showTypeColumn) {
    columns.splice(2, 0, {
      key: 'type',
      title: 'Type',
      dataIndex: 'type',
      width: '150px',
      sorter: sorterFunction('type'),
    });
  }

  if (showAccountColumn) {
    columns.splice(4, 0, {
      key: 'accounts',
      title: '# of Accounts',
      dataIndex: 'accounts',
      width: '190px',
      render: (value, record) => {
        if (!showAccountsTooltip) {
          return value;
        }

        return (
          <Tooltip
            overlayStyle={{ maxWidth: '500px' }}
            mouseLeaveDelay={0.2}
            title={
              <Flex align="center" vertical gap="8px" justify="center">
                {isLoading && !accountsCache[record.id] && <Spin />}

                {accountsCache[record.id] && (
                  <div style={{ flexWrap: 'wrap', wordWrap: 'break-word' }}>
                    {accountsCache[record.id]?.accounts.map(account => account.name).join(', ')}
                  </div>
                )}

                {accountsCache[record.id]?.pagination.pages.currentPage <
                  accountsCache[record.id]?.pagination.pages.total && (
                  <Button
                    onClick={() => handlePageChange(record.id)}
                    size="small"
                    loading={isLoading}
                  >
                    Load More
                  </Button>
                )}
              </Flex>
            }
            onOpenChange={visible => handleOpenChange(visible, record)}
          >
            <Typography.Text style={{ cursor: 'default' }}>{value}</Typography.Text>
          </Tooltip>
        );
      },
      sorter: sorterFunction('accounts'),
    });
  }

  return (
    <Table
      locale={{
        emptyText: loading ? `Loading top topics...` : 'No topics found',
      }}
      pagination={false}
      loading={loading}
      columns={columns}
      data={data}
    />
  );
};
