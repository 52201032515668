import { useContext, useEffect, useState } from 'react';
import { SelectedTopic } from '../TopAccounts/types';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { Button, Flex, Select, Space, Tooltip } from 'antd';
import { ViewAllTopicsModal } from './components/ViewAllTopicsModal';
import { TopTopicsTable } from './components/TopTopicsTable';
import Card from '../../../../componentsV2/Card';
import { DashboardFilterContext } from '../../../shared/dashboard-filters/dashboard-filters.context';
import { InsightsFilters, TopTopicsType } from '../../../../services/types';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux/typings/store';
import { PlusOutlined } from '@ant-design/icons';
import { useStore } from 'zustand';

interface TopTopicsProps {
  onTopicSelected: (args: {
    id: string;
    name: string;
    description: string;
    type: string;
    custom: boolean;
    following: boolean;
  }) => unknown;
  onEventCountCalculated?: (count: number) => void;
  insightType?: string;
  onEditTopic?: (args: {
    id: string;
    name: string;
    description: string;
    custom: boolean;
    following: boolean;
    type: string;
  }) => unknown;
  onAddTopic?: (category: string) => void;
  onFollowTopic: (id: string) => void;
  onUnfollowTopic: (id: string) => void;
  onPinTopic: (id: string) => void;
  onDisableTopic: (id: string) => void;
  onArchiveTopic: (id: string) => void;
  onRemoveTopic: (id: string) => void;
  filters: {
    from: string;
    to: string;
    accountIds?: string[];
    productLines?: string[];
    ownerIds?: string[];
    crmFilters?: Record<string, string[]>;
  };
  showAccountColumn?: boolean;
  id?: string;
  customTitle?: string;
  topicsFetcher: (
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters & { insightType: string; meetingType?: string }>,
  ) => Promise<TopTopicsType>;
}

export const TopTopics: React.FC<TopTopicsProps> = ({
  onTopicSelected,
  onEventCountCalculated,
  filters,
  insightType,
  showAccountColumn,
  id,
  customTitle,
  onAddTopic,
  onEditTopic,
  onFollowTopic,
  onUnfollowTopic,
  onDisableTopic,
  onPinTopic,
  onArchiveTopic,
  onRemoveTopic,
  topicsFetcher,
}) => {
  const { store } = useContext(DashboardFilterContext);

  const user = useSelector((store: Store) => store.session.user);
  const isOwner = user?.membership.role === 'OWNER' || user?.membership.role === 'ADMIN';

  const [response, setResponse] = useState<TopTopicsType>();

  const [sortBy, setSortBy] = useState<string>('mentions');
  const [showAllTopics, setShowAllTopics] = useState(false);

  const { isFetching, refetch } = useQuery({
    queryKey: [
      'top-topics',
      filters.from,
      filters.to,
      filters.accountIds?.join(','),
      filters.productLines?.join(','),
      filters.ownerIds?.join(','),
      filters.crmFilters,
      sortBy,
      insightType,
      id,
    ],
    queryFn: async args => {
      const options: any = {};
      if (filters.accountIds) {
        options['accountIds'] = filters.accountIds;
      }

      if (filters.productLines) {
        options['productLines'] = filters.productLines;
      }
      if (filters.ownerIds) {
        options['ownerIds'] = filters.ownerIds;
      }

      if (insightType) {
        options['insightType'] = insightType;
      }

      if (filters.crmFilters) {
        options['crmFilters'] = filters.crmFilters;
      }

      return await topicsFetcher(filters.from, filters.to, 1, 10, sortBy, options);
    },
    onSuccess: data => {
      setResponse(data);

      if (onEventCountCalculated) {
        onEventCountCalculated(data.eventsCount);
      }
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const state = store.getState();
    if (id && !state.hasRefetchMethod(id)) {
      state.setRefetchMethod(id, refetch);
    }
  }, [refetch]);

  const totalTopics = response?.pagination.items.total || 0;

  return (
    <Flex id={id} vertical gap={'16px'} flex={1}>
      <ViewAllTopicsModal
        topicsFetcher={topicsFetcher}
        open={showAllTopics}
        onClose={() => setShowAllTopics(false)}
        onEditTopicClicked={topic => onEditTopic!(topic)}
        onTopicClicked={topic => {
          setShowAllTopics(false);
          onTopicSelected({ ...topic, type: insightType! });
        }}
        onFollowTopicClicked={onFollowTopic}
        onUnfollowTopicClicked={onUnfollowTopic}
        onPinTopic={onPinTopic}
        onDisableTopic={onDisableTopic}
        onRemoveTopic={onRemoveTopic}
        onArchiveTopic={onArchiveTopic}
        insightType={insightType}
        filters={filters}
        sortBy={sortBy}
      />

      <Card
        title={customTitle ? customTitle : `Top ${insightType ? `${insightType} ` : ''}Topics`}
        extra={
          onAddTopic && (
            <Tooltip title={!isOwner ? 'Only owners or admins can add topics.' : ''}>
              <Button onClick={() => onAddTopic(insightType!)} disabled={!isOwner}>
                <PlusOutlined />
                Add Topic
              </Button>
            </Tooltip>
          )
        }
        containerStyle={{ flex: 1 }}
      >
        <Flex vertical gap={'16px'}>
          <Space>
            <Button type="primary" onClick={() => setShowAllTopics(true)}>
              View All ({totalTopics})
            </Button>
            <Select
              value={sortBy}
              options={[
                {
                  value: 'mentions',
                  label: '# of Detections',
                },
                {
                  value: 'meetings',
                  label: '# of Meetings',
                },
                {
                  value: 'accounts',
                  label: '# of Accounts',
                },
              ]}
              onChange={value => setSortBy(value)}
            />
          </Space>
          <TopTopicsTable
            showAccountColumn={showAccountColumn}
            showTypeColumn={!insightType}
            loading={isFetching}
            data={response?.topics || []}
            onTopicClicked={topic => onTopicSelected({ ...topic, type: insightType! })}
            showAccountsTooltip={true}
            filters={filters}
            onEditTopicClicked={onEditTopic}
            onFollowTopicClicked={onFollowTopic}
            onUnfollowTopicClicked={onUnfollowTopic}
            onPinTopicClicked={onPinTopic}
            onDisableTopicClicked={onDisableTopic}
            onArchiveTopicClicked={onArchiveTopic}
            onRemoveTopicClicked={onRemoveTopic}
            canEditTopic={isOwner}
          />
        </Flex>
      </Card>
    </Flex>
  );
};
