import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SelectAll } from '../../../../../pages/insights/components/SelectAll';
import { coreService } from '../../../../../services/core/core-service';

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
  domain: string;
};

export const OwnersSelector: FC<Props> = ({ onChange, value, domain }) => {
  const { isFetching, data } = useQuery({
    queryKey: ['meeting-owners'],
    queryFn: () => coreService.getAllUsersByDomain(domain),
  });

  return (
    <SelectAll
      showAll
      style={{ maxWidth: '240px' }}
      label={'Meeting Owner'}
      value={value}
      disabled={isFetching}
      options={
        data?.map(owner => ({
          label: `${owner.firstName} ${owner.lastName}`,
          id: owner.id,
        })) ?? []
      }
      onSelectionChanged={onChange}
    />
  );
};
