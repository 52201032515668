import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, Input, InputRef, Select, Space, Tag, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { OptionalTooltipWrapper } from '../../../../../../../../../../componentsV2/OptionalTooltipWrapper';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../../../../../../../services/core/core-service';
import { useToast } from '../../../../../../../../../../hooks/useToast';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../../../../../../redux/typings/store';
import { useEventStore } from '../../../../../../stores/events';

type ProductLine = {
  id: string;
  name: string;
  color: string;
};

type Props = {
  insightId: string;
  productLines: ProductLine[];
  onProductLinesChange: (insightId: string, productLines: ProductLine[]) => void;
};

export const InsightProductLinesContainer: React.FC<Props> = ({
  insightId,
  productLines,
  onProductLinesChange,
}) => {
  const eventEmitter = useEventStore.getState();
  const { success, error } = useToast();

  const [productLinesData, setProductLinesData] = useState(productLines);
  const [isAddingProductLines, setIsAddingProductLines] = useState(productLines.length === 0);
  const [isSavingProductLines, setIsSavingProductLines] = useState<boolean>(false);
  const [newProductLineName, setNewProductLineName] = useState('');
  const [productLinesColors, setProductLinesColors] = useState<Record<string, string>>(
    productLines.reduce((acc, productLine) => {
      acc[productLine.id] = productLine.color;
      return acc;
    }, {} as Record<string, string>),
  );

  const [selectedProductLines, setSelectedProductLines] = useState<
    { label: string; value: string }[]
  >(productLines.map(productLine => ({ label: productLine.name, value: productLine.id })));

  const inputRef = useRef<InputRef>(null);

  const user = useSelector((store: Store) => store.session.user);
  const isOwner = user?.membership.role === 'OWNER' || user?.membership.role === 'ADMIN';

  const onNewProductLineNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProductLineName(event.target.value);
  };

  const { data: workspaceProductLines, refetch: refetchWorkspaceProductLines } = useQuery({
    queryKey: ['workspace-product-lines'],
    queryFn: async () => {
      const productLinesResponse = await coreService.getWorkspaceProductLines();
      return productLinesResponse;
    },
    onSuccess: data => {
      setProductLinesColors(
        data?.reduce((acc: Record<string, string>, productLine) => {
          acc[productLine.id] = productLine.color;
          return acc;
        }, {}) ?? {},
      );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const handleSaveProductLines = async () => {
    try {
      setIsSavingProductLines(true);
      const productLineIds = selectedProductLines.map(productLine => productLine.value);
      await coreService.handleInsightProductLines(insightId, productLineIds);
      setIsAddingProductLines(false);
      setProductLinesData(
        workspaceProductLines!.filter(productLine => productLineIds.includes(productLine.id)),
      );
      onProductLinesChange(
        insightId,
        workspaceProductLines!.filter(productLine => productLineIds.includes(productLine.id)),
      );
      eventEmitter.emit('insight-product-lines-updated', {
        insightId,
        productLines: selectedProductLines,
      });
      success('Product lines saved successfully.');
    } catch (err) {
      error('There was an error saving topic product lines.');
    } finally {
      setIsSavingProductLines(false);
    }
  };

  const onSaveNewProductLine = async () => {
    setIsSavingProductLines(true);
    await coreService.createWorkspaceProductLine({
      name: newProductLineName,
      description: '',
      color: 'green',
    });

    const newProductLine = await refetchWorkspaceProductLines().then(response =>
      response.data?.find(productLine => productLine.name === newProductLineName),
    );

    if (newProductLine) {
      await coreService.handleInsightProductLines(insightId, [
        ...productLinesData.map(productLine => productLine.id),
        newProductLine.id,
      ]);
      setProductLinesData(prev => [...prev, newProductLine]);
      setProductLinesColors(prev => ({ ...prev, [newProductLine.id]: newProductLine.color }));
      setSelectedProductLines(prev => [
        ...prev,
        { label: newProductLine.name, value: newProductLine.id },
      ]);
      await refetchWorkspaceProductLines();
    }

    setNewProductLineName('');
    setIsSavingProductLines(false);
  };

  return (
    <div>
      <Flex style={{ maxWidth: '300px', flexWrap: 'wrap' }} gap="8px 0">
        {!isAddingProductLines && (
          <>
            {productLinesData &&
              productLinesData.map(productLine => (
                <Tag style={{ cursor: 'default' }} color={productLine.color} key={productLine.id}>
                  {productLine.name}
                </Tag>
              ))}

            <Tag
              color="default"
              onClick={() => setIsAddingProductLines(true)}
              style={{ cursor: 'pointer' }}
            >
              {productLinesData && productLinesData.length > 0 ? 'Change' : 'Add'} Product Lines
            </Tag>
          </>
        )}

        {isAddingProductLines && (
          <Flex gap={8}>
            <Select
              style={{ minWidth: 200 }}
              placeholder="Select product lines"
              mode="multiple"
              labelInValue
              tagRender={({ label, value, onClose }) => (
                <Tag
                  color={productLinesColors[value]}
                  closable={true}
                  onClose={onClose}
                  style={{ marginInlineEnd: 4 }}
                >
                  {label}
                </Tag>
              )}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Flex vertical gap={8}>
                      <Input
                        placeholder="Product Line name"
                        ref={inputRef}
                        value={newProductLineName}
                        onChange={onNewProductLineNameChange}
                        onKeyDown={e => e.stopPropagation()}
                        disabled={isSavingProductLines || !isOwner}
                      />
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={onSaveNewProductLine}
                        loading={isSavingProductLines}
                        disabled={!newProductLineName}
                      >
                        <OptionalTooltipWrapper
                          value="Only owners or admins can edit topics."
                          display={!isOwner}
                        >
                          Add Product Line
                        </OptionalTooltipWrapper>
                      </Button>
                    </Flex>
                  </Space>
                </>
              )}
              value={selectedProductLines}
              onChange={setSelectedProductLines}
              disabled={isSavingProductLines}
              options={
                workspaceProductLines?.map(productLine => ({
                  label: productLine.name,
                  value: productLine.id,
                  title: productLine.color,
                })) ?? []
              }
            />

            <Button onClick={handleSaveProductLines} type="primary" loading={isSavingProductLines}>
              Save
            </Button>
          </Flex>
        )}
      </Flex>
    </div>
  );
};
