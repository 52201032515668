import { Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';

type Props = {
  text: string;
  multiline?: boolean;
  maxLines?: number;
  customTooltipRender?: (text: string) => React.ReactNode;
};

export const EllipsisWithTooltip: React.FC<Props> = ({
  text,
  multiline,
  maxLines = 2,
  customTooltipRender,
}) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const element = textRef.current;

    if (element) {
      const isTruncated = multiline
        ? element.scrollHeight > element.clientHeight
        : element.scrollWidth > element.clientWidth;
      element.setAttribute('data-truncated', isTruncated ? 'true' : 'false');
      setIsTruncated(isTruncated);
    }
  }, []);

  return (
    <Tooltip
      title={customTooltipRender ? customTooltipRender(text) : text}
      placement="top"
      overlayStyle={{ maxWidth: '500px' }}
      open={
        isTruncated && textRef.current?.getAttribute('data-truncated') === 'true'
          ? undefined
          : false
      }
    >
      <div
        ref={textRef}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          ...(multiline
            ? {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: maxLines,
                whiteSpace: 'break-spaces',
              }
            : {
                display: 'block',
                whiteSpace: 'nowrap',
              }),
        }}
      >
        {text}
      </div>
    </Tooltip>
  );
};
