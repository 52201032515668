import styled from 'styled-components';

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Primary-200, #ffdad4);
  color: var(--Primary-700, #bf513f);
  text-align: center;
  font-family: 'Cera Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
`;

const StakeholderTitle = styled.h1<{ titleWidth: string }>`
  width: ${({ titleWidth }) => titleWidth};
  color: var(--Gray-950, #282828);
  font-family: 'Cera Pro';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 93.75% */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const StakeholderSubtitle = styled.h1<{ titleWidth: string }>`
  width: ${({ titleWidth }) => titleWidth};
  color: var(--Gray-700, #6a6a6a);
  font-family: 'Cera Pro';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const Title = styled.div<{ titleWidth: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: end;
  height: 100%;
  gap: 5px;
`;

export default { Avatar, Title, StakeholderTitle, StakeholderSubtitle };
