import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { coreService } from '../../../../../../services/core/core-service';
import { ViewAllModal } from '../../../AllTopicsModal';
import { Flex, Pagination, Select } from 'antd';
import { TopAccountRecord, TopAccountsTable } from '../TopAccountsTable';
import { AccountTopicClickHandler, SelectedTopic } from '../../types';

interface Props {
  open: boolean;
  filters: {
    from: string;
    to: string;
    accountIds?: string[];
    crmFilters?: Record<string, string[]>;
  };
  onTopicClicked: AccountTopicClickHandler;
  onAccountClicked: (accountId: string) => unknown;
  sortBy: string;
  onClose: () => unknown;
}

export const ViewAllAccountsModal: React.FC<Props> = ({
  filters,
  onTopicClicked,
  onAccountClicked,
  sortBy,
  open,
  onClose,
}) => {
  const [selectedSortBy, setSelectedSortBy] = useState(sortBy);
  const [page, setSelectedPage] = useState(1);
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const { isFetching, data } = useQuery({
    queryKey: ['view-all-accounts-modal', filters.from, filters.to, page, selectedSortBy, isOpen],
    queryFn: async () => {
      if (!isOpen) {
        return null;
      }

      const options: Record<string, any> = {};
      if (filters.accountIds) {
        options['accountIds'] = filters.accountIds;
      }

      if (filters.crmFilters) {
        options['crmFilters'] = filters.crmFilters;
      }

      return coreService.getTopAccounts(
        filters.from,
        filters.to,
        page,
        10,
        selectedSortBy,
        options,
      );
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const records: TopAccountRecord[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.accounts.map(account => {
      return {
        account: {
          id: account.id,
          name: account.name || '',
          provider: account.provider || '',
        },
        mentions: account.mentions,
        meetings: account.meetings,
        topRisk: account.topTopics.find(tt => tt.type === 'Risks'),
        topProductFeedback: account.topTopics.find(tt => tt.type === 'Product Feedback'),
        topAdvocacy: account.topTopics.find(tt => tt.type === 'Advocacy'),
        topGrowthOpportunities: account.topTopics.find(tt => tt.type === 'Growth Opportunities'),
      };
    });
  }, [data]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleTopicClicked = (
    topic: { id: string; name: string; description: string },
    accountId: string,
  ) => {
    handleClose();
    onTopicClicked(topic, accountId);
  };

  const handleSortByChanged = (sortBy: string) => {
    setSelectedSortBy(sortBy);
    setSelectedPage(1);
  };

  return (
    <ViewAllModal open={isOpen} label="View All Accounts" onClose={handleClose}>
      <Flex vertical gap={'16px'}>
        <Flex>
          <Select
            disabled={isFetching}
            value={selectedSortBy}
            onChange={value => handleSortByChanged(value)}
            options={[
              { value: 'mentions', label: '# of Detections' },
              { value: 'meetings', label: '# of Meetings' },
            ]}
          />
        </Flex>
        <TopAccountsTable
          onAccountClicked={onAccountClicked}
          loading={isFetching}
          data={records || []}
          onTopicClicked={(topic, account) => handleTopicClicked(topic, account)}
        />
        <Flex justify="flex-end">
          {data && (
            <Pagination
              onChange={val => setSelectedPage(val)}
              current={page}
              pageSize={10}
              total={data.pagination.items.total}
            />
          )}
        </Flex>
      </Flex>
    </ViewAllModal>
  );
};
