import React, { useEffect, useRef, useState } from 'react';
import { Divider } from 'antd';
import { Container } from './style';
import { InviteEmailModal } from './components/InviteEmailModal';
import { MeetingVisibilitySection } from './components/MeetingsVisibilitySection';
import { MyOrganizationSection } from './components/MyOrganizationSection';
import { MembersSection } from './components/MembersSection';
import { InvitationsSection } from './components/InvitationsSection';
import { CRMSetupSection } from './components/CRMSetupSection';
import { DNR } from './components/DNR';
import { CustomInsightCategorySection } from './components/CustomInsightCategoriesSection';
import { SlackSection } from './components/SlackSection';
import { WorkspaceContext } from './components/WorkspaceContext';
import { TopicsSection } from './components/TopicsSection';

interface Props {
  disableUserInteractions?: boolean;
  showInviteModal?: boolean;
}

const Organizations: React.FC<Props> = ({ disableUserInteractions, showInviteModal }) => {
  const [showModal, setShowModal] = useState(showInviteModal);
  const crmRef = useRef<HTMLDivElement>(null);

  const handleAddNewMember = () => {
    setShowModal(true);
  };

  const handleInvitationsConfirmed = () => setShowModal(false);
  const handleInvitationsCanceled = () => setShowModal(false);

  useEffect(() => {
    if (window.location.hash === '#CRM-SETUP-ID') {
      document.getElementById('CRM-SETUP-ID')?.scrollIntoView();
    }
  }, []);

  return (
    <Container>
      <InviteEmailModal
        shouldShow={showModal}
        onConfirm={handleInvitationsConfirmed}
        onCancel={handleInvitationsCanceled}
      />

      <MyOrganizationSection />

      <Divider />

      <WorkspaceContext />

      <Divider />

      <CustomInsightCategorySection />

      <Divider />

      <TopicsSection />

      <Divider />

      <SlackSection />

      <Divider />

      <MeetingVisibilitySection />

      <div id="CRM-SETUP-ID">
        <CRMSetupSection ref={crmRef} />
      </div>
      <Divider />

      <DNR />
      <Divider />

      <MembersSection onAddNewMember={handleAddNewMember} />

      <Divider />

      <InvitationsSection />
    </Container>
  );
};

export default Organizations;
