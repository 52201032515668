import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { coreService } from '../../../../../../services/core/core-service';
import { ViewAllModal } from '../../../AllTopicsModal';
import { Flex, Pagination, Select } from 'antd';
import { TopTopicsTable } from '../TopTopicsTable';
import { Alert } from '../../../../../../components/alerts/Alert';
import { DatabaseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../../redux/typings/store';
import { InsightsFilters, TopTopicsType } from '../../../../../../services/types';

interface Props {
  open: boolean;
  insightType?: string;
  filters: {
    from: string;
    to: string;
    accountId?: string;
    productLines?: string[];
    crmFilters?: Record<string, string[]>;
  };
  onTopicClicked: (args: {
    id: string;
    name: string;
    description: string;
    custom: boolean;
    following: boolean;
  }) => unknown;
  onEditTopicClicked: (args: {
    id: string;
    name: string;
    description: string;
    custom: boolean;
    following: boolean;
    type: string;
  }) => unknown;
  onFollowTopicClicked: (id: string) => void;
  onUnfollowTopicClicked: (id: string) => void;
  onRemoveTopic: (id: string) => void;
  onArchiveTopic: (id: string) => void;
  sortBy: string;
  onClose: () => unknown;
  onPinTopic: (id: string) => void;
  onDisableTopic: (id: string) => void;
  topicsFetcher: (
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters & { insightType: string; meetingType?: string }>,
  ) => Promise<TopTopicsType>;
}

export const ViewAllTopicsModal: React.FC<Props> = ({
  filters,
  onTopicClicked,
  onEditTopicClicked,
  onFollowTopicClicked,
  onUnfollowTopicClicked,
  onDisableTopic,
  onPinTopic,
  onRemoveTopic,
  onArchiveTopic,
  sortBy,
  open,
  onClose,
  insightType,
  topicsFetcher,
}) => {
  const [selectedSortBy, setSelectedSortBy] = useState(sortBy);
  const [page, setSelectedPage] = useState(1);
  const [isOpen, setIsOpen] = useState(open);

  const user = useSelector((store: Store) => store.session.user);
  const isOwner = user?.membership.role === 'OWNER' || user?.membership.role === 'ADMIN';

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const { data, isFetching } = useQuery({
    queryKey: [
      'top-topics',
      filters.from,
      filters.to,
      page,
      filters.accountId,
      selectedSortBy,
      insightType,
      isOpen,
    ],
    queryFn: async args => {
      if (!isOpen) {
        return null;
      }

      const options: any = {};
      if (filters.accountId) {
        options['accountIds'] = [filters.accountId];
      }

      if (filters.productLines) {
        options['productLines'] = filters.productLines;
      }

      if (filters.crmFilters) {
        options['crmFilters'] = filters.crmFilters;
      }

      if (insightType) {
        options['insightType'] = insightType;
      }

      return await topicsFetcher(filters.from, filters.to, page, 10, sortBy, options);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleSortByChanged = (sortBy: string) => {
    setSelectedSortBy(sortBy);
    setSelectedPage(1);
  };
  return (
    <ViewAllModal
      open={isOpen}
      label="View All Topics"
      onClose={handleClose}
      headerExtraContent={
        <Alert
          customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
          message={
            !data && isFetching
              ? 'Loading information.'
              : `Insights come from ${data?.eventsCount} meeting recaps based on your applied filters and meeting access.`
          }
          variant="info"
          title=""
        />
      }
    >
      <Flex vertical gap={'16px'}>
        <Flex>
          <Select
            disabled={isFetching}
            value={selectedSortBy}
            onChange={value => handleSortByChanged(value)}
            options={[
              { value: 'mentions', label: '# of Detections' },
              { value: 'meetings', label: '# of Meetings' },
              { value: 'accounts', label: '# of Accounts' },
            ]}
          />
        </Flex>
        <TopTopicsTable
          loading={isFetching}
          data={data?.topics || []}
          onTopicClicked={onTopicClicked}
          canEditTopic={isOwner}
          onEditTopicClicked={onEditTopicClicked}
          onFollowTopicClicked={onFollowTopicClicked}
          onUnfollowTopicClicked={onUnfollowTopicClicked}
          onDisableTopicClicked={onDisableTopic}
          onPinTopicClicked={onPinTopic}
          onRemoveTopicClicked={onRemoveTopic}
          onArchiveTopicClicked={onArchiveTopic}
        />
        <Flex justify="flex-end">
          {data && (
            <Pagination
              onChange={page => setSelectedPage(page)}
              current={page}
              total={data.pagination.items.total}
            />
          )}
        </Flex>
      </Flex>
    </ViewAllModal>
  );
};
