import React, { useEffect, useState } from 'react';
import Styles from './styles';
import { FolderItem as FolderItemComponent } from '../FolderItem';
import { Trash } from '../../../../features/shared/components/Icons/Trash';
import {
  SelectableFolderItem,
  useLibraries,
} from '../../../../features/libraries/hooks/useLibraries';
import { NoFolderItems } from '../NoFolderItems';
import { Flex, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

const {
  Container,
  Title,
  ItemsContainer,
  SelectedItemsActions,
  SelectedItemsCount,
  DeleteLabel,
  DeleteAction,
  FullHeihgtContainer,
} = Styles;

interface Props {
  title: string;
}

export const FolderContents: React.FC<Props> = ({ title }) => {
  const [folderItems, setFolderItems] = useState<SelectableFolderItem[]>([]);
  const {
    removeItem,
    itemMapper,
    isFetchingItems,
    selectedFolderId,
    selectedLibraryId,
    folder,
    recentItems,
  } = useLibraries();

  const navigate = useNavigate();

  useEffect(() => {
    if (folder && selectedLibraryId && selectedFolderId) {
      setFolderItems(itemMapper(folder.items || [], selectedLibraryId, selectedFolderId));
    } else {
      setFolderItems(itemMapper(recentItems));
    }
  }, [folder, recentItems]);

  const handleItemSelectionChange = (itemId: string, selected: boolean) => {
    const newItems = [...folderItems].map(item => {
      if (item.id === itemId) {
        item.isSelected = selected;
      }
      return item;
    });
    setFolderItems(newItems);
  };

  const itemsSelectedCount = folderItems.filter(item => item.isSelected).length;

  return (
    <>
      {itemsSelectedCount > 0 && (
        <SelectedItemsActions>
          <SelectedItemsCount>{itemsSelectedCount} item/s selected</SelectedItemsCount>
          <DeleteAction>
            <Trash />
            <DeleteLabel>Remove</DeleteLabel>
          </DeleteAction>
        </SelectedItemsActions>
      )}
      <Container>
        <Title>{title}</Title>

        <ItemsContainer>
          {folderItems.length === 0 && !isFetchingItems && (
            <Flex justify="center" align="center" style={{ height: '100%' }}>
              <NoFolderItems />
            </Flex>
          )}
          {isFetchingItems ? (
            <FullHeihgtContainer>
              <Spin />
            </FullHeihgtContainer>
          ) : (
            folderItems.map(item => (
              <FolderItemComponent
                name={item.name}
                type={item.category}
                onClick={() => {
                  const url =
                    item.type === 'recap'
                      ? `/recap/${item.itemId}`
                      : `/recap/${item.eventId}?insightId=${item.itemId}`;
                  navigate(url);
                }}
                itemId={item.itemId}
                eventId={item.eventId}
                folderName={item.folder.name}
                starredBy={item.starredBy}
                thumbnail={item.thumbnail}
                daysSinceMeeting={item.daysSinceMeeting}
                duration={item.duration}
                onRemove={() => removeItem(item.libraryId, item.folderId, item.itemId)}
                checked={item.isSelected}
                onCheckChange={value => handleItemSelectionChange(item.id, value)}
                key={`${item.id}-${item.name}`}
              />
            ))
          )}
        </ItemsContainer>
      </Container>
    </>
  );
};
