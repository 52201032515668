import React, { useState } from 'react';
import Style from './style';
import Amex from './logos/amex.png';
import Apple from './logos/applePay.png';
import Discovery from './logos/discover.png';
import Google from './logos/googlePay.png';
import JCB from './logos/jcb.png';
import Mastercard from './logos/mastercard.png';
import Union from './logos/unionPay.png';
import Visa from './logos/visa.png';
import { Button, Flex } from 'antd';
import { twoDigitNumber } from '@updateai/ui/dist/utils/timeUtils';
import { coreAPI } from '../../../../../API/core';
import { capitalize } from 'lodash';

interface Props {
  provider: string;
  last4: string;
  expiration: string;
  isCanceled: boolean;
}

const brandToLogo: Record<string, string> = {
  amex: Amex,
  apple: Apple,
  discover: Discovery,
  google: Google,
  jcb: JCB,
  mastercard: Mastercard,
  unionpay: Union,
  visa: Visa,
};

const CardLogo: React.FC<{ provider: string }> = ({ provider }) => {
  const logo = brandToLogo[provider];
  return <img style={{ height: '34px' }} src={logo} />;
};

export const CardHolder: React.FC<Props> = ({ provider, last4, expiration, isCanceled }) => {
  const { Number, Expiration } = Style;

  const [loading, setLoading] = useState(false);

  const edit = async () => {
    const api = new coreAPI();
    setLoading(true);

    try {
      const url: string = await api.editPaymentMethod();
      if (url) window.open(url);
    } finally {
      setLoading(false);
    }
  };

  const expirationComponents = expiration.split('/');
  const expirationMonth = twoDigitNumber(expirationComponents[0]);
  const expirationYear = expirationComponents[1];
  const expirationDate = `${expirationMonth}/${expirationYear}`;

  return (
    <Flex
      gap={'12px'}
      align="center"
      justify="space-between"
      style={{
        padding: '16px',
        background: '#f8f8f8',
        borderRadius: '6px',
        height: '66px',
        width: '100%',
      }}
    >
      <CardLogo provider={provider} />
      <Flex vertical align="center" justify="flex-start" flex={1}>
        <Number>
          {capitalize(provider)}
          <p style={{ margin: 0, fontSize: '24px' }}>{' ···· ···· ···· '}</p>
          {last4}
        </Number>

        <Expiration>Expiry date {expirationDate}</Expiration>
      </Flex>

      <Button
        children="Edit"
        style={{ width: '94px' }}
        disabled={isCanceled}
        onClick={() => edit()}
        loading={loading}
      />
    </Flex>
  );
};
