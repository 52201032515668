import { Button, Flex, Input, Modal, Select, SelectProps, Space, Tag, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import {
  Content,
  EmailInvite,
  EmailText,
  Footer,
  LabelText,
  NewMemberText,
  SelectWrapper,
  TitleContainer,
} from './style';
import { useToast } from '../../../../../../hooks/useToast';
import IconSvg from '../../../../../../components/common/IconSvg';
import { inviteUsers } from '../../../../../../features/organizations/organizations.service';
import { DatabaseOutlined, EditOutlined } from '@ant-design/icons';

const options: SelectProps['options'] = [];

interface Props {
  shouldShow?: boolean;
  onConfirm: () => unknown;
  onCancel: () => unknown;
}

type UserInvite = {
  email: string;
  type: string;
  role: string;
};

export const InviteEmailModal: React.FC<Props> = ({ shouldShow, onConfirm, onCancel }) => {
  const [invitationsToSend, setInvitationsToSend] = useState<UserInvite[]>([]);
  const [loading, setLoading] = useState(false);
  const { success, error } = useToast();
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [inviteToEdit, setInviteToEdit] = useState<UserInvite | null>(null);

  const handleChange = (values: string[]) => {
    setInputValues(values);
    if (values.length > 0) {
      setInvitationsToSend(
        values.map(email => ({
          email,
          type: 'PAID',
          role: 'MEMBER',
        })),
      );

      setInviteToEdit({
        email: values[values.length - 1],
        type: 'PAID',
        role: 'MEMBER',
      });
    } else {
      if (inviteToEdit) {
        setInviteToEdit(null);
      }
    }
  };

  const ModalTitle = (
    <TitleContainer>
      <IconSvg name="addNewMemberBlack" size="md" color="transparent" />
      <NewMemberText>Invite new member</NewMemberText>
    </TitleContainer>
  );

  const handleSendInvitations = async () => {
    if (invitationsToSend.length > 0) {
      setLoading(true);
      try {
        await inviteUsers(invitationsToSend);
        success(`Invitation${invitationsToSend.length > 1 ? 's' : ''} sent successfully`);
      } catch (e) {
        error((e as Error).message);
      }
      setLoading(false);
      setInvitationsToSend([]);
      onConfirm();
    }
  };

  const editUserInvite = (email: string) => {
    setInviteToEdit(invitationsToSend.find(invite => invite.email === email)!);
  };

  return (
    <Modal title={ModalTitle} open={shouldShow} closable={false} destroyOnClose footer={false}>
      <Content>
        <EmailInvite>
          <EmailText>Email</EmailText>
          <SelectWrapper>
            <div className="prefix-icon-wrapper">
              <IconSvg name="email" size="md" color="transparent" />
            </div>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              tagRender={({ label, closable, onClose }) => (
                <Tag closable={closable} onClose={onClose}>
                  <Tooltip
                    title={(() => {
                      const user = invitationsToSend.find(invite => invite.email === label);
                      return user ? (
                        <Flex vertical>
                          <div>{user.email}</div>
                          <div>
                            Role:{' '}
                            <span style={{ textTransform: 'capitalize' }}>
                              {user.role.toLowerCase()}
                            </span>
                          </div>
                          <div>
                            Type:{' '}
                            <span style={{ textTransform: 'capitalize' }}>
                              {user.type.toLowerCase()}
                            </span>
                          </div>
                        </Flex>
                      ) : null;
                    })()}
                  >
                    {label}
                  </Tooltip>
                  <span
                    style={{ marginLeft: 8, cursor: 'pointer' }}
                    onClick={() => editUserInvite(label!.toString())}
                  >
                    <EditOutlined style={{ color: 'grey' }} />
                  </span>
                </Tag>
              )}
              placeholder={
                <div style={{ paddingLeft: '32px' }}>
                  Separate multiple e-mails by pressing Enter↵
                </div>
              }
              onChange={handleChange}
              options={options}
              showArrow={false}
              open={false}
              autoFocus
            />
          </SelectWrapper>
          <LabelText>We’ll email them instructions and a link to join the workspace.</LabelText>
        </EmailInvite>

        {inviteToEdit && (
          <div
            style={{
              backgroundColor: '#f5f5f5',
              padding: '16px 24px',
              marginLeft: '-24px',
              width: 'calc(100%)',
            }}
          >
            <Space style={{ fontSize: '18px', fontWeight: 500, marginBottom: '16px' }}>
              <EditOutlined />
              Edit Member Permissions
            </Space>
            <Flex vertical gap={8}>
              <EmailInvite style={{ width: '75%' }}>
                <EmailText>Email</EmailText>
                <Input disabled value={inviteToEdit.email} />
              </EmailInvite>

              <Flex align="end" gap={8} style={{ width: '100%' }}>
                <EmailInvite style={{ width: 'auto', flexGrow: 1 }}>
                  <EmailText>User Type</EmailText>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Select a user type"
                    value={inviteToEdit.type}
                    onSelect={value => {
                      setInviteToEdit({ ...inviteToEdit, type: value });
                    }}
                    options={[
                      { label: 'Paid', value: 'PAID' },
                      { label: 'Free', value: 'FREE' },
                    ]}
                  />
                </EmailInvite>

                <EmailInvite style={{ width: 'auto', flexGrow: 1 }}>
                  <EmailText>Role</EmailText>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Select a role"
                    value={inviteToEdit.role}
                    onChange={value => {
                      setInviteToEdit({ ...inviteToEdit, role: value });
                    }}
                    options={[
                      { label: 'Admin', value: 'ADMIN' },
                      { label: 'Member', value: 'MEMBER' },
                    ]}
                  />
                </EmailInvite>

                <Flex gap={8}>
                  <Button
                    type="default"
                    onClick={() => {
                      setInviteToEdit(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setInvitationsToSend(
                        invitationsToSend.map(invitation =>
                          invitation.email === inviteToEdit.email ? inviteToEdit : invitation,
                        ),
                      );
                      success('Invitation updated successfully');
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </div>
        )}

        <Footer>
          <Button
            type="default"
            onClick={() => {
              setInviteToEdit(null);
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              handleSendInvitations();
            }}
            loading={loading}
            disabled={inputValues.length === 0}
          >
            Invite new member{invitationsToSend.length > 1 ? 's' : ''}
          </Button>
        </Footer>
      </Content>
    </Modal>
  );
};
