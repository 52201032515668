import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { isValid } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  pastOnly?: boolean;
  futureOnly?: boolean;
  className?: string;
  handleCalendarChange: (range: [Date, Date] | [null, null]) => void;
  defaultFrom?: Date | null;
  defaultTo?: Date | null;
  valueFrom?: Date | null;
  valueTo?: Date | null;
  bordered?: boolean;
  style?: React.CSSProperties;
}

const { RangePicker } = DatePicker;

export const DateSelect: React.FC<Props> = ({
  pastOnly = false,
  futureOnly = false,
  handleCalendarChange,
  defaultFrom,
  className,
  defaultTo,
  bordered,
  valueFrom,
  valueTo,
  style,
}) => {
  const DAY_MILISECONDS = 86400000;
  const [calendarValues, setCalendarValues] = useState<[Dayjs | null, Dayjs | null]>([null, null]);

  useEffect(() => {
    const newValueFrom = valueFrom instanceof Date ? dayjs(valueFrom) : null;
    const newValueTo = valueTo instanceof Date ? dayjs(valueTo) : null;
    setCalendarValues([newValueFrom, newValueTo]);
  }, [valueFrom, valueTo]);

  return (
    <RangePicker
      bordered={bordered}
      style={style}
      className={className}
      value={calendarValues}
      disabledDate={d =>
        !d ||
        (pastOnly && d.isAfter(Date.now() - DAY_MILISECONDS)) ||
        (futureOnly && d.isBefore(Date.now() - DAY_MILISECONDS))
      }
      // eslint-disable-next-line
      onCalendarChange={(e: any) => {
        if (e?.length === 2) {
          const start = new Date(new Date(e[0]?.$d).setUTCHours(0, 0, 0, 0));
          const end = new Date(new Date(e[1]?.$d).setUTCHours(23, 59, 59, 999));
          if (isValid(start) && isValid(end)) handleCalendarChange([start, end]);
        }
      }}
      defaultValue={defaultFrom && defaultTo ? [dayjs(defaultFrom), dayjs(defaultTo)] : undefined}
    />
  );
};
