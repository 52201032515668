import React from 'react';
import { ButtonContainer, Container } from './styles';
import { CopyAll } from '../CopyAll/CopyAll';
import { ButtonTabPane } from '../../../shared/components/ButtonTabPane/ButtonTabPane';
import { Copy } from '../../../shared/components/Icons/Copy';
import { Button, Space } from 'antd';
import { useCopySummary, useCopySummaryPublicly } from '../../../../hooks/useRecapCopy';
import { useAskMeAnything } from '../../../askMeAnything/hooks/useAskMeAnything';
import { AskMeAnything } from '../../../shared/components/AskMeAnything';
import { ChatItem } from '../../../../services/types';
import { useSummarizeInsights } from '../../../insights/summarize-insights/summarize-insights.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';
import useFeatures from '../../../../hooks/useFeatures';
import { SubscribeToProPlanLink } from '../../../../componentsV2/UpgradeToPro';

interface Props {
  onSelect: (key: string) => void;
  selected: string;
}

export const MeetingInfoSelector: React.FC<Props> = ({ onSelect, selected }) => {
  const { askRecap } = useAskMeAnything();
  const {
    id,
    title,
    host,
    scheduledStartDate,
    participantsType,
    isDemo,
    isPublic,
    isBasic,
    account,
  } = useRecapManagement();
  const { summarize, insights, insightsCount } = useSummarizeInsights();

  const copySummaryPublic = useCopySummaryPublicly();
  const copySummaryInternal = useCopySummary();

  const features = useFeatures();
  const availableFeature = features.shareWizard;

  const items = [
    {
      key: 'smart-summary',
      label: 'Smart Summary™',
    },
  ];

  if (!isPublic) {
    items.push({
      key: 'meeting-details',
      label: 'Meeting Analytics',
    });
    items.push({
      key: 'frameworks',
      label: 'Insight Frameworks',
    });
  }

  const shareText =
    insightsCount === 0 ? 'Share Wizard' : `Share Wizard (${insightsCount}/7 insights)`;

  return (
    <div id="appcues-recap-demo-step-3" style={{ width: '100%' }}>
      <Container>
        <ButtonContainer>
          <ButtonTabPane
            selectedKey={selected}
            onKeyChanged={(key: string) => {
              onSelect(key);
            }}
            items={items}
          />
        </ButtonContainer>

        {!isPublic ? (
          <OptionalTooltipWrapper
            display={!insights.length || !availableFeature}
            value={
              !insights.length ? (
                'Select one or more insights to create and share a Smart Summary™️'
              ) : (
                <SubscribeToProPlanLink />
              )
            }
          >
            <Button
              type="default"
              disabled={!insights.length || !availableFeature}
              onClick={() =>
                summarize({
                  title,
                  host,
                  account: account ? account.name : 'No account',
                  date: scheduledStartDate ? scheduledStartDate.toISOString() : '',
                })
              }
            >
              {' '}
              <Space>
                <FontAwesomeIcon icon={faMagicWandSparkles} color="#7230FF" />
                {shareText}
              </Space>
            </Button>
          </OptionalTooltipWrapper>
        ) : (
          <></>
        )}

        <CopyAll onCopyInternal={copySummaryInternal} onCopyPublic={copySummaryPublic}>
          <Button icon={<Copy />}>Copy Options</Button>
        </CopyAll>

        <AskMeAnything
          location="recap"
          askFunction={(chatMessages: ChatItem[]) => askRecap(id, chatMessages)}
          disabled={isPublic || isBasic || isDemo}
        />
      </Container>
    </div>
  );
};
