import { useEffect, useRef, useState } from 'react';
import { Table } from '../../../../../../componentsV2/Table';
import { AccountLabel } from '../../../../../../features/shared/components/AccountLabel';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { MentionsContainer } from '../MentionsContainer';
import { coreService } from '../../../../../../services/core/core-service';
import { useQuery } from '@tanstack/react-query';
import { TopMentionsType } from '../../../../../../services/types';
import { useToast } from '../../../../../../hooks/useToast';
import { selectInsightItem } from '../../../../../libraries/libraries.service';

export type TopAccountsItemType = {
  id: string;
  name: string;
  mentions: number;
};

export interface TopAccountRecord {
  account: {
    id: string;
    name: string;
    provider: string;
  };
  meetings: number;
  mentions: number;
  arr: number | null;
  renewalDate: Date | null;
}

export interface Props {
  sortBy: string;
  topic: {
    id: string;
    name: string;
  };
  filters: {
    from: string;
    to: string;
    accountIds?: string[];
  };
  elems?: TopAccountRecord[];
  loading?: boolean;
}

const sorterFunction =
  (key: keyof TopAccountRecord) =>
  (first: TopAccountRecord, second: TopAccountRecord): number => {
    if (first[key] === null || second[key] === null) {
      return 0;
    }
    return Number(first[key]) - Number(second[key]);
  };

export const TopAccountsCondensedTable: React.FC<Props> = ({
  elems: data,
  topic,
  sortBy,
  loading = false,
  filters,
}) => {
  const [accountsData, setAccountsData] = useState<TopAccountRecord[]>(data || []);
  const { success, error } = useToast();

  const [expandRow, setExpandRow] = useState<string[]>([]);
  const [mentionsByAccount, setMentionsByAccount] = useState<Record<string, TopMentionsType>>({});
  const isAllExpanded = expandRow.length === accountsData?.length;

  const [requestAccountIds, setRequestAccountIds] = useState<string[]>([]);

  const { refetch, isFetching } = useQuery({
    enabled: false,
    queryKey: [
      'topic-modal-topics',
      filters.from,
      filters.to,
      filters.accountIds,
      topic.id,
      requestAccountIds,
    ],
    queryFn: async () => {
      return await coreService.getTopMentionsByTopicId(
        topic.id,
        filters.from,
        filters.to,
        1,
        10,
        sortBy,
        {
          accountIds: requestAccountIds,
        },
      );
    },
    onSuccess: (data: TopMentionsType) => {
      const result = data.mentions.reduce((acc, curr) => {
        if (!acc[curr.account.id]) {
          acc[curr.account.id] = {
            mentions: [],
            pagination: data.pagination,
          };
        }

        acc[curr.account.id].mentions.push(
          ...data.mentions.filter(m => m.account.id === curr.account.id),
        );

        return acc;
      }, {} as Record<string, TopMentionsType>);

      setMentionsByAccount(result);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    setAccountsData(data || []);
    requestData(data?.map(d => d.account.id) || []);
    setExpandRow(data?.map(d => d.account.id) || []);

    return () => {
      setExpandRow([]);
    };
  }, [data]);

  const requestData = (ids: string[]) => {
    setRequestAccountIds(ids);
  };

  useEffect(() => {
    if (requestAccountIds.length > 0) {
      refetch();
    }
  }, [requestAccountIds]);

  const updateDetectionsCount = (id: string) => {
    const [account] = Object.entries(mentionsByAccount)
      .filter(([key, value]) => value.mentions.some(aa => aa.mentions.some(m => m.id === id)))
      .map(([key]) => key);

    if (account) {
      setAccountsData(prev =>
        prev.map(currentAccount => ({
          ...currentAccount,
          mentions:
            currentAccount.account.id === account
              ? currentAccount.mentions > 0
                ? currentAccount.mentions - 1
                : 0
              : currentAccount.mentions,
        })),
      );
    }
  };

  const removeInsight = async (id: string) => {
    try {
      await coreService.removeInsight(id);
      refetch();
      updateDetectionsCount(id);
      success('Insight removed successfully');
    } catch (e) {
      error('Error removing insight');
    }
  };

  const excludeInsight = async (id: string) => {
    try {
      await coreService.excludeInsightFromEngine(id);
      refetch();
      updateDetectionsCount(id);
      success('Insight excluded successfully');
    } catch (e) {
      error('Error excluding insight');
    }
  };

  const favoriteInsight = async (id: string) => {
    try {
      selectInsightItem(id, 'insight', refetch);
    } catch (e) {
      error('Error favoriting insight');
    }
  };

  return (
    <Table
      pagination={false}
      loading={loading}
      rowKey={row => row.account.id}
      columns={[
        {
          title: 'Account',
          key: 'accounts',
          dataIndex: 'account',
          render: value => <AccountLabel account={value} />,
        },
        {
          title: '# of Detections',
          key: 'mentions',
          dataIndex: 'mentions',
          render: value => `${value}`,
          sorter: sorterFunction('mentions'),
        },
        {
          title: '# of Meetings',
          key: 'meetings',
          dataIndex: 'meetings',
          render: value => `${value}`,
          sorter: sorterFunction('mentions'),
        },
      ]}
      data={accountsData || []}
      props={{
        expandable: {
          columnTitle: () => (
            <Tooltip
              title={isAllExpanded ? 'Collapse All' : 'Expand all'}
              key={isAllExpanded ? 'collapse' : 'expand'}
            >
              <Button
                icon={isAllExpanded ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                onClick={() => {
                  if (isAllExpanded) {
                    setExpandRow([]);
                  } else {
                    setExpandRow(data?.map(d => d.account.id) || []);
                    const dataToRequest = data?.filter(
                      d => mentionsByAccount[d.account.id] === undefined,
                    );
                    if (dataToRequest) {
                      requestData(dataToRequest.map(d => d.account.id));
                    }
                  }
                }}
              />
            </Tooltip>
          ),
          expandedRowKeys: expandRow,
          expandIcon: (row: any) => (
            <Button
              icon={row.expanded ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
              onClick={() => {
                if (expandRow.includes(row.record.account.id)) {
                  setExpandRow(expandRow.filter(id => id !== row.record.account.id));
                } else {
                  setExpandRow([...expandRow, row.record.account.id]);
                  const needRequestData = mentionsByAccount[row.record.account.id] === undefined;
                  if (needRequestData) {
                    requestData([row.record.account.id]);
                  }
                }
              }}
            />
          ),
          expandedRowRender: (record: any) => (
            <MentionsContainer
              isParentLoading={isFetching && requestAccountIds.includes(record.account.id)}
              mentionsData={mentionsByAccount[record.account.id] ?? { mentions: [] }}
              onRemove={removeInsight}
              onFavorite={favoriteInsight}
              onUnfavorite={favoriteInsight}
              onExclude={excludeInsight}
            />
          ),
        },
      }}
    />
  );
};
