import React, { useEffect, useState } from 'react';
import { Button, Flex } from 'antd';
import UpgradeModal from '../UpgradeModal';
import DeleteModal from '../../../Organizations/components/DeleteModal';
import { coreAPI } from '../../../../../../API/core';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../../../../redux/slices/sessionSlice';
import { useQuery } from '../../../../../../hooks/useQuery';

export const Plans = {
  annual: {
    id: 'UPDATE_ANNUAL',
    pricePerMonth: 39,
    label: 'Annual',
  },
  monthly: {
    id: 'UPDATE_MONTHLY',
    pricePerMonth: 49,
    label: 'Monthly',
  },
  default: {
    pricePerMonth: 0,
    label: 'Monthly',
  },
};

export const PlansToPrices = (plan: string) => {
  if (Plans.annual.id === plan) return Plans.annual;
  if (Plans.monthly.id === plan) return Plans.monthly;
  return Plans.default;
};

type Props = {
  isActive: boolean;
  isCanceled: boolean;
  usedSeats: number;
  paidSeats: number;
  plan: string;
  canManageSubscription: boolean;
  workspaceId: string;
};

const api = new coreAPI();

const StripeSubscriptionCard: React.FC<Props> = ({
  isCanceled,
  usedSeats,
  paidSeats,
  plan,
  canManageSubscription,
  workspaceId,
}) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const dispatch = useDispatch();

  const query = useQuery();

  useEffect(() => {
    const hasValue = query.get('upgrade');
    if (hasValue && canManageSubscription) {
      setShowUpgradeModal(true);
    }
  }, [query]);

  return (
    <>
      <UpgradeModal
        onClose={() => setShowUpgradeModal(false)}
        open={showUpgradeModal}
        paidSeats={paidSeats}
        plan={plan}
        usedPaidSeats={usedSeats}
        workspaceId={workspaceId}
      />
      <DeleteModal
        shouldShow={showCancelModal}
        closeModal={() => setShowCancelModal(false)}
        heading="Cancel your subscription"
        loading={isCanceling}
        detail={
          'Are you sure you want to cancel? This will end the subscription for all workspace members beginning in the next billing cycle. \n\nYour subscription will remain in tact for the remainder of the current billing cycle.'
        }
        confirmButtonText="Yes, cancel it now"
        onConfirm={async () => {
          setIsCanceling(true);
          await api.cancelSubscription(workspaceId);
          dispatch(fetchUser());
          setIsCanceling(false);
          setShowCancelModal(false);
        }}
      />
      <Flex vertical gap={'12px'} style={{ width: '100%' }}>
        <Flex vertical gap={'5px'}>
          <p
            style={{
              fontWeight: 500,
              fontFamily: 'Cera Pro Medium',
              padding: '0px',
              marginBlock: '0px',
            }}
          >
            ${PlansToPrices(plan).pricePerMonth} / Month - {'All features in Basic + Premium'}
          </p>
          <p
            style={{
              fontWeight: 500,
              fontFamily: 'Cera Pro Medium',
              padding: '0px',
              marginBlock: '0px',
            }}
          >
            {paidSeats} Seats / {PlansToPrices(plan).label}
          </p>

          <div style={{ display: 'flex', gap: '5px' }}>
            <Button
              type="primary"
              onClick={() => setShowCancelModal(true)}
              disabled={!canManageSubscription || isCanceled}
            >
              {'Cancel'}
            </Button>
            <Button
              type="default"
              onClick={() => setShowUpgradeModal(true)}
              disabled={!canManageSubscription || isCanceled}
            >
              Manage seats and plan
            </Button>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default StripeSubscriptionCard;
