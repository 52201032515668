import React, { useEffect, useState } from 'react';
import Styled from './style';
import Section from '../../../_Misc/Section';
import { extractError } from '../../../../../../utils/api';
import { useToast } from '../../../../../../hooks/useToast';
import { Button } from 'antd';
import { colors } from '../../../../../../theme/colors';
import { ContextTextarea } from './ContextTextarea';
import { useOrganizationDetails } from '../../../../../../features/organizations/hooks/useOrganizationDetails';
import { coreService } from '../../../../../../services/core/core-service';

export type ContextData = {
  aboutTheCompany: string;
  aboutTheProductOrService: string;
  competitors: string;
  goals: string;
  other: string;
};

const { Buttons, Container } = Styled;

export const WorkspaceContext: React.FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ContextData>({
    aboutTheCompany: '',
    aboutTheProductOrService: '',
    competitors: '',
    goals: '',
    other: '',
  });
  const [updatedData, setUpdatedData] = useState<ContextData>({
    aboutTheCompany: '',
    aboutTheProductOrService: '',
    competitors: '',
    goals: '',
    other: '',
  });

  const { success, error } = useToast();
  const { name, permissions } = useOrganizationDetails();

  const disableUserInteractions = !permissions.manageOrganizationDetails;

  const updateCompanyContext = async () => {
    try {
      setLoading(true);
      await coreService.updateCompanyContext(updatedData);
      setData(updatedData);
      success('Company context updated successfully');
    } catch (e) {
      const errorMessage = extractError(e);
      error(errorMessage);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    coreService
      .getCompanyContext()
      .then(r => {
        setData(r);
        setUpdatedData(r);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <Section
        title="Supercharge Your AI Insights"
        description={
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div>
              Adding context about your business will help UpdateAI generate even more precise AI
              insights about your customer interactions. Anything goes.
            </div>
            <div>
              💡 Pro Tip: Feel free to write in any format that suits you. Just include any URLs in
              the fields below, and we'll automatically extract detailed insights about your
              competitors, your business, and your standout product features. Check out{' '}
              <a
                href="https://help.update.ai/en/articles/9458245-how-to-supercharge-your-ai-insights-by-providing-additional-context-about-your-company"
                target="_blank"
                style={{ color: colors.primary[500] }}
              >
                these sample responses.
              </a>
            </div>
          </div>
        }
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 16px 0px 16px',
            gap: '25px',
          }}
        >
          <ContextTextarea
            disable={disableUserInteractions || loading}
            maxLength={2000}
            title={`Tell us about ${name}`}
            placeholder="Does your company focus on a specific industry, field, or vertical? What value do you create for your customers? What is your business model? Feel free to add any context about your business that will help us know you better."
            value={(updatedData || data).aboutTheCompany}
            onChange={e => {
              setUpdatedData({ ...updatedData, aboutTheCompany: e });
            }}
          />

          <ContextTextarea
            disable={disableUserInteractions || loading}
            maxLength={2000}
            title="Tell us about your product or service"
            placeholder="Describe what your product or service does. You can also describe the key features of your product or service."
            value={(updatedData || data).aboutTheProductOrService}
            onChange={e => {
              setUpdatedData({ ...updatedData, aboutTheProductOrService: e });
            }}
          />

          <ContextTextarea
            disable={disableUserInteractions || loading}
            maxLength={2000}
            title="Who are your competitors?"
            placeholder="List your competitors."
            value={(updatedData || data).competitors}
            onChange={e => {
              setUpdatedData({ ...updatedData, competitors: e });
            }}
          />

          <ContextTextarea
            disable={disableUserInteractions || loading}
            maxLength={2000}
            title="What are the top 3 business goals that your team is trying to accomplish over the next two business quarters?"
            placeholder="Anything goes..."
            value={(updatedData || data).goals}
            onChange={e => {
              setUpdatedData({ ...updatedData, goals: e });
            }}
          />

          <ContextTextarea
            disable={disableUserInteractions || loading}
            maxLength={2000}
            title="Is there any other information that you’d like us to know to improve the performance of our AI insights?"
            placeholder="Anything goes..."
            value={(updatedData || data).other}
            onChange={e => {
              setUpdatedData({ ...updatedData, other: e });
            }}
          />

          <Buttons>
            <Button
              type="primary"
              loading={loading}
              disabled={disableUserInteractions || loading}
              onClick={updateCompanyContext}
            >
              Save Changes
            </Button>

            <Button
              disabled={disableUserInteractions || loading}
              onClick={() => {
                setUpdatedData(data);
              }}
            >
              Cancel
            </Button>
          </Buttons>
        </div>
      </Section>
    </Container>
  );
};
