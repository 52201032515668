import { Select } from 'antd';
import styled from 'styled-components';

const CustomSelect = styled(Select)`
  width: 200px;
  align-self: flex-start;

  &.has-value {
    .ant-select-selection-placeholder {
      color: rgba(0, 0, 0, 0.88);
    }
  }
`;

const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

const DropdownLabel = styled.label`
  flex: 1;
  color: var(--gray-950, #282828);

  /* text-sm/leading-5/font-normal */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export default {
  CustomSelect,
  DropdownOption,
  DropdownLabel
};
