import * as librariesActions from '../../stores/libraries';
import { coreService } from '../../services/core/core-service';

export const createFolder = async (folderName: string, libraryId: string) => {
  const createdFolder = await coreService.createFolder(libraryId, folderName);
  librariesActions.folderAdded(createdFolder.id, folderName, libraryId);
  return createdFolder.id;
};

export const removeFolder = async (libraryId: string, folderId: string) => {
  await coreService.deleteFolder(folderId);
  librariesActions.folderRemoved(libraryId, folderId);
};

export const renameFolder = async (libraryId: string, folderId: string, folderName: string) => {
  await coreService.renameFolder(folderId, folderName);
  librariesActions.folderRenamed(folderId, folderName, libraryId);
};

export const removeItem = async (libraryId: string, folderId: string, itemId: string) => {
  await coreService.removeFolderItem(folderId, itemId);
  librariesActions.folderItemRemoved(libraryId, folderId, itemId);
};

export const addItem = async (folderId: string, itemId: string, type: string) => {
  await coreService.addFolderItem(folderId, itemId, type);
};

export const selectInsightItem = async (itemId: string, type: string, onFinishCallback?: (params: Record<string, any>) => void) => {
  librariesActions.insightToManageSelected(itemId, type, onFinishCallback);
  const item = await coreService.fetchFolderItem(itemId);
  librariesActions.insightSelectedFetchEnded(item.folders);
};

export const fetchItems = async (folderId?: string, selectedLibraryId?: string) => {
  try {
    if (folderId) {
      librariesActions.fetchItemsStarted();
      const items = await coreService.fetchFolderItems(folderId);
      librariesActions.folderItemsLoaded(
        selectedLibraryId || '',
        folderId,
        items.map(item => {
          return { ...item };
        }),
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    librariesActions.fetchItemsEnded();
  }
};
