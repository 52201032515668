import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOnboardingError } from '../../../redux/selectors';
import { setCurrentStep } from '../../../redux/slices/onboardingSlice';
import { Container, Content, NavigationButtons, Status } from './style';
import { Button, Typography } from 'antd';
import { Progress } from '../Progress';
import { FramedLayout } from '../../../components/layout/FramedLayout/FramedLayout';
import { useStore } from 'zustand';
import { onboardingStore } from '../stores/onboarding.store';
import GoogleChromeLogo from '../../../assets/svg/google-chrome-logo.svg';

export const ChromeExperience: React.FC = () => {
  const { totalSteps } = useStore(onboardingStore);
  const dispatch = useDispatch();

  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const error = useSelector(selectOnboardingError);

  useEffect(() => {
    setTimeout(() => {
      setButtonEnabled(true);
    }, 3000);
  }, []);

  const onNext = async () => {
    dispatch(setCurrentStep());
  };

  return (
    <FramedLayout error={error} blockLayout={!isButtonEnabled} logOut>
      <Container>
        <Content
          style={{
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography.Paragraph type="secondary" italic>
            One last note...
          </Typography.Paragraph>

          <img
            src={GoogleChromeLogo}
            style={{ width: '100px', height: '100px' }}
            alt="Google Chrome Logo"
          />

          <Typography.Title level={1}>
            For the best experience, we recommend using your Chrome browser.{' '}
          </Typography.Title>

          <Typography.Paragraph>
            Other browsers such as Safari and Edge are supported too, but the best experience is in
            Chrome.
          </Typography.Paragraph>
        </Content>

        <Status>
          <NavigationButtons>
            <div />
            <Button
              type="primary"
              loading={!isButtonEnabled}
              onClick={onNext}
              disabled={!isButtonEnabled}
            >
              Explore a demo recap now!
            </Button>
          </NavigationButtons>

          <Progress total={totalSteps} current={6} />
        </Status>
      </Container>
    </FramedLayout>
  );
};
