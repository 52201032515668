import { FC, useEffect, useState } from 'react';
import { Modal } from '../../../../../../componentsV2/Modal';

import Styles from './styles';
import { Input, Select } from 'antd';
import { coreService } from '../../../../../../services/core/core-service';
import { useToast } from '../../../../../../hooks/useToast';
import { Alert } from '../../../../../../components/alerts/Alert';
import { DatabaseOutlined } from '@ant-design/icons';

const { Container, FormItem, Label } = Styles;

type AddTopicModalProps = {
  open: boolean;
  defaultCategory: string;
  onClose: VoidFunction;
  onSuccess: (type: 'create' | 'update') => void;
  topic: {
    id: string;
    name: string;
    description: string;
    type: string;
  };
};

export const AddTopicModal: FC<AddTopicModalProps> = ({
  open,
  defaultCategory,
  topic,
  onClose,
  onSuccess,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState(defaultCategory);
  const [isLoading, setIsLoading] = useState(false);

  const { success, error } = useToast();

  const handleClose = () => {
    onClose();
    setName('');
    setDescription('');
    setType('');
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      if (topic.id) {
        await coreService.updateTopic(topic.id, {
          name,
          description,
        });
        success('Topic updated successfully');
      } else {
        await coreService.createTopic({
          name,
          description,
          type,
        });
        success('Topic created successfully');
      }
      onSuccess(topic.id ? 'update' : 'create');
      onClose();
    } catch (err) {
      error('Failed to create topic');
    } finally {
      setIsLoading(false);
      setName('');
      setDescription('');
      setType('');
    }
  };

  const isFormInvalid = !topic.id ? !name || !description || !type : !name || !description;

  useEffect(() => {
    if (defaultCategory !== type) {
      setType(defaultCategory);
    }
  }, [defaultCategory]);

  useEffect(() => {
    if (topic.id) {
      setName(topic.name);
      setDescription(topic.description);
      setType(topic.type);
    }
  }, [topic]);

  return (
    <Modal
      width={500}
      zIndex={1000}
      primaryAction={{
        label: topic.id ? 'Update Topic' : 'Add Topic',
        onClick: handleSubmit,
        disabled: isFormInvalid,
        loading: isLoading,
      }}
      secondaryAction={{ label: 'Cancel', onClick: handleClose }}
      title={topic.id ? topic.name : 'Add a New Topic'}
      open={open}
      onClose={handleClose}
    >
      <Container>
        <Alert
          customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
          message={
            topic.id
              ? 'Modifying the name or description of a topic may affect how AI calculate its trackers.'
              : "The Name and Description fields determine this topic's calculation. Modifications will only apply to future meeting data."
          }
          variant="info"
          title=""
        />

        <FormItem>
          <Label>Name</Label>
          <Input
            value={name}
            onChange={ev => setName(ev.target.value)}
            placeholder="e.g. Benefits of VoC Topic Discovery"
          />
        </FormItem>

        <FormItem>
          <Label>Description</Label>
          <Input.TextArea
            value={description}
            onChange={ev => setDescription(ev.target.value)}
            rows={6}
            placeholder="e.g. Customers discuss the benefits of using UpdateAI's voice of customer (VoC) topic discovery feature. This feature requires no setup or configuration and detects the key patterns from all your customer conversations."
          />
        </FormItem>

        {!(topic.id && !defaultCategory) && (
          <FormItem>
            <Label>Insight Type</Label>
            <Select
              value={type}
              defaultValue={defaultCategory}
              disabled={defaultCategory !== ''}
              onChange={value => setType(value)}
              options={[
                {
                  label: 'Risks',
                  value: 'Risks',
                },
                { label: 'Product Feedback', value: 'Product Feedback' },
                { label: 'Advocacy', value: 'Advocacy' },
                { label: 'Growth Opportunities', value: 'Growth Opportunities' },
              ]}
            />
          </FormItem>
        )}
      </Container>
    </Modal>
  );
};
