import { create } from 'zustand';
import { getUserBrowser } from '../../../utils/browser';
import {
  ONBOARDING_TOTAL_STEPS_AMOUNT,
  ONBOARDING_TOTAL_STEPS_AMOUNT_WITH_CHROME,
} from '../../../types/commonTypes';

type OnboardingStoreProps = {
  totalSteps: number;
  browser: string;
};

export const onboardingStore = create<OnboardingStoreProps>(set => {
  const browser = getUserBrowser();
  const totalSteps =
    browser === 'Google Chrome'
      ? ONBOARDING_TOTAL_STEPS_AMOUNT
      : ONBOARDING_TOTAL_STEPS_AMOUNT_WITH_CHROME;

  return {
    totalSteps,
    browser,
  };
});
