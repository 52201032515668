import { CrownFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';

const DecisionMakerTag: React.FC = () => {
  return (
    <Tag icon={<CrownFilled />} color={'orange'}>
      Decision maker
    </Tag>
  );
};

export default DecisionMakerTag;
