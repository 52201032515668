import { PropsWithChildren, useEffect, useRef } from 'react';
import { pastMeetingsContext } from './past-meetings.context';
import { createPastMeetingsStore, PAST_MEETINGS_PAGE_KEY } from './past-meetings.store';
import { useQuery } from '@tanstack/react-query';
import { coreAPI } from '../../../API/core';
import { FetchPastEventsArgs } from '../../../API/types';

export const PastMeetingsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const store = useRef(createPastMeetingsStore()).current;
  const {
    dateRange,
    showMineOnly,
    showReadyOnly,
    showOnlyUnmappedAccounts,
    searchTerm,
    pageSize,
    page,
    meetingType: participantsType,
    updateAccounts,
    contacts,
    mType,
    fetchId,
    canLoadData,
    fetchStatusChanged,
    eventsFetched,
    paginationMetadataSet,
    updateFiltersState,
  } = store();

  useEffect(() => {
    const filters = JSON.parse(localStorage.getItem('pageFilters') || '{}');
    const pageFilters = filters[PAST_MEETINGS_PAGE_KEY] || {};
    updateFiltersState({
      searchTerm: pageFilters.searchTerm || '',
      showMineOnly: pageFilters.showMineOnly || false,
      showReadyOnly: pageFilters.showReadyOnly || false,
      showOnlyUnmappedAccounts: pageFilters.showOnlyUnmappedAccounts || false,
      meetingType: pageFilters.meetingType || 'all',
      mType: pageFilters.mType || null,
      updateAccounts: pageFilters.updateAccounts || [],
      dateRangeType: pageFilters.dateRangeType || '30d',
      dateRange: (() => {
        const storedDate = pageFilters.dateRange || [];
        if (storedDate.length === 2) {
          return [new Date(storedDate[0]), new Date(storedDate[1])];
        }
        return [];
      })(),
      canLoadData: true,
    });
  }, []);

  const { data, isFetching } = useQuery({
    queryKey: [
      dateRange,
      showMineOnly,
      showReadyOnly,
      showOnlyUnmappedAccounts,
      searchTerm,
      participantsType,
      updateAccounts,
      contacts,
      page,
      fetchId,
      mType,
    ],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    networkMode: 'always',
    enabled: canLoadData,
    queryFn: () => {
      const searchArgs: FetchPastEventsArgs = {
        page,
        limit: pageSize,
        hostOnly: String(showMineOnly),
        readyOnly: String(showReadyOnly),
        onlyUnmappedAccounts: String(showOnlyUnmappedAccounts),
      };

      if (searchTerm) {
        searchArgs['search'] = searchTerm;
      }

      if (dateRange[0] && dateRange[1]) {
        searchArgs['from'] = dateRange[0].toISOString();
        searchArgs['to'] = dateRange[1].toISOString();
      }

      if (participantsType !== 'all') {
        searchArgs['participantsType'] = participantsType;
      }

      if (updateAccounts.length) {
        searchArgs['accountIds'] = updateAccounts.map(a => a.id).join(',');
      }

      if (contacts.length) {
        searchArgs['contactIds'] = contacts.join(',');
      }

      if (mType) {
        searchArgs['meetingType'] = mType;
      }

      const api = new coreAPI();
      return api.fetchPastEvents(searchArgs);
    },
  });

  useEffect(() => {
    fetchStatusChanged(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      eventsFetched(data.items);
      paginationMetadataSet(data.metadata);
    }
  }, [data]);

  return <pastMeetingsContext.Provider value={{ store }}>{children}</pastMeetingsContext.Provider>;
};
