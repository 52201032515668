import React, { useCallback } from 'react';
import Styled from './styles';
import { ProviderIntegration } from '../../../../../../redux/typings/store';
import { AccountIcon } from '../../../../../shared/components/AccountIcon';
import plus from '../../../../../../assets/svg/plus-square.svg';
import { Dropdown, Space, Spin, Tooltip } from 'antd';
import { DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import useFeatures from '../../../../../../hooks/useFeatures';
import { OptionalTooltipWrapper } from '../../../../../../componentsV2/OptionalTooltipWrapper';
import { SubscribeToProPlanLink } from '../../../../../../componentsV2/UpgradeToPro';
import { useNavigate } from 'react-router-dom';

const {
  ConnectAccountButton,
  DropDownSection,
  RemoveAccountButton,
  AccountTextContainer,
  AccountText,
  AccountClickableContainer,
} = Styled;

interface Props {
  isWorking: boolean;
  recapAccount: ProviderIntegration;
  prospectedAccounts: ProviderIntegration[];
  canShowSelector: boolean;
  canShowRemove: boolean;
  onConnectClick: () => unknown;
  onDelete: () => unknown;
  onChangeClick: () => unknown;
}

export const AccountResolver: React.FC<Props> = ({
  isWorking,
  recapAccount,
  canShowSelector,
  canShowRemove,
  onConnectClick,
  onDelete,
  onChangeClick,
  prospectedAccounts,
}) => {
  const navigate = useNavigate();
  const features = useFeatures();
  const availableFeature = features.salesforceIntegration || features.hubspotIntegration;
  const hasMultipleAccountsProspected = prospectedAccounts?.length > 1;

  const navigateToRecapAccount = useCallback(() => {
    window.open(`/accounts/${recapAccount.id}`, '_blank');
  }, [recapAccount]);

  const renderRecapAccountInfo = (
    <AccountTextContainer>
      <AccountText> Account: </AccountText>
      <AccountClickableContainer onClick={navigateToRecapAccount}>
        <AccountIcon provider={recapAccount?.provider} /> {recapAccount?.name}
      </AccountClickableContainer>
    </AccountTextContainer>
  );

  const renderConnectAccountButton = (
    <OptionalTooltipWrapper display={!availableFeature} value={<SubscribeToProPlanLink />}>
      <Tooltip
        title={hasMultipleAccountsProspected ? 'Multiple accounts found' : null}
        placement="left"
      >
        <ConnectAccountButton
          id="appcues-recap-demo-step-4"
          icon={<img src={plus} />}
          disabled={!availableFeature}
          children={
            <>
              {hasMultipleAccountsProspected
                ? `${prospectedAccounts.length} Accounts to select...`
                : 'Push Recap to CRM'}
            </>
          }
          onClick={() => canShowSelector && onConnectClick}
        />
      </Tooltip>
    </OptionalTooltipWrapper>
  );

  const renderRefreshAccountButton = (
    <ConnectAccountButton
      children={
        <Dropdown.Button
          menu={{
            items: [
              {
                label: <DropDownSection>Account options</DropDownSection>,
                key: '0',
                disabled: true,
              },
              {
                label: (
                  <RemoveAccountButton onClick={onDelete}>
                    <DeleteOutlined />
                    Remove account...
                  </RemoveAccountButton>
                ),
                key: '1',
              },
            ],
          }}
          onClick={onChangeClick}
          danger
        >
          <Space>
            <SyncOutlined /> Change
          </Space>
        </Dropdown.Button>
      }
    />
  );

  if (isWorking) {
    return <Spin />;
  }

  return (
    <>
      {recapAccount ? renderRecapAccountInfo : renderConnectAccountButton}
      {canShowRemove && renderRefreshAccountButton}
    </>
  );
};
