import { create } from 'zustand';

interface EventStore {
  events: Record<string, any>;
  emit: (event: string, payload?: any) => void;
  subscribe: (event: string, callback: (payload: any) => void) => () => void;
}

export const useEventStore = create<EventStore>((set, get) => ({
  events: {},
  emit: (event, payload) => {
    const callbacks = get().events[event] || [];
    callbacks.forEach((callback: (payload: any) => void) => callback(payload));
  },
  subscribe: (event, callback) => {
    set(state => ({
      events: {
        ...state.events,
        [event]: [...(state.events[event] || []), callback],
      },
    }));

    return () => {
      set(state => ({
        events: {
          ...state.events,
          [event]: (state.events[event] || []).filter((cb: any) => cb !== callback),
        },
      }));
    };
  },
}));
