import { Input } from 'antd';
import styled from 'styled-components';

const CustomInput = styled(Input)`
  width: auto;
  &.has-value {
    .ant-input::placeholder {
      color: rgba(0, 0, 0, 0.88);
    }
  }
`;

const Contents = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  min-width: 300px;
`;

const OptionsContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  border-radius: 8px;
  max-height: 260px;
  max-width: 500px;
  overflow-y: auto;
`;

const SelectAllHeader = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding: 8px;
`;

const OptionLabel = styled.span`
  cursor: pointer;
`;

const DropdownOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: calc(100% - 8px);
  padding: 2px 4px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`

export default {
  CustomInput,
  Contents,
  OptionsContainer,
  SelectAllHeader,
  OptionLabel,
  DropdownOption,
};
