import React, { useEffect } from 'react';
import { Button, Flex, Select, Tag } from 'antd';
import { CopyFilled, ReloadOutlined } from '@ant-design/icons';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { SubscribeToProPlanLink } from '../../../../componentsV2/UpgradeToPro';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/selectors';
import { Framework, FrameworkExecution } from '../../type';
import { ReactElement, useState } from 'react';
import { useToast } from '../../../../hooks/useToast';
import useFeatures from '../../../../hooks/useFeatures';

import Styles from './styles';
import { Modal } from '../../../../componentsV2/Modal';
import { MeetingTypeList } from '../../../home/tabs/UpcomingsTab/components/MeetingTypeSelector';
import { DateSelect } from '../../../shared/components/DateSelect';
import { setDate } from 'date-fns';
import { useParams, useSearchParams } from 'react-router-dom';

const { Container, NoFrameworks } = Styles;

interface Props {
  setLastExecution: (le: FrameworkExecution | null) => void;
  fetchLastExecution: (id: string) => Promise<FrameworkExecution | null>;
  onGenerate: (id: string, context?: any) => Promise<FrameworkExecution | null>;
  onCopy: (name: string, updatedAt: string) => void;
  showContext: boolean;
  children: ReactElement;
  disabled: boolean;
  key: string;
}

type FrameWorkExecutionProps = {
  selectedFramework: string;
  onClose: () => void;
  open: boolean;
  onGenerate: (context: any) => unknown;
};

const FrameworkExecutionOptions: React.FC<FrameWorkExecutionProps> = ({
  onClose,
  open,
  onGenerate,
  selectedFramework,
}) => {
  const [selectedMeetingTypes, setSelectedMeetingTypes] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[null, null] | [Date, Date]>([null, null]);

  const resetFramework = () => {
    setSelectedMeetingTypes([]);
    setDateRange([null, null]);
  };

  const cancelFramework = () => {
    resetFramework();
    onClose();
  };

  const isGenerateEnabled = dateRange[0] !== null && dateRange[0] !== null;

  return (
    <Modal
      title="Create Insight Framework Report"
      onClose={cancelFramework}
      open={open}
      width={550}
      primaryAction={{
        label: 'Generate',
        disabled: !isGenerateEnabled,
        onClick: () => {
          onGenerate({
            from: dateRange[0]?.toISOString(),
            to: dateRange[1]?.toISOString(),
            meetingType: selectedMeetingTypes,
          });
          resetFramework();
        },
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: cancelFramework,
      }}
    >
      <Flex vertical gap={12}>
        <Flex vertical gap={4}>
          <span style={{ fontFamily: 'Cera Pro Medium', fontWeight: 500, color: '#282828' }}>
            Framework selected
          </span>
          <span>{selectedFramework}</span>
        </Flex>
        <Flex vertical gap={4}>
          <span style={{ fontFamily: 'Cera Pro Medium', fontWeight: 500, color: '#282828' }}>
            Date range
          </span>
          <Flex>
            <DateSelect
              style={{
                padding: '8px',
                fontFamily: 'Cera Pro',
                border: '1px solid #d9d9d9',
              }}
              bordered={false}
              pastOnly
              futureOnly={false}
              handleCalendarChange={val => {
                setDateRange(val);
              }}
              valueFrom={dateRange[0]}
              valueTo={dateRange[1]}
            />
          </Flex>
        </Flex>
        <Flex vertical gap={4}>
          <span style={{ fontFamily: 'Cera Pro Medium', fontWeight: 500, color: '#282828' }}>
            Meeting types (optional)
          </span>
          <Select
            value={selectedMeetingTypes}
            onChange={value => setSelectedMeetingTypes(value)}
            style={{ maxHeight: '80px', overflowY: 'auto' }}
            options={MeetingTypeList.map(v => ({ label: v, value: v }))}
            mode="tags"
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

export const FrameworksHeader: React.FC<Props> = ({
  setLastExecution,
  fetchLastExecution,
  onGenerate,
  onCopy,
  showContext,
  children,
  disabled,
  key,
}) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<Framework | null | undefined>(undefined);
  const [refetch, setRefetch] = useState<NodeJS.Timeout | null>(null);
  const [displayContext, setDisplayContext] = useState(false);

  const [queryParams] = useSearchParams();

  const { error } = useToast();

  const user = useSelector(selectUser);
  const feature = useFeatures();
  const availableFeature = feature.meddicc;

  const handleSelect = async (f: Framework | null) => {
    if (!f?.id) return;

    if (f.id !== selected?.id && refetch) {
      clearTimeout(refetch);
      setRefetch(null);
    }

    setSelected(f);
    try {
      setLoading(true);
      const fe = await fetchLastExecution(f?.id);
      setLastExecution(fe);

      if (fe?.isProcessing) {
        setRefetch(
          setTimeout(() => {
            handleSelect(f);
          }, 5000),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const generateFramework = async (context?: any) => {
    if (!selected) return;

    try {
      setLoading(true);
      await onGenerate(selected.id, context);
      setLastExecution({ isProcessing: true } as FrameworkExecution);
      setRefetch(
        setTimeout(() => {
          handleSelect({ ...selected });
        }, 2000),
      );
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      error(e.response.data.message);
    }
  };

  const handleGenerate = async () => {
    if (!selected) return;

    if (showContext) {
      setDisplayContext(true);
      return;
    }

    await generateFramework();
  };

  const { data, isFetching } = useQuery({
    queryKey: ['frameworks', key],
    queryFn: async () => {
      if (!user?.membership.organization.id) return [];
      const d = await coreService.fetchFrameworksByWorkspaceId(user?.membership.organization.id);
      if (d.length > 0) {
        const framework = d.find(({ id }) => id === queryParams.get('framework')) ?? d[0];
        handleSelect(framework);
        return d;
      } else {
        setLoading(false);
        setLastExecution(null);
        return [];
      }
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const options = data?.map(f => ({ label: f.name, value: f.id })) || [];
  const val = options.find(f => f.value === selected?.id);
  const noData = data?.length === 0 && !loading;

  document.addEventListener('stop-framework-polling', () => {
    if (refetch) clearTimeout(refetch);
  });

  const handleGenerateOpts = async (context: any) => {
    setDisplayContext(false);
    await generateFramework(context);
  };

  return (
    <>
      <Container>
        <FrameworkExecutionOptions
          selectedFramework={selected?.name || ''}
          onGenerate={handleGenerateOpts}
          open={displayContext}
          onClose={() => setDisplayContext(false)}
        />
        <Select
          placeholder="Select a framework"
          options={options}
          disabled={isFetching || loading}
          value={val}
          style={{ width: 350 }}
          onSelect={(v, i) => handleSelect(data?.find(f => f.id === i.value) || null)}
        />

        <OptionalTooltipWrapper display={!availableFeature} value={<SubscribeToProPlanLink />}>
          <Button
            type="default"
            icon={<ReloadOutlined />}
            disabled={isFetching || loading || disabled || noData}
            onClick={() => handleGenerate()}
          >
            Regenerate
          </Button>
        </OptionalTooltipWrapper>

        <Button
          icon={<CopyFilled />}
          onClick={() => onCopy(selected?.name || '', selected?.createdAt || '')}
          style={{ borderRadius: '8px' }}
          disabled={isFetching || loading || disabled || noData}
        >
          Copy All
        </Button>
      </Container>

      {noData ? (
        <NoFrameworks
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}
        >
          <span style={{ width: '500px', textAlign: 'center' }}>
            Your workspace hasn't created any reporting frameworks yet. Go to your{' '}
            <a href="/settings?tab=framework"> frameworks settings</a> to create your own.
          </span>
        </NoFrameworks>
      ) : (
        React.cloneElement(children, {
          ...children.props,
          loading: isFetching || loading || children.props.loading,
          selectedFrameworkName: selected?.name,
          onGenerate: handleGenerate,
        })
      )}
    </>
  );
};
