import { Checkbox, Flex, Select, Switch, Typography } from 'antd';
import { useAccountSearch } from '../../../../features/account/account-search/account-search.hook';
import Styles from './styles';
import { AccountIcon } from '../../../../features/shared/components/AccountIcon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { EllipsisWithTooltip } from '../../../../features/shared/components/EllipsisWithTooltip';
import pluralize from 'pluralize';

const { CustomSelect, DropdownOption, DropdownLabel } = Styles;

interface Props {
  onSelect: (accountIds: string[]) => void;
  onSelectionClear?: () => unknown;
  searchType?: 'local' | 'remote';
  value?: string[];
  allowClear?: boolean;
  disabled?: boolean;
}

export const MultipleAccountsSelector: React.FC<Props> = ({
  onSelect,
  value,
  onSelectionClear,
  searchType,
  allowClear,
  disabled,
}) => {
  const { isFetching, accounts } = useAccountSearch();
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectionClear = () => {
    setSelectedAccounts([]);
    if (onSelectionClear) {
      onSelectionClear();
    }
  };

  useEffect(() => {
    if (value) {
      const options = accounts.filter(account => value.includes(account.id));

      if (options) {
        setSelectedAccounts(options.map(account => account.id));
      } else {
        setSelectedAccounts([]);
      }
    } else {
      setSelectedAccounts([]);
    }
  }, [value, accounts]);

  const handleAccountSelected = (values: string[]) => {
    const [accountId] = values;

    if (!accountId) {
      return;
    }

    const accountsToOutput = selectedAccounts.some(a => a === accountId)
      ? selectedAccounts.filter(a => a !== accountId)
      : [...selectedAccounts, accountId];

    setSelectedAccounts(accountsToOutput);

    onSelect(accountsToOutput);
  };

  const accountsSorted = useMemo(() => {
    return accounts.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });
  }, [accounts]);

  const getRenderedOptions = useMemo(() => {
    if (searchType === 'local' && searchTerm) {
      return accountsSorted.filter(
        account =>
          account.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          selectedAccounts.includes(account.id),
      );
    }

    if (showOnlySelected) {
      return accountsSorted.filter(account => selectedAccounts.some(s => s === account.id));
    }

    return accountsSorted;
  }, [selectedAccounts, showOnlySelected, accounts, searchTerm]);

  return (
    <Flex gap={'8px'} align="center">
      <CustomSelect
        mode="multiple"
        className={value?.length ? 'has-value' : ''}
        showSearch={!!searchType}
        loading={isFetching}
        placeholder={
          selectedAccounts.length > 0
            ? `${selectedAccounts.length} ${pluralize(
                'accounts',
                selectedAccounts.length,
              )} selected`
            : 'Select Accounts'
        }
        disabled={isFetching || disabled}
        allowClear={allowClear}
        value={searchTerm !== '' ? searchTerm : null}
        filterOption={(input, option) =>
          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
        }
        dropdownRender={menu => (
          <>
            <div style={{ padding: '8px 12px' }}>
              <Flex justify="space-between">
                <DropdownLabel>Show selected only</DropdownLabel>
                <Switch
                  checked={showOnlySelected}
                  onChange={value => setShowOnlySelected(value)}
                  size="small"
                />
              </Flex>
            </div>
            {menu}
          </>
        )}
        options={getRenderedOptions}
        optionRender={account => (
          <DropdownOption onClick={() => handleAccountSelected([account.data.id])}>
            <Flex style={{ width: '100%' }} justify="space-between" align="center" gap={'4px'}>
              <Flex gap="8px" align="center" style={{ overflow: 'hidden' }}>
                <AccountIcon provider={account.data.provider} />
                <EllipsisWithTooltip text={account.data.name} />
              </Flex>
              <Checkbox checked={selectedAccounts.some(a => a === account.data.id)} />
            </Flex>
          </DropdownOption>
        )}
      />
      {allowClear && (
        <Typography.Link onClick={handleSelectionClear} type="secondary">
          Clear
        </Typography.Link>
      )}
    </Flex>
  );
};
