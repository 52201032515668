import { Button, Flex, Modal, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { ModalDescription, ModalTitle, RadioContainer } from '../../style';
import { Plans } from '../../../../../../features/subscription/types';
import { useToast } from '../../../../../../hooks/useToast';
import { Subs } from '../style';

type Props = {
  open: boolean;
  isWorking: boolean;
  usedPaidSeats: number;
  paidSeats: number;
  plan: string;
  onClose: () => unknown;
  onConfirm: (plan: string, seats: number) => unknown;
};

const SeatManagementModal: React.FC<Props> = ({
  open,
  isWorking,
  usedPaidSeats,
  paidSeats,
  plan,
  onClose,
  onConfirm,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(plan);
  const [currentPaidSets, setCurrentPaidSeats] = useState(paidSeats);
  const { error, success } = useToast();

  useEffect(() => {
    if (paidSeats) setCurrentPaidSeats(paidSeats);
  }, [paidSeats]);

  useEffect(() => {
    setSelectedPlan(plan);
  }, [plan]);

  const handlePlanChanged = (plan: string) => {
    setSelectedPlan(plan);
  };

  const handleDecreaseSeatCount = () => {
    setCurrentPaidSeats(value => {
      const newCount = currentPaidSets - 1;
      if (newCount < usedPaidSeats) {
        error('Current licenses in use exceed the amount selected.');
        return value;
      }

      if (newCount === 0) {
        error('Cancel subscription to remove all licenses.');
        return value;
      }

      return newCount;
    });
  };

  const handleIncreaseSeatCount = () => {
    setCurrentPaidSeats(seats => seats + 1);
  };

  const handleCancel = () => {
    setCurrentPaidSeats(paidSeats);
    setSelectedPlan(plan);
    onClose();
  };

  const handleConfirm = () => {
    onConfirm(selectedPlan, currentPaidSets);
  };

  const seatQuantityChanged = currentPaidSets !== paidSeats;
  const seatsDecreased = currentPaidSets < paidSeats;

  return (
    <Modal
      centered
      destroyOnClose
      open={open}
      title={false}
      closable={false}
      footer={false}
      width="500px"
    >
      <Flex vertical align="center">
        <ModalTitle>Upgrade your plan</ModalTitle>

        <ModalDescription>
          You have {usedPaidSeats} of {paidSeats} licenses used, update billing cycle of all of
          them.
        </ModalDescription>

        <Radio.Group
          name="radiogroup"
          value={selectedPlan}
          style={{ marginTop: '36px', display: 'flex', flexDirection: 'column' }}
          onChange={e => handlePlanChanged(e.target.value)}
        >
          <RadioContainer position="up" selected={selectedPlan === Plans.monthly.id}>
            <Radio value={Plans.monthly.id}>
              <div>Monthly</div>
              <div style={{ fontStyle: 'italic' }}>Billed month-to-month</div>
            </Radio>
          </RadioContainer>

          <RadioContainer position="down" selected={selectedPlan === Plans.annual.id}>
            <Radio value={Plans.annual.id}>
              <div>Annual</div>
              <div style={{ fontStyle: 'italic' }}>Get 20% off the price of monthly</div>
            </Radio>
          </RadioContainer>
        </Radio.Group>

        <Flex
          gap={'50px'}
          vertical
          style={{ margin: '24px 0px' }}
          align="center"
          justify="space-between"
        >
          Total licenses in plan
          <Flex style={{ width: '200px' }} align="center" justify="space-between">
            <Button
              type="default"
              style={{ width: '50px', fontSize: '20px' }}
              onClick={handleDecreaseSeatCount}
            >
              -
            </Button>

            {currentPaidSets}

            <Button
              type="default"
              style={{ width: '50px', fontSize: '20px' }}
              onClick={handleIncreaseSeatCount}
            >
              +
            </Button>
          </Flex>
        </Flex>

        <Subs>
          {seatQuantityChanged && (
            <p>
              {seatsDecreased
                ? 'Licenses removed from your plan will take effect in the next billing cycle.'
                : 'Licenses added to your plan will take effect immediately, and the charge will be prorated for the current billing cycle.'}
            </p>
          )}
        </Subs>

        <div style={{ display: 'flex', gap: '100px', width: '100%' }}>
          <Button
            type="default"
            children="Cancel"
            onClick={handleCancel}
            style={{ flex: 1, marginTop: '24px' }}
            disabled={isWorking}
          />

          <Button
            type="primary"
            children="Upgrade"
            loading={isWorking}
            onClick={handleConfirm}
            style={{ flex: 1, marginTop: '24px' }}
          />
        </div>
      </Flex>
    </Modal>
  );
};

export default SeatManagementModal;
