import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Modal, Progress, Radio } from 'antd';
import { ModalContent, ModalDescription, ModalTitle, RadioContainer } from '../style';
import { Col, Container, Content, Licenses, LicensesContent, Seats, Subs, Used } from './style';
import DeleteModal from '../../Organizations/components/DeleteModal';
import Section from '../../_Misc/Section';
import ExtendTrial from '../components/ExtendTrial';
import { useToast } from '../../../../../hooks/useToast';
import { useQuery } from '../../../../../hooks/useQuery';
import { coreAPI } from '../../../../../API/core';
import { selectSubscription, selectUser } from '../../../../../redux/selectors';
import { fetchUser } from '../../../../../redux/slices/sessionSlice';
import { extractError } from '../../../../../utils/api';
import { StripeSubscription, Subscription } from '../../../../../features/subscription/types';
import { isStripeSubscription } from '../../../../../features/subscription/utils';
import { capitalize } from 'lodash';
import StripeSubscriptionCard from './StripeSubscriptionCard';
import SubscribeModal from './SubscribeModal';

export const Plans = {
  annual: {
    id: 'UPDATE_ANNUAL',
    pricePerMonth: 39,
    label: 'Annual',
  },
  monthly: {
    id: 'UPDATE_MONTHLY',
    pricePerMonth: 49,
    label: 'Monthly',
  },
  default: {
    pricePerMonth: 0,
    label: 'Monthly',
  },
};

export const PlansToPrices = (plan: string) => {
  if (Plans.annual.id === plan) return Plans.annual;
  if (Plans.monthly.id === plan) return Plans.monthly;
  return Plans.default;
};

type Props = {
  subscription: Subscription;
  paidSeats: number;
  usedPaidSeats: number;
  canManageSubscription: boolean;
};

export const CurrentPlanCard: React.FC<Props> = ({
  subscription,
  paidSeats,
  usedPaidSeats,
  canManageSubscription,
}) => {
  const query = useQuery();
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const isStripe = isStripeSubscription(subscription.subscription.billing);
  const [onlyAdminCanUpgradeModal, setOnlyAdminCanUpgrademodal] = useState(false);

  useEffect(() => {
    const hasValue = query.get('upgrade');
    if (hasValue && !canManageSubscription) {
      setOnlyAdminCanUpgrademodal(true);
    }
  }, [query]);

  const percentageOfUsedSeats = paidSeats > 0 ? usedPaidSeats / paidSeats : 0;

  const isFree = subscription.subscription.planId === 'free';
  const isPro = subscription.subscription.planId === 'pro';

  return (
    <>
      <Container>
        <Flex gap={24}>
          <Section>
            <Content>
              <Col>
                <h1>Plan: {capitalize(subscription.subscription.planId)}</h1>
                <Flex gap={'4px'} align="center">
                  <p
                    style={{
                      padding: '0px',
                      marginBlock: '0px',
                    }}
                  >
                    {isPro
                      ? 'All features in Basic + Premium'
                      : isFree
                      ? 'Basic features, limited visibility'
                      : ''}
                  </p>
                </Flex>
                {isFree && (
                  <Flex>
                    <Button type="primary" onClick={() => setShowSubscribeModal(true)}>
                      Subscribe
                    </Button>
                  </Flex>
                )}
                <SubscribeModal
                  onClose={() => setShowSubscribeModal(false)}
                  open={showSubscribeModal}
                  workspaceId={subscription.workspaceId}
                  paidSeats={0}
                  plan={Plans.annual.id}
                  usedPaidSeats={0}
                />
                {isStripeSubscription(subscription.subscription.billing) && (
                  <StripeSubscriptionCard
                    canManageSubscription={canManageSubscription}
                    isActive={subscription.subscription.isActive}
                    isCanceled={subscription.subscription.isCanceled}
                    paidSeats={subscription.subscription.paidSeats}
                    plan={subscription.subscription.billing.plan}
                    usedSeats={subscription.usedPaidSeats}
                    workspaceId={subscription.workspaceId}
                  />
                )}
              </Col>
            </Content>
          </Section>

          {subscription && (
            <Section>
              <Content>
                <Col>
                  <h1>Licenses in your plan</h1>

                  <Progress
                    percent={percentageOfUsedSeats}
                    status="active"
                    showInfo={false}
                    strokeColor={'#21A6FF'}
                    style={{ margin: 0, marginInlineEnd: 0 }}
                  />

                  <Used>
                    <Seats>{usedPaidSeats}</Seats>
                    <span>of {paidSeats} licenses used</span>
                  </Used>

                  <div style={{ display: 'flex', gap: '5px' }}>
                    <Button
                      type="default"
                      onClick={() => (window.location.href = '/settings?tab=my-organization')}
                    >
                      Manage seats assignment
                    </Button>
                  </div>
                </Col>
              </Content>
            </Section>
          )}
        </Flex>
      </Container>

      <ExtendTrial
        open={onlyAdminCanUpgradeModal}
        onClose={() => setOnlyAdminCanUpgrademodal(false)}
      />
    </>
  );
};
