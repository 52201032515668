import Styles, { InsightListContainer } from './styles';
import React, { useCallback, useMemo, useState } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { GenerativeAIItem } from '../../../../redux/typings/recap';
import { Insight } from '../../../../componentsV2/Insight';
import { useRecapInsightAutomaticPlay } from '../../../../pages/recap/automatic-play/automatic-play.hook';
import { useVideoPlayer } from '../../../video/hooks';
import { useSummarizeInsights } from '../../../insights/summarize-insights/summarize-insights.hook';
import { useLibraries } from '../../../libraries/hooks/useLibraries';
import { useRecapAnalytics } from '../../../recapv2/recap-analytics/hooks/useRecapAnalytics';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';
import { selectInsightItem } from '../../../libraries/libraries.service';
import { coreService } from '../../../../services/core/core-service';

interface Props {
  color: string;
  hightlightColor: string;
  smartClipColor: string;
  bullets: GenerativeAIItem[];
  category: string;
  onEdit: (id: string, text: string) => unknown;
  onDelete: (id: string) => unknown;
}

export const BulletList: React.FC<Props> = props => {
  const { Blur, BlurMessage } = Styles;
  const [activeId, setActiveId] = useState('');
  const { isPublic, isBasic, id, excludedFromInsights, permissions } = useRecapManagement();
  const { played, itemPlayed } = useRecapInsightAutomaticPlay();
  const { play, ready } = useVideoPlayer();
  const { addInsight, removeInsight, insightsCount } = useSummarizeInsights();
  const { transcript } = useRecapManagement();
  const { smartClip } = useRecapAnalytics();

  const [insights, setInsights] = useState(props.bullets);

  const getMessages = useCallback(
    (item: { chunks: { messageId: string }[] }) => {
      const messageIds = new Set(item.chunks.map(chunk => chunk.messageId));
      const messages = transcript.filter(msg => {
        return messageIds.has(msg.id);
      });
      return messages.map(msg => {
        return {
          id: msg.id,
          speaker: msg.speaker,
          content: msg.content,
          mapping: msg.segments,
          startedTime: String(msg.startTime),
        };
      });
    },
    [transcript],
  );

  const itemClicked = (arg: GenerativeAIItem) => {
    setActiveId(arg.id);
  };

  const clearItem = () => {
    setActiveId('');
  };

  const handleManageInsight = (insightId: string) => {
    try {
      selectInsightItem(insightId, 'insight', (params: Record<string, number>) => {
        const { totalRemainingFolders } = params;
        const insightIndex = insights.findIndex(bullet => bullet.id === insightId);
        setInsights(prev =>
          prev.map((bullet, index) => ({
            ...bullet,
            favourite: index === insightIndex ? totalRemainingFolders > 0 : bullet.favourite,
          })),
        );

        smartClip.favorited();
      });
    } catch (e) {
      console.error('Error managing insight', e);
    }
  };

  return (
    <>
      {isBasic && <Blur />}

      <InsightListContainer>
        {insights.map(bullets => {
          return (
            <Insight
              isFavorite={bullets.favourite}
              openInsightSamePage={true}
              canExclude={permissions.canManageInsightExclusion}
              canEdit={permissions.canManageInsightExclusion}
              isRecapExcludedFromInsightsEngine={excludedFromInsights}
              key={bullets.id}
              insight={bullets}
              text={bullets.text}
              color={props.color}
              isEditing={bullets.id === activeId}
              // TODO: No idea what this is used for
              isOpen={false}
              played={played}
              hoverColor={props.hightlightColor}
              speakers={undefined}
              editable={!isPublic && !isBasic}
              smartClipColor={props.smartClipColor}
              ready={ready}
              isPublic={isPublic}
              insightsCount={insightsCount}
              recapId={id}
              messages={getMessages(bullets)}
              manageInsight={() => handleManageInsight(bullets.id)}
              itemPlayed={itemPlayed}
              onClick={() => itemClicked(bullets)}
              onStopEditing={() => clearItem()}
              onDelete={() => props.onDelete(bullets.id)}
              onEdit={text => props.onEdit(bullets.id, text)}
              play={play}
              removeInsight={removeInsight}
              addInsight={addInsight}
            />
          );
        })}
      </InsightListContainer>

      {isBasic && (
        <BlurMessage>
          <LockOutlined style={{ color: '#F9AA0B', marginRight: '8px' }} />

          {!isBasic ? (
            'Get more from this meeting! Encourage the host to upgrade their plan for full access.'
          ) : (
            <>
              <a
                href="/settings?tab=plan-and-billing"
                style={{
                  textDecoration: 'underline',
                  fontWeight: 700,
                  color: '#b75a06',
                  marginRight: '5px',
                }}
              >
                Upgrade
              </a>
              to a paid plan to access insights, edit each bullet, and watch Smart Clips™
            </>
          )}
        </BlurMessage>
      )}
    </>
  );
};
