import { produce } from 'immer';
import { DateRangeIntervals, KeyToFilterConfig, KeyToFilterStore } from './types';
import { create } from 'zustand';
import { filtersConfigToStore } from './dashboard-filters.utils';

export const PORTFOLIO_INSIGHTS_PAGE_KEY = 'portfolio-insights';

interface DashboardFiltersState {
  filters: KeyToFilterStore;
  refetchMethods: Record<string, VoidFunction>;
  dateFilterChanged: (filterId: string, intervals: DateRangeIntervals) => void;
  listFilterSelectionChanged: (filterId: string, optionIds: string[]) => void;
  currencyFilterSelectionChanged: (filterId: string, from: number, to: number) => void;
  dateOptionSelectionChanged: (filterId: string, option: string) => void;
  dateFilterSelectionChanged: (filterId: string, dates: [Date, Date]) => void;
  accountFilterSelectionChanged: (filterId: string, selected: string | undefined) => void;
  multipleAccountsFilterSelectionChanged: (filterId: string, selected: string[]) => void;
  productLinesFilterSelectionChanged: (filterId: string, selected: string[]) => void;
  ownersFilterSelectionChanged: (filterId: string, selected: string[]) => void;
  numberFilterValueChanged: (filterId: string, value: number | null) => void;
  rangeNumberFilterValueChanged: (filterId: string, value: [number, number]) => void;
  selectFilterSelectionChanged: (filterId: string, selected: number | string) => void;
  meetingTypeChanged: (filterId: string, selected: string | null) => void;
  filtersChanged: (filters: KeyToFilterConfig) => void;
  triggerDataLoad: (key?: string) => void;
  clearFilters: VoidFunction;
  hasRefetchMethod: (key: string) => boolean;
  setRefetchMethod: (key: string, method: VoidFunction) => void;
}

export type DashboardFiltersStore = ReturnType<typeof createStore>;

type CreateStoreArgs = {
  filters: KeyToFilterConfig;
};

export const createStore = (args: CreateStoreArgs) => {
  const filters = filtersConfigToStore(args.filters);

  return create<DashboardFiltersState>((set, get) => {
    return {
      filters,
      refetchMethods: {},
      hasRefetchMethod: key => get().refetchMethods[key] !== undefined,
      dateFilterChanged: (filterId, intervals) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'dateRange') {
              filter.value.interval = intervals;
            }
          }),
        );
      },
      rangeNumberFilterValueChanged: (filterId, intervals) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'numberRange') {
              filter.value.interval = intervals;
            }
          }),
        );
      },
      filtersChanged: filters => {
        set({ filters: filtersConfigToStore(filters) });
      },
      listFilterSelectionChanged: (filterId, optionIds) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'list') {
              filter.value.options = filter.value.options.map(option => {
                option.selected = optionIds.includes(option.id);
                return option;
              });
            }
          }),
        );
      },
      currencyFilterSelectionChanged: (filterId, from, to) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'list' && filter.componentProps?.type === 'currency') {
              filter.value.from = from;
              filter.value.to = to;
            }
          }),
        );
      },
      accountFilterSelectionChanged: (filterId, selected) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'account') {
              filter.value.selected = selected;
            }
          }),
        );
      },
      multipleAccountsFilterSelectionChanged: (filterId, selected) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'multipleAccounts') {
              filter.value.selected = selected;
            }
          }),
        );
      },
      productLinesFilterSelectionChanged: (filterId, selected) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'productLines') {
              filter.value.selected = selected;
            }
          }),
        );
      },
      ownersFilterSelectionChanged: (filterId, selected) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'owners') {
              filter.value.selected = selected;
            }
          }),
        );
      },
      dateOptionSelectionChanged: (filterId, option) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'dateRange') {
              filter.value.dateOptionValue = option;
            }
          }),
        );
      },
      dateFilterSelectionChanged: (filterId, dates) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'dateRange') {
              filter.value.interval = dates;
            }
          }),
        );
      },
      numberFilterValueChanged: (filterId, value) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'number') {
              filter.value.value = value;
            }
          }),
        );
      },
      selectFilterSelectionChanged: (filterId, selected) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'select') {
              filter.value.selected = selected;
            }
          }),
        );
      },
      meetingTypeChanged: (filterId, selected) => {
        set(prevState =>
          produce(prevState, draft => {
            const filter = draft.filters[filterId];
            if (filter && filter.type === 'meetingType') {
              filter.value.selected = selected;
            }
          }),
        );
      },
      triggerDataLoad: (key?: string) => {
        if (key && get().refetchMethods[key]) {
          get().refetchMethods[key]();
          return;
        }

        Object.values(get().refetchMethods).forEach(method => method());
      },
      setRefetchMethod: (key, method) => {
        set(prevState =>
          produce(prevState, draft => {
            draft.refetchMethods[key] = method;
          }),
        );
      },
      clearFilters: () => {
        set(prevState =>
          produce(prevState, draft => {
            Object.keys(draft.filters).forEach(key => {
              const filter = draft.filters[key];
              if (filter.type === 'list') {
                filter.value.options = filter.value.options.map(option => {
                  option.selected = false;
                  return option;
                });
              } else if (filter.type === 'dateRange') {
                filter.value.interval = [null, null];
                filter.value.value = '30d';
                filter.value.dateOptionValue = '30d'
              } else if (filter.type === 'multipleAccounts') {
                filter.value.selected = [];
              } else if (filter.type === 'number') {
                filter.value.value = null;
              } else if (filter.type === 'select') {
                filter.value.selected = -1;
              } else if (filter.type === 'meetingType') {
                filter.value.selected = null;
              }
            });
          }),
        );

        get().triggerDataLoad()
      }
    };
  });
};
