import { Button, Modal } from 'antd';
import React from 'react';
import { Container, Detail, Footer, Heading, TextContainer } from './style';
import { colors } from '../../../../../../theme/colors';

interface Props {
  shouldShow?: boolean;
  closeModal: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  confirmButtonText?: string;
}

const ImportModal: React.FC<Props> = ({
  shouldShow,
  closeModal,
  onConfirm,
  loading,
  confirmButtonText,
}) => {
  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal title={false} open={shouldShow} closable={false} destroyOnClose footer={false} centered>
      <Container>
        <TextContainer>
          <Heading>What you need to know before importing Clari calls</Heading>
          <Detail>
            <div>
              1. The person who imports the meeting{' '}
              <span style={{ color: 'black' }}>will be designated as the owner</span>. Currently,
              transferring ownership is not supported, but this feature will be available soon.
            </div>
            <div>
              2. A call can only be imported{' '}
              <span style={{ color: 'black' }}>once per workspace</span>.
            </div>
            <div>
              3. The process of importing and processing{' '}
              <span style={{ color: 'black' }}>can take up to 10 minutes per meeting.</span>
              After initiating the transfer, please close the window. Come back after the assumed
              length of time and refresh this modal to see the new status.
            </div>
            <div>
              4. When selecting meetings to import, please note the days on which they were
              recorded. Then, when searching under past meetings for these recaps,{' '}
              <span style={{ color: 'black' }}>remember to adjust the date range accordingly.</span>
            </div>
            <div>
              <span style={{ color: colors.primary[500] }}>Important:</span> Once every call is
              processed, an email will be sent to the invitees in this workspace and the owner as
              configured in the settings.
            </div>
            <div>
              We recommend{' '}
              <a
                href="/settings?tab=notifications"
                target="_blank"
                style={{ color: colors.primary[500] }}
              >
                turning off recap notifications
              </a>{' '}
              (both types) before starting this process.
            </div>
          </Detail>
        </TextContainer>

        <Footer>
          <Button onClick={handleClose}>Cancel</Button>

          <Button
            type="primary"
            onClick={handleConfirm}
            loading={loading}
            style={{ width: 'fit-content' }}
          >
            {confirmButtonText ? confirmButtonText : 'Yes, remove them now'}
          </Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default ImportModal;
